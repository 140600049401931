import React, { useEffect, useState } from "react";
import { SafariPackage } from "../../utils/interface";
import useSafaris from "../../hooks/useSafaris";
import SafariCard from "./SafariCard";
import Loader from "./Loader";

type SelectedFilters = {
  priceRange: string;
  categories: string[];
  destinations: string[];
  duration: number[];
};

export default function FilterResults() {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    priceRange: "Mid range Safaris",
    categories: [],
    destinations: [],
    duration: [],
  });

  const [safariPackageData, setSafariPackageData] = useState<SafariPackage[]>(
    []
  );
  const {
    allSafarisQuery: { isLoading: itemLoading, data },
  } = useSafaris();

  useEffect(() => {
    if (data && data.length > 0) {
      // Filter safari packages based on selected filters
      const filteredSafariPackages = filterSafariPackages(
        data,
        selectedFilters
      );
      setSafariPackageData(filteredSafariPackages);
    }
  }, [data, selectedFilters]);

  const filterSafariPackages = (
    safariPackageData: SafariPackage[],
    selectedFilters: SelectedFilters
  ): SafariPackage[] => {
    const filteredData = safariPackageData.filter((safariPackage) => {
      if (selectedFilters.categories.length > 0) {
        const matchingCategories = selectedFilters.categories.some((category) =>
          safariPackage.safari_package_category.includes(category)
        );
        if (!matchingCategories) {
          return false;
        }
      }

      if (
        selectedFilters.destinations.length > 0 &&
        !selectedFilters.destinations.includes(safariPackage.destination)
      ) {
        return false;
      }
      if (selectedFilters.duration.length > 0) {
        const maxDuration = Math.max(...selectedFilters.duration);
        if (safariPackage.details.days > maxDuration) {
          return false;
        }
      }
      return true;
    });
    return filteredData;
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: keyof SelectedFilters
  ) => {
    const value = event.target.value;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [category]: event.target.checked
        ? [...(prevFilters[category] as string[]), value]
        : (prevFilters[category] as string[]).filter((item) => item !== value),
    }));
  };

  const handleSearch = () => {
    const filteredSafariPackages = filterSafariPackages(
      safariPackageData,
      selectedFilters
    );
    console.log(filteredSafariPackages);
    setSafariPackageData(filteredSafariPackages);
  };

  return (
    <div className="container mt-10 px-5">
      {itemLoading ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-12 lg:gap-12 gap-0 my-5">
            <div className="lg:col-span-8 col-span-12 grid md:grid-cols-2 grid-cols-1 gap-6 order-2 md:order-1">
              {safariPackageData?.map((safariProduct) => (
                <div className="h-fit">
                  <SafariCard
                    id={safariProduct.id}
                    image={safariProduct.image_url}
                    description={safariProduct.safari_package_description}
                    title={safariProduct.title}
                    featured={safariProduct.featured}
                    price={safariProduct.prices_midrange}
                    days={safariProduct.details?.days}
                    priceFrom={safariProduct.details.price_from}
                    category={safariProduct.safari_package_category}
                    highlights={safariProduct.safari_package_highlights}
                    slug={safariProduct.slug}
                  />
                </div>
              ))}
            </div>
            <div className="lg:col-span-4 col-span-12 my-5 order-1 md:order-2">
              <div className="pb-[10px] border-b border-colorTextBase border-opacity-10">
                <h4 className="text-lg font-semibold">Filter by :</h4>
              </div>

              {/* Price Range section */}
              <aside>
                <h5 className="text-base pt-5 font-semibold">Price Range</h5>
                <ul className="pt-2 ">
                  <li className="pt-1 first:pt-0">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value="Budget Safaris"
                        name="list-radio"
                        className="w-5 h-5 accent-colorTextBase"
                        onChange={(e) =>
                          setSelectedFilters((prevFilters) => ({
                            ...prevFilters,
                            priceRange: e.target.value,
                          }))
                        }
                      />
                      <label
                        htmlFor="list-radio-license"
                        className="w-full py-1 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Budget Safaris
                      </label>
                    </div>
                  </li>
                  <li className="pt-1 first:pt-0">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value="Mid range Safaris"
                        name="list-radio"
                        checked={
                          selectedFilters.priceRange === "Mid range Safaris"
                        }
                        className="w-5 h-5 accent-colorTextBase"
                        onChange={(e) =>
                          setSelectedFilters((prevFilters) => ({
                            ...prevFilters,
                            priceRange: e.target.value,
                          }))
                        }
                      />
                      <label
                        htmlFor="list-radio-license"
                        className="w-full py-1 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Mid range Safaris
                      </label>
                    </div>
                  </li>
                  <li className="pt-1 first:pt-0">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value="Luxury Safaris"
                        name="list-radio"
                        className="w-5 h-5 accent-colorTextBase"
                        onChange={(e) =>
                          setSelectedFilters((prevFilters) => ({
                            ...prevFilters,
                            priceRange: e.target.value,
                          }))
                        }
                      />
                      <label
                        htmlFor="list-radio-license"
                        className="w-full py-1 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Luxury Safaris
                      </label>
                    </div>
                  </li>
                </ul>
              </aside>

              {/* Categories section */}
              <aside>
                <h5 className="text-base pt-5 font-semibold">Categories</h5>
                <ul className="pt-2">
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Game Drives"
                        id="cate-one"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-one">Game Drives</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Flying Safaris"
                        id="cate-two"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-two">Flying Safaris</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Night Game Drives"
                        id="cate-three"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-three">Night Game Drives</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Honeymoon Safaris"
                        id="cate-four"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-four">Honeymoon Safaris</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Boat Safaris"
                        id="cate-five"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-five">Boat Safaris</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Nature Walks Safaris"
                        id="cate-six"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-six">Nature Walks Safaris</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Cultural Safaris"
                        id="cate-seven"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-seven">Cultural Safaris</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Mountain Safaris"
                        id="cate-eight"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-eight">Mountain Safaris</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Beach Safaris"
                        id="cate-nine"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-nine">Beach Safaris</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Specialised Safaris"
                        id="cate-ten"
                        onChange={(e) => handleCheckboxChange(e, "categories")}
                      />
                      <label htmlFor="cate-ten">Specialised Safaris</label>
                    </div>
                  </li>
                </ul>
              </aside>

              {/* Destinations section */}
              <aside>
                <h5 className="text-base pt-5 font-semibold">Destinations</h5>
                <ul className="pt-2">
                  <li className="pt-3 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Kenya"
                        id="des-one"
                        onChange={(e) =>
                          handleCheckboxChange(e, "destinations")
                        }
                      />
                      <label htmlFor="des-one">Kenya</label>
                    </div>
                  </li>
                  <li className="pt-3 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Tanzania"
                        id="des-two"
                        onChange={(e) =>
                          handleCheckboxChange(e, "destinations")
                        }
                      />
                      <label htmlFor="des-two">Tanzania</label>
                    </div>
                  </li>
                  <li className="pt-3 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value="Uganda"
                        id="des-three"
                        onChange={(e) =>
                          handleCheckboxChange(e, "destinations")
                        }
                      />
                      <label htmlFor="des-three">Uganda</label>
                    </div>
                  </li>
                </ul>
              </aside>

              {/* Duration section */}
              <aside>
                <h5 className="text-base pt-5 font-semibold">Duration</h5>
                <ul className="pt-2">
                  <li className="pt-3 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value={1}
                        id="d1"
                        onChange={(e) => handleCheckboxChange(e, "duration")}
                      />
                      <label htmlFor="d1">Fullday (+8 hours)</label>
                    </div>
                  </li>
                  <li className="pt-3 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value={4}
                        id="d2"
                        onChange={(e) => handleCheckboxChange(e, "duration")}
                      />
                      <label htmlFor="d2">2 - 4 Days</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value={7}
                        id="d3"
                        onChange={(e) => handleCheckboxChange(e, "duration")}
                      />
                      <label htmlFor="d3">5 - 7 Days</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value={10}
                        id="d4"
                        onChange={(e) => handleCheckboxChange(e, "duration")}
                      />
                      <label htmlFor="d4">8 - 10 days</label>
                    </div>
                  </li>
                  <li className="pt-5 first:pt-0">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        value={11}
                        id="d5"
                        onChange={(e) => handleCheckboxChange(e, "duration")}
                      />
                      <label htmlFor="d5">More Then 10 Days</label>
                    </div>
                  </li>
                </ul>
              </aside>

              {/* Search button */}
              <aside>
                <div
                  className="border border-colorTextBase mt-5 p-3 flex justify-center cursor-pointer bg-colorTextSecond text-white rounded-sm"
                  onClick={handleSearch}
                >
                  <svg
                    fill="currentColor"
                    width="20"
                    height="20"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 488.4 488.4"
                    xmlSpace="preserve"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />

                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />

                    <g id="SVGRepo_iconCarrier">
                      <g>
                        <g>
                          <path d="M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6 s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2 S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7 S381.9,104.65,381.9,203.25z" />{" "}
                        </g>
                      </g>
                    </g>
                  </svg>
                  <h3 className="ml-5">Search Tour</h3>
                </div>
                <div className="relative"></div>
              </aside>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
