import React from "react";
import { useForm } from "react-hook-form";
import Button from "../components/ui/Button";
import { EMAIL_REGEX } from "../utils/interface";
import emailjs from "@emailjs/browser";
import Head from "../components/seo/Head";
import { useLocation } from "react-router-dom";

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

const CurateSafarisContactForm: React.FC = () => {
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    mode: "onSubmit",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    },
  });

  const submit = async (data: FormData) => {
    try {
      // Initialize EmailJS
      emailjs.init("znTnwdmQ3r_88Dh66");

      // Create form data
      const form = document.createElement("form");
      Object.keys(data).forEach((key) => {
        const input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", key);
        input.setAttribute("value", data[key as keyof FormData]);
        form.appendChild(input);
      });

      await emailjs.sendForm("service_zkjp3r5", "template_5r3vulj", form);
      alert("Message sent successfully!");
      reset();
    } catch (error) {
      console.error("Failed to send message:", error);
      alert("Failed to send message, please try again later.");
    }
  };

  return (
    <>
      <Head
        title="Curate Safaris Contact form"
        addPostfixTitle={true}
        link={location.pathname}
        description="At Divine Travel Nest Safari, we specialize in crafting unforgettable safari experiences tailored to your dreams."
      />

      <div className="text-center pt-10">
        <h1 className="text-3xl font-bold sm:text-4xl">Contact us</h1>

        <p className="mt-1 ">
          Embark on a meticulously curated safari experience where every detail
          is thoughtfully planned to deliver an unforgettable adventure
          according to your needs.
        </p>
      </div>

      <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto flex flex-col sm:flex-row justify-center sm:gap-6">
        <div className="space-y-14 w-full sm:w-[40%] mt-12">
          <div className="flex gap-x-5">
            <svg
              className="flex-shrink-0 size-6 text-colorTextBase"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" />
              <circle cx="12" cy="10" r="3" />
            </svg>
            <div className="grow">
              <h4 className="font-semibold">Our address:</h4>
              <address className="mt-1 text-sm not-italic">
                Spur Mall Nairobi,
                <br />
                Kenya
              </address>
            </div>
          </div>

          <div className="flex gap-x-5">
            <svg
              className="flex-shrink-0 size-6 text-colorTextBase"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
              <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
            </svg>
            <div className="grow">
              <h4 className="font-semibold">Email us:</h4>
              <a
                className="mt-1 text-sm"
                href="mailto:divinetravellnestsafarisltd@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                divinetravellnestsafarisltd@gmail.com
              </a>
            </div>
          </div>

          <div className="flex gap-x-5">
            <svg
              className="flex-shrink-0 size-6 text-colorTextBase"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="m3 11 18-5v12L3 14v-3z" />
              <path d="M11.6 16.8a3 3 0 1 1-5.8-1.6" />
            </svg>
            <div className="grow">
              <h4 className="font-semibold">Talk to us</h4>
              <p className="mt-1 leading-relaxed">
                To book a tour please complete the form or call <br />
                +254 722-595-916
                <br />
                +254 712-954-545
              </p>
            </div>
          </div>
        </div>

        <div className="mt-12 items-center w-full sm:w-1/2">
          <div className="flex flex-col border rounded-md p-4 sm:p-6 lg:p-8 border-colorTextBase">
            <h2 className="mb-8 text-xl font-semibold">Send us a message</h2>

            <form
              className="grid gap-4"
              onSubmit={handleSubmit((data) => submit(data))}
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="firstName" className="sr-only">
                    First Name
                  </label>
                  <input
                    {...register("firstName", {
                      required: "Please provide your first name",
                    })}
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="py-3 px-4 block w-full bg-transparent placeholder-colorTextBase placeholder-opacity-75 border border-colorTextBase rounded-md text-sm"
                    placeholder="First Name"
                    autoComplete="on"
                  />
                  {errors.firstName && (
                    <p
                      className={`${
                        errors ? "animate-shake" : ""
                      } text-red-500 text-xs font-light py-2`}
                    >
                      {errors.firstName.message}
                    </p>
                  )}
                </div>

                <div>
                  <label htmlFor="lastName" className="sr-only">
                    Last Name
                  </label>
                  <input
                    {...register("lastName", {
                      required: "Please provide your last name",
                    })}
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="py-3 px-4 block w-full bg-transparent placeholder-colorTextBase placeholder-opacity-75 border border-colorTextBase rounded-md text-sm disabled:opacity-50 disabled:pointer-events-none"
                    placeholder="Last Name"
                    autoComplete="on"
                  />
                  {errors.lastName && (
                    <p
                      className={`${
                        errors ? "animate-shake" : ""
                      } text-red-500 text-xs font-light py-2`}
                    >
                      {errors.lastName.message}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  {...register("email", {
                    required: "Please provide an email",
                    pattern: {
                      value: EMAIL_REGEX,
                      message:
                        "Please provide a properly formatted email address",
                    },
                  })}
                  type="email"
                  name="email"
                  id="email"
                  className="py-3 px-4 block w-full bg-transparent placeholder-colorTextBase placeholder-opacity-75 border border-colorTextBase rounded-md text-sm disabled:opacity-50 disabled:pointer-events-none"
                  placeholder="your@email.com"
                  autoComplete="on"
                />
                {errors.email && (
                  <p
                    className={`${
                      errors ? "animate-shake" : ""
                    } text-red-500 text-xs font-light py-2`}
                  >
                    {errors.email.message}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  {...register("message", {
                    required: "Please provide your message",
                  })}
                  id="message"
                  name="message"
                  rows={4}
                  className="py-3 px-4 block w-full bg-transparent placeholder-colorTextBase placeholder-opacity-75 border border-colorTextBase rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none"
                  placeholder="Details"
                ></textarea>
                {errors.message && (
                  <p
                    className={`${
                      errors ? "animate-shake" : ""
                    } text-red-500 text-xs font-light py-2`}
                  >
                    {errors.message.message}
                  </p>
                )}
              </div>

              <div className="mt-4 grid">
                <Button
                  type="submit"
                  text="Send inquiry"
                  className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-md disabled:opacity-50 disabled:pointer-events-none"
                />
              </div>

              <div className="mt-3 text-center">
                <p className="text-sm dark:text-colorTextBase">
                  Free of charge, no spam, no disturbing calls
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurateSafarisContactForm;
