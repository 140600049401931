import React from "react";
// import Heading from "../ui/Heading";
// import Button from "../ui/Button";

function Blog() {
  return (
    <>
      <div className="">
        <div className="pt-24 ">
          <div className="min-h-[34rem] p-10 md:p-20 bg-[url('/public/assets/images/body/bibhash-polygon-cafe-banerjee-3QPNiZ_Nqjs-unsplash.jpg')] bg-cover bg-bottom flex justify-end items-center">
            {/* <div className="w-full sm:w-1/2 bg-white flex items-center justify-center flex-col">
              <Heading
                text="Latest Story From Us"
                className="mt-3 mb-5 sm:mt-5 sm:mb-10 text-center"
              />
              <Button text="Continue Reading" type="button" />
              <Button text="Visit the Blog" type="button" />
            </div> */}
          </div>
        </div>
        {/* <div className="w-1/2 p-10 h-full flex justify-center ">
          <Heading
            text="Latest Story From Us"
            className="mt-3 mb-5 sm:mt-5 sm:mb-10"
          />
        </div> */}
      </div>
    </>
  );
}

export default Blog;
