import React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Heading from "./Heading";

interface PageProps {
  heading?: string;
  p?: string;
  sign?: string;
  className?: string;
}

export const PageIntro = ({ p, heading, sign, className }: PageProps) => {
  if (heading) {
    return (
      <>
        <section className="section-intro flex items-center justify-center px-3 md:px-0">
          <div className="w-full md:w-3/4 flex flex-col items-center">
            <Heading
              text={heading}
              className="mt-3 mb-5 sm:mt-5  text-center"
            />
            <div
              className={`text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ${
                className ?? " "
              }`}
            >
              <Markdown remarkPlugins={[remarkGfm]}>{p}</Markdown>
            </div>
            {sign && (
              <div className="flex justify-center items-center py-10">
                <h2 className="font-Sebastian text-4xl md:text-6xl">{sign}</h2>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
  return (
    <>
      <section className="section-intro flex items-center justify-center px-3 md:px-0">
        <div className="mt-3 flex items-center md:px-0 w-full md:w-3/4 px-8 text-sm sm:text-base leading-5 sm:leading-7 font-Athelas">
          <Markdown remarkPlugins={[remarkGfm]}>{p}</Markdown>
        </div>
      </section>
    </>
  );
};
