import React, { PropsWithChildren } from "react";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";

type PropType = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

export const DotButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
    </button>
  );
};

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button
      className="mx-3 border flex items-center justify-center border-secondary rounded-full h-10 w-10 px-5"
      type="button"
      {...restProps}
    >
      <div>
        <GoArrowLeft />
      </div>

      {children}
    </button>
  );
};

export const NextButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button
      className="mx-3 border flex items-center justify-center border-secondary rounded-full h-10 w-10 px-5"
      type="button"
      {...restProps}
    >
      <div>
        <GoArrowRight />
      </div>

      {children}
    </button>
  );
};
