import React from "react";

interface ButtonPros {
  text: string;
  onClick?: (e?: any) => void;
  type?: "submit" | "reset" | "button";
  className?: string;
}

export default function Button({ text, onClick, type, className }: ButtonPros) {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium  bg-mainColor/95 border border-colorTextSecond text-white p-3 tracking-wider capitalize rounded-md hover:bg-mainColor/100 ${
        className ?? ""
      }`}
    >
      {text}
    </button>
  );
}
