import React from "react";
import Imgix from "react-imgix";

interface ImageProp {
  src: string;
  className?: string;
}

function LazyImage({ src, className }: ImageProp) {
  console.log("LazyImage src:", src);
  return (
    <Imgix
      className={`${className} object-cover w-full h-full`}
      src={src}
      htmlAttributes={{
        "data-src": `${src}`,
        "data-srcset": `${src} 300w, ${src}  600w, ${src} 900w`,
        "data-sizes": "auto",
        className: "lazyload",
      }}
    />
  );
}

export default LazyImage;
