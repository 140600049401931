import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { getAllSafaris, safariPackage } from "../api/firebase";
import { SafariPackage } from "../utils/interface";

interface FilterProps {
  key?: string;
  value?: string;
  slug: string;
}

export default function useSafaris(filter: Partial<FilterProps> = {}) {
  const { slug } = filter;

  //Get All Safari Packages
  const allSafarisQuery: UseQueryResult<SafariPackage[], Error> = useQuery({
    queryKey: ["safaris"],
    queryFn: async () => await getAllSafaris(),
    // @ts-ignore
    config: {
      staleTime: 1000 * 60,
      keepPreviousData: true,
    },
  });

  // Get Safari Package by slug
  const fetchSafariPackage: UseQueryResult<SafariPackage | null, Error> =
    useQuery({
      queryKey: ["safaripackage", filter],
      queryFn: async () => await safariPackage({ slug: slug as string }),
    });

  return {
    allSafarisQuery,
    fetchSafariPackage,
  };
}
