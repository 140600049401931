import FilterResults from "./FilterResults";
import Heading from "./Heading";

export default function SearchDestination() {
  return (
    <div className="container">
      <Heading text="Destination Search" />
      <FilterResults />
    </div>
  );
}
