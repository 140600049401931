import React, { useState } from "react";
import Button from "./Button";
import Imgix from "react-imgix";
import Modal from "./Modal";

interface ImageProps {
  image: string;
  mainText: string;
  subText?: string;
  className?: string;
}

export const PageHero = ({
  image,
  mainText,
  subText,
  className,
}: ImageProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  };
  return (
    <>
      <div
        className="relative w-full h-full before:content-['']
          before:absolute before:inset-0 before:bg-black before:opacity-50"
      >
        <Imgix
          className={`w-screen h-full min-h-96 md:max-h-[95vh] object-cover ${
            className ?? ""
          }`}
          src={image}
          width={800}
          height={600}
          htmlAttributes={{
            sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 100vw, 100vw",
          }}
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 h-full w-full">
          <div className="text-center relative flex z-[5] flex-col h-full w-full items-center justify-center">
            <h1 className="font-fontMainBold font-bold capitalize lg:text-6xl md:text-5xl text-3xl text-white text-center px-3">
              {mainText}
            </h1>
            <div className="flex flex-col justify-between items-center">
              <p className="font-Averia text-sm sm:text-base my-6 text-white max-w-[40rem] capitalize md:text-base font-bold tracking-wider">
                {subText}
              </p>
              <Modal
                open={open}
                setOpen={setOpen}
                title="Get in touch for inquiries and booking your safari adventure"
              />
              <Button
                text="Book My safari"
                type="button"
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
