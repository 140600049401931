import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

interface ButtonPros {
  text: string;
  href: string;
}

export default function MenuSidebarLink({ text, href }: ButtonPros) {
  return (
    <Link to={href}>
      <Button text={text} type="button" className="w-full" />
    </Link>
  );
}
