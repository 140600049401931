import React from "react";
import Imgix from "react-imgix";
import { Link } from "react-router-dom";

interface DataProp {
  dataImage: {
    name: string;
    details: string;
    image: string;
    disabled: boolean;
  }[];
}

export default function DestinationBanner({ dataImage }: DataProp) {
  return (
    <>
      <div className="grid-cols-4 md:gap-5 gap-2 hidden lg:grid">
        {dataImage.map((photourl, i) => (
          <Link
            to={photourl.disabled ? "#" : `destinations/${photourl.name}`}
            key={i}
            className="relative min-h-full md:min-h-96 after:content-['']
          after:absolute after:inset-0 after:bg-black after:opacity-30 after:hover:opacity-65 after:transition-all after:duration-500"
          >
            <Imgix
              className="lazyload h-full object-cover"
              src={`${photourl.image}?auto=format`}
              width={800}
              height={600}
              htmlAttributes={{
                sizes:
                  "(max-width: 600px) 100vw, (max-width: 1200px) 100vw, 100vw",
              }}
            />

            <div className="absolute top-0 left-0 right-0 bottom-0 h-full w-full">
              <div className="relative flex z-[5] flex-col h-full w-full items-center justify-center">
                <h1 className=" font-AthelasBold cursor-pointer font-extrabold capitalize text-4xl text-center text-white">
                  {photourl.name}
                </h1>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
}
