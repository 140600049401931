import React from "react";
import PageTemplate from "../components/templates/PageTemplate";
import { useLocation } from "react-router-dom";
import Head from "../components/seo/Head";

export default function Responsibility() {
  const location = useLocation();
  return (
    <>
      <Head
        title="Our Responsibility"
        addPostfixTitle={true}
        link={location.pathname}
        description=""
      />
      <PageTemplate
        subTitle="Tourism that takes full account of its current and future economic, social and environmental impacts, addressing the needs of visitors, the industry, the environment and host communities."
        mainText="RESPONSIBILITY"
        heading="Vision"
        headingTwo="Mission"
        firstParagraph="Embrace a transformative safari journey with us, where conservation meets exploration in harmony. Our vision is to curate exceptional safari experiences that not only showcase the unparalleled beauty of wildlife and landscapes but also uphold our commitment to environmental stewardship and community empowerment. Through sustainable practices and deep respect for local cultures, we aim to create meaningful connections between travelers and the natural world, fostering a profound appreciation for biodiversity and conservation efforts. Join us on a safari adventure where every moment is thoughtfully crafted to inspire, educate, and preserve, ensuring that future generations can continue to explore and cherish our planet's most extraordinary destinations. Together, let's embark on a journey that leaves a positive impact, one safari at a time."
        secondParagraph="Our mission is to provide unforgettable safari experiences while championing environmental conservation and empowering local communities. Through responsible tourism practices, we strive to minimize our ecological footprint and preserve the natural habitats we explore. We are dedicated to supporting sustainable initiatives that protect wildlife and promote biodiversity. We aim to enrich travelers' lives by fostering deep connections with nature and cultural heritage, guided by knowledgeable experts who share our passion for conservation. By engaging with integrity, transparency, and respect for all stakeholders, we aspire to be a leader in ethical tourism, ensuring that every safari with us leaves a positive impact on both the environment and the communities we visit."
        sign="Divine Travel Nest Safaris"
        mainImg="https://images.unsplash.com/photo-1542601906990-b4d3fb778b09"
        secImgTwo="https://images.unsplash.com/photo-1605230521018-61f2095a762a"
        secImg="https://images.unsplash.com/photo-1657644894968-cc7dd0c56842"
        textslide={false}
      />
    </>
  );
}
