import React, { useEffect, useState } from "react";
import { PageHero } from "../components/ui/PageHero";
import { PageIntro } from "../components/ui/PageIntro";
import useSafaris from "../hooks/useSafaris";
import { SafariPackage } from "../utils/interface";
import SafariCard from "../components/ui/SafariCard";

import Loader from "../components/ui/Loader";
import { useLocation } from "react-router-dom";
import Head from "../components/seo/Head";

export default function Tours() {
  const [safariPackageData, setSafariPackageData] = useState<SafariPackage[]>(
    []
  );
  const {
    allSafarisQuery: { isLoading: itemLoading, error, data },
  } = useSafaris();

  const location = useLocation();
  useEffect(() => {
    if (data && data.length > 0) {
      setSafariPackageData(data);
    }
  }, [data]);

  return (
    <>
      <Head
        keywords={[
          "massai mara",
          "Kenya safaris",
          "Uganda safaris",
          "Tanzania safaris",
        ]}
        title="Best East Africa Safari tours and guide Packages"
        link={location.pathname}
        description="Choose Divine Travel Nest Safari for your next adventure and let us redefine your expectations of safari tours and travels. Welcome to a world where the wild meets luxury, and every moment is filled with wonder and awe."
      />

      <PageHero
        image="https://images.unsplash.com/photo-1574451426683-7f812931c7e5"
        mainText="Luxury family trips, safaris & tours"
        subText="Let us host your vacation"
      />
      <PageIntro
        sign="Made to measure"
        heading="African Tours & Safaris"
        p="There is a safari experience waiting for each and every traveler in East Africa; what will your trip narrative be? There is a wide variety of itineraries and trip packages available for African safaris, ranging from “bucket list” experiences to ultra-luxurious vacations and everything in between.

Keep in mind that all of our itineraries are completely adaptable and may be altered to meet your specific needs.

Lacking the time to look around? Get in touch with an Africa Safari Curator to arrange a trip that is designed specifically around your preferences, interests, and financial constraints."
        className="px-8"
      />

      <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 w-full px-4 my-5 ">
        <>
          {error && <h1>Error</h1>}
          {itemLoading && (
            <div className="h-screen w-screen flex justify-center items-center">
              <Loader />
            </div>
          )}
          {safariPackageData?.map((safariProduct) => {
            return (
              <li
                key={safariProduct.id}
                className="flex flex-col justify-between"
              >
                <SafariCard
                  id={safariProduct.id}
                  image={safariProduct.image_url}
                  description={safariProduct.safari_package_description}
                  title={safariProduct.title}
                  featured={safariProduct.featured}
                  price={safariProduct.prices_midrange}
                  days={safariProduct.details?.days}
                  priceFrom={safariProduct.details.price_from}
                  category={safariProduct.safari_package_category}
                  highlights={safariProduct.safari_package_highlights}
                  slug={safariProduct.slug}
                />
              </li>
            );
          })}
        </>
      </ul>
    </>
  );
}
