import React from "react";
import { PageIntro } from "../components/ui/PageIntro";
import Head from "../components/seo/Head";
import { useLocation } from "react-router-dom";

export default function PrivacyPolicy() {
  const location = useLocation();
  return (
    <>
      <Head
        title="Privacy Policy"
        link={location.pathname}
        addPostfixTitle={true}
      />

      <PageIntro
        heading="PRIVACY POLICY"
        p="This privacy notice for Divine Travel Nest Safaris (“Company,” “we,” “us,” or “our“), describes how and why we might collect, store, use, and/or share (“process“) your information when you use our services (“Services“), such as when you:"
      />
      <div className="flex justify-center px-10 mt-3">
        <ul className="list-disc w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            Visit our website at https://www.Divinetravelnestsafaris.com, or any
            website of ours that links to this privacy notice
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
      </div>
      <PageIntro
        heading="SUMMARY OF KEY POINTS"
        p="This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for."
      />
      <section className="section-intro flex items-center justify-center px-3 md:px-0">
        <div className="w-full md:w-3/4 mt-5 flex flex-col pt-14">
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">
              What personal information do we process?
            </span>
            When you visit, use, or navigate our Services, we may process
            personal information depending on how you interact with Divine
            Travel Nest Safaris and the Services, the choices you make, and the
            products and features you use.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">
              Do we process any sensitive personal information?
            </span>
            We do not process sensitive personal information.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">
              Do we receive any information from third parties?
            </span>
            We may receive information from public databases, marketing
            partners, social media platforms, and other outside sources. Learn
            more about information collected from other sources.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">
              How do we process your information?
            </span>
            We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention,
            and to comply with law. We may also process your information for
            other purposes with your consent. We process your information only
            when we have a valid legal reason to do so.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">
              In what situations and with which parties do we share personal
              information?
            </span>
            We may share information in specific situations and with specific
            third parties.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">
              How do we keep your information safe?
            </span>
            We have organizational and technical processes and procedures in
            place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can
            be guaranteed to be 100% secure, so we cannot promise or guarantee
            that hackers, cybercriminals, or other unauthorized third parties
            will not be able to defeat our security and improperly collect,
            access, steal, or modify your information.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">What are your rights?</span>
            Depending on where you are located geographically, the applicable
            privacy law may mean you have certain rights regarding your personal
            information.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">
              How do you exercise your rights?
            </span>
            The easiest way to exercise your rights is by submitting a data
            subject access request, or by contacting us. We will consider and
            act upon any request in accordance with applicable data protection
            laws.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            Want to learn more about what Divine Travel Nest Safaris does with
            any information we collect? Review the privacy notice in full below.
          </p>
        </div>
      </section>
      <PageIntro heading="Table Of content" />
      <div className="flex justify-center px-10">
        <ol className="list-decimal w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            WHAT INFORMATION DO WE COLLECT?
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            HOW DO WE PROCESS YOUR INFORMATION?
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            HOW LONG DO WE KEEP YOUR INFORMATION?
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            HOW DO WE KEEP YOUR INFORMATION SAFE?
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            DO WE MAKE UPDATES TO THIS NOTICE?
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </li>
        </ol>
      </div>
      <PageIntro
        heading="WHAT INFORMATION DO WE COLLECT?"
        p="Personal information you disclose to us, Information automatically collected such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services."
      />
      <section className="section-intro flex items-center justify-center px-3 md:px-0">
        <div className="w-full md:w-3/4 mt-5 flex flex-col pt-14">
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            We collect personal information that you voluntarily provide to us
            when you express an interest in obtaining information about us or
            our products and Services, when you participate in activities on the
            Services, or otherwise when you contact us.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            Personal Information Provided by You. The personal information that
            we collect depends on the context of your interactions with us and
            the Services, the choices you make, and the products and features
            you use. The personal information we collect may include the
            following:
          </p>
          <ul className="list-disc w-full md:w-3/4 ml-5">
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              Name
            </li>
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              Phone number
            </li>
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              Email address
            </li>
          </ul>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">Sensitive Information</span>
            We do not process sensitive information.
          </p>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold mr-1">Payment Data</span>
            We may collect data necessary to process your payment if you make
            purchases, such as your payment instrument number, and the security
            code associated with your payment instrument. All payment data is
            stored by{" "}
            <a
              className="inline cursor-pointer text-primary hover:underline"
              href="https://www.pesapal.com/"
            >
              pesapal
            </a>
            . You may find their privacy notice link(s) here:{" "}
            <a
              className="inline cursor-pointer text-primary hover:underline"
              href="  https://www.pesapal.com/privacy-policy"
            >
              pesapal privacy policy
            </a>
            .<br /> All personal information that you provide to us must be
            true, complete, and accurate, and you must notify us of any changes
            to such personal information.
          </p>
        </div>
      </section>
      <PageIntro
        heading="HOW DO WE PROCESS YOUR INFORMATION?"
        p="We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent."
      />
      <section className="section-intro flex items-center justify-center px-3 md:px-0">
        <div className="w-full md:w-3/4 mt-5 flex flex-col pt-14">
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>

          <ul className="list-disc w-full md:w-3/4 ml-5">
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              To deliver and facilitate delivery of services to the user. We may
              process your information to provide you with the requested
              service.
            </li>
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              To respond to user inquiries/offer support to users. We may
              process your information to respond to your inquiries and solve
              any potential issues you might have with the requested service.
            </li>
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              To send administrative information to you. We may process your
              information to send you details about our products and services,
              changes to our terms and policies, and other similar information.
            </li>
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              To fulfill and manage your orders. We may process your information
              to fulfill and manage your orders, payments, returns, and
              exchanges made through the Services.
            </li>
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              To send you marketing and promotional communications. We may
              process the personal information you send to us for our marketing
              purposes, if this is in accordance with your marketing
              preferences. You can opt out of our marketing emails at any time.
            </li>
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              To protect our Services. We may process your information as part
              of our efforts to keep our Services safe and secure, including
              fraud monitoring and prevention.
            </li>
            <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
              To save or protect an individual’s vital interest. We may process
              your information when necessary to save or protect an individual’s
              vital interest, such as to prevent harm.
            </li>
          </ul>
        </div>
      </section>
      <PageIntro
        heading="WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
        p="We may need to share your personal information in the following situations: Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company. When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us."
      />
      <PageIntro
        heading="DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?"
        p="We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice."
      />
      <PageIntro
        heading="HOW LONG DO WE KEEP YOUR INFORMATION?"
        p="We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible."
      />
      <PageIntro
        heading="HOW DO WE KEEP YOUR INFORMATION SAFE?"
        p="We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment."
      />
      <PageIntro
        heading="DO WE MAKE UPDATES TO THIS NOTICE?"
        p="We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information."
      />
      <PageIntro
        heading="HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
        p="If you have questions or comments about this notice, you may email us at info@imaraafricasafaris.com or contact us by post at:"
      />
      <PageIntro
        heading="HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?"
        p="Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a data subject access request."
      />
      <div className="h-10"></div>
    </>
  );
}
