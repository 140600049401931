import React from "react";
import Navbar from "./components/layout/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./components/layout/Footer";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Head from "./components/seo/Head";

function App() {
  const queryClient = new QueryClient();
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Head />
        <Navbar />
        <Outlet />
        <Footer />
      </QueryClientProvider>
    </>
  );
}
export default App;
