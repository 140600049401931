import React from "react";
interface HeadingPros {
  text: string;
  className?: string;
}

export default function Heading({ text, className }: HeadingPros) {
  return (
    <>
      <div
        className={`flex items-center justify-center  relative flex-col px-2${
          className ?? ""
        }`}
      >
        <h1 className=" font-fontMainBold font-semibold text-3xl md:text-4xl capitalize text-center">
          {text}
        </h1>
        <div className="w-[20%] h-[1px] bg-colorTextBase"></div>
      </div>
    </>
  );
}
