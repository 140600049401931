import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { BlogPost, SafariPackage } from "../utils/interface";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_Id,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getFirestore(app);

/* Tour Safaris Get All Safari */
export async function getAllSafaris() {
  try {
    const safariSnapshot = await getDocs(
      collection(database, "safari_packages")
    );

    const results: SafariPackage[] = [];

    safariSnapshot.forEach((doc) => {
      const safariData = doc.data() as SafariPackage;
      results.push({ ...safariData, id: doc.id });
    });

    return results;
  } catch (error) {
    console.error("Error fetching all safaris:", error);
    throw error;
  }
}

// Function to fetch a single safari package
export async function safariPackage({ slug }: { slug: string }) {
  try {
    // Query the collection where 'slug' field matches the provided value
    const querySnapshot = await getDocs(
      query(collection(database, "safari_packages"), where("slug", "==", slug))
    );

    if (!querySnapshot.empty) {
      const safariDoc = querySnapshot.docs[0];
      const data = safariDoc.data();
      //console.log({ id: safariDoc.id, ...data });
      return { id: safariDoc.id, ...data };
    } else {
      console.log("No data available for safari slug:", slug);
      return null;
    }
  } catch (error) {
    console.error("Error fetching safari package:", error);
    throw error;
  }
}

/* Get All Safari Blog Post */
export async function getAllBlogs() {
  try {
    const blogSnapshot = await getDocs(collection(database, "blog"));

    const results: BlogPost[] = [];

    blogSnapshot.forEach((doc) => {
      const blogPostData = doc.data() as BlogPost;
      results.push({ ...blogPostData, id: doc.id });
    });

    return results;
  } catch (error) {
    console.error("Error fetching all safaris:", error);
    throw error;
  }
}
// Function to fetch a single Blog
export async function getBlog({ slug }: { slug: string }) {
  try {
    // Query the collection where 'slug' field matches the provided value
    const querySnapshot = await getDocs(
      query(collection(database, "blog"), where("slug", "==", slug))
    );

    if (!querySnapshot.empty) {
      const blogDoc = querySnapshot.docs[0];
      const data = blogDoc.data();

      return { id: blogDoc.id, ...data };
    } else {
      console.log("No data available for:", slug);
      return null;
    }
  } catch (error) {
    console.error("Error fetching blog:", error);
    throw error;
  }
}

// Initialize Firebase Storage
const storage = getStorage();

// Example function to download image
export async function downloadImage(imageRef: string) {
  try {
    const url = await getDownloadURL(ref(storage, imageRef));
    return url;
  } catch (error) {
    console.error("Error downloading image:", error);
  }
}
