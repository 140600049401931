import { useEffect, useState } from "react";
import { PageHero } from "../components/ui/PageHero";
import { PageIntro } from "../components/ui/PageIntro";
import { BlogPost } from "../utils/interface";
import { Link, useLocation } from "react-router-dom";
import Head from "../components/seo/Head";
import useBlogBost from "../hooks/useBlogPost";
import { downloadImage } from "../api/firebase";
import { timeStampConverter } from "../utils/timeStampConverter";
import Button from "../components/ui/Button";
import Loader from "../components/ui/Loader";

interface ImageUrls {
  [key: string]: string;
}

export default function Blogs() {
  const [blogPostData, setBlogPostData] = useState<BlogPost[]>([]);
  const [imageUrls, setImageUrls] = useState<{ [key: string]: string }>({});
  const {
    allBlogPost: { isLoading: itemLoading, error, data },
  } = useBlogBost();

  const location = useLocation();

  useEffect(() => {
    if (data && data.length > 0) {
      setBlogPostData(data);
    }
  }, [data]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        blogPostData.map(async (blog) => {
          const url = await downloadImage(blog.header_image);
          return { slug: blog.slug, url };
        })
      );

      const filteredUrls = urls.filter((item) => item.url !== undefined);

      const urlsObject = filteredUrls.reduce((acc, { slug, url }) => {
        acc[slug] = url as string;
        return acc;
      }, {} as ImageUrls);

      setImageUrls(urlsObject);
    };

    if (blogPostData.length > 0) {
      fetchImageUrls();
    }
  }, [blogPostData]);

  return (
    <>
      <Head
        title="Our Blog Page"
        addPostfixTitle
        link={location.pathname}
        description="Divine travel nest safaris travel blog post, guides, reviews, insight guides and much more."
      />
      <PageHero
        image="https://images.unsplash.com/uploads/1411400493228e06a6315/ad711a20"
        mainText="Blog"
        subText="Look deep into nature, and then you will understand everything better"
      />
      <PageIntro
        sign="Blogs"
        heading="Blog List Page"
        p="Here, you'll discover a wealth of information about exotic destinations, wildlife encounters, sustainable travel tips, and cultural experiences that redefine what it means to explore responsibly. Our goal is to ignite your wanderlust while fostering a deeper appreciation for the diverse landscapes and creatures that share our planet. Seat Back and relax as we create enjoyable content for you"
        className="px-8"
      />
      <div className="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-5 pb-10">
        {itemLoading && (
          <>
            <div className="h-screen w-screen flex justify-center items-center">
              <Loader />
            </div>
          </>
        )}
        {error && <h1 className="text-9xl">error...</h1>}
        {blogPostData.map((blog) => (
          <>
            <Link
              to={`/blog/${blog.slug}`}
              key={blog.id}
              className="relative shadow p-4 duration-300 ease-in-out transition-transform transform hover:-translate-y-2 bg-colorCard"
            >
              <div className="flex justify-center relative  overflow-hidden h-52">
                <div className="transition-transform duration-500 transform ease-in-out hover:scale-110 w-full ">
                  <img
                    src={imageUrls[blog.slug]}
                    className=" h-full w-full object-cover"
                    alt={`${blog.slug}-pic`}
                  />
                  <div className="absolute inset-0 bg-black opacity-25"></div>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 items-center mt-6 border-b border-colorTextBase pb-3">
                <div className="flex flex-wrap gap-3">
                  <h3 className="text-xs font-medium uppercase rounded-full py-1.5 px-2.5 border border-colorTextBase tracking-wide whitespace-nowrap">
                    {blog.category}
                  </h3>
                </div>
                <div className="text-sm data-color flex items-center">
                  <span className="whitespace-nowrap">
                    {timeStampConverter(blog.publish_date, "formattedDate")}
                  </span>
                  <span className="px-2.5">⋅</span>
                  <span className="whitespace-nowrap">
                    {blog.read_time} min read
                  </span>
                </div>
              </div>
              <h2 className="tracking-wider font-bold text-xs md:text-sm line-clamp-3 pt-2 capitalize">
                {blog.name}
              </h2>
              <p className="pt-2 text-xs line-clamp-4">
                {blog.first_paragraph_preview}
              </p>
              <Button text="Read More" type="button" className="my-3" />
            </Link>
          </>
        ))}
      </div>
    </>
  );
}
