import React from "react";
import Imgix from "react-imgix";

export const WhyUs = () => {
  return (
    <>
      <div className=" max-w-[30rem]">
        <Imgix
          src="https://res.cloudinary.com/ddwkntgdf/image/upload/v1707475395/dpwmunrfiuyujcakvndd.jpg?auto=format"
          width={800}
          height={800}
          htmlAttributes={{
            sizes: "(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 50vw",
          }}
        />
      </div>
    </>
  );
};
