export const blogCategories = [
  "Safari Destinations",
  "Safari Types",
  "Safari Experience",
  "Safari Tips and Guides",
  "Safari lodges and camps",
  "Cultural Experiences",
  "Wildlife and Nature",
  "Adventure Activities",
  "Personal Safari Stories",
  "Tech and Gear",
  "Safari Planning",
];
