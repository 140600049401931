import React from "react";
import { Link } from "react-router-dom";

export const Error = () => {
  return (
    <>
      <section className="flex flex-col h-screen items-center justify-center">
        <img
          src="/assets/images/icons/404-error-with-a-landscape-cuate.png"
          alt="404 error with a landscape"
          className="pricing-img h-96"
        />
        <p className="">
          It looks like nothing was found at this location. You can either go
          back to the last page or go to{" "}
          <Link
            to="/"
            className="inline-block text-colorTextSecond hover:underline"
          >
            Home Page
          </Link>
        </p>
      </section>
    </>
  );
};
