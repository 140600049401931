import React from "react";
import { PageIntro } from "../components/ui/PageIntro";
import Heading from "../components/ui/Heading";
import Head from "../components/seo/Head";
import { useLocation } from "react-router-dom";

export default function SafariTerms() {
  const location = useLocation();
  return (
    <>
      <Head
        title="Safari Terms"
        link={location.pathname}
        addPostfixTitle={true}
      />

      <PageIntro
        heading="Booking Terms / Payment Conditions"
        p="By making safari bookings on our website or with one of our agents, or
          otherwise purchasing any tour services offered by Divine Travel Nest
          Safaris, you warrant that:"
      />
      <div className="flex justify-center px-10">
        <ol className="list-decimal w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            you are at least 18 years of age and have the legal authority to
            create a binding legal obligation;
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            You accept and agree to all the Terms presented herein.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            All information supplied by you for the purposes of that booking is
            true, accurate, current, and complete to the best of your knowledge.
          </li>
        </ol>
      </div>
      <PageIntro
        heading="Booking On Behalf Of Others"
        p="If you make a booking for anyone other than yourself, you are considered the designated contact person for those other travelers. You represent and warrant that you are legally authorized to act on their behalf; that you have obtained all required consents; and that you will inform them of these Terms and warrant that they accept and agree to them. You are also responsible for making all payments due for your booking; notifying us if any changes or cancellations are required; and keeping the other travelers informed of all information relevant to your trip."
      />
      <PageIntro heading="Booking Your Holiday" />
      <div className="flex justify-center px-10">
        <ol className="list-decimal w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            Your contract in respect of your safari in East Africa is made with
            Divine Travel Nest Safaris (‘the Company‘), registered in Kenya and
            all bookings are subject to these terms and conditions.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            All communications by the Company in relation to your holiday will
            be sent to the address stated on the booking form.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            All bookings must be made through an authorized representative of
            the Company. At the time of booking the Company booking form must be
            completed and submitted together with a deposit of 30% of the total
            cost of the booking.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas">
            Receipt of the deposit and booking form by the Company does neither
            guarantee nor imply confirmation of the booking. No booking shall be
            confirmed until the Company issues a written notice. The Company
            reserves the right to refuse a booking without giving any reason and
            shall in that event return any deposit received.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas">
            Your holiday must be paid in full at least 45 days before the
            departure date. If payment is not received by the due date, the
            Company reserves the right to cancel the booking and retain the
            deposit. The person who signs the booking form guarantees payment of
            the total amount shown on the booking form in accordance with these
            conditions. It is the responsibility of the signatory to ensure the
            Company receives payment in full by the due date. No reminders will
            be sent.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas">
            If you book within 30 days of your departure date payment must be
            made in full at the time of booking.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas">
            All special requests, such as dietary requirements, should be noted
            on booking via email or on the booking form.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas">
            The Company will provide the service as set out and confirmed in
            writing on the itinerary.
          </li>
        </ol>
      </div>
      <PageIntro heading="Price Policy" />
      <div className="flex justify-center px-10">
        <ol className="list-decimal w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            The Company is under no obligation to furnish a breakdown of the
            costs involved in a holiday.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            The Company reserves the right to notify you of any increase in
            price before accepting your booking.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            You have the option to make a full payment for your vacation at the
            time that you make your reservation. If you do this, the price of
            your vacation will be locked in at the rate that was originally
            provided to you by the Company. You should send the Confirmation
            Invoice back to the Company along with the complete payment so that
            it reaches the Company within seven days of the date that is
            indicated on the Confirmation Invoice if you want to be eligible for
            this benefit.
          </li>
        </ol>
      </div>
      <PageIntro heading="Cancellation and Changes by the Company" />
      <div className="flex justify-center px-10">
        <ol className="list-decimal w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            Occasionally it may be deemed necessary to make changes to your
            holiday and the Company reserves the right to do so at any time, and
            you will be notified of any changes at the earliest possible
            opportunity. If a major change to your tour is necessary, providing
            it does not arise from circumstances beyond the Company’s control,
            you may choose (i) to accept the change of arrangements (ii) to
            purchase another holiday from us or (iii) to cancel your holiday.
            Compensation may be payable in cases of major change as detailed
            below.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            Compensation will not be paid for changes or cancellations caused by
            Acts of God (Force Majeure), war, threat of war, riot, civil strike,
            industrial dispute, terrorist activity, natural or man-made
            disaster, fire, technical problems to transport, closure or
            congestion of airports, strikes or other industrial action, adverse
            weather conditions or any other event beyond the Company’s control.
            It is essential that you take out adequate travel insurance.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            The Company reserve the right to cancel your holiday at any time
            before the date of departure, even after a confirmation notice has
            been sent. If your holiday is cancelled the Company will refund in
            full the money you have already paid. No compensation will be
            payable.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            Transportation is subject to the conditions of carriage of the
            carrier, some of which may limit or exclude liability. These
            conditions are often the subject of international agreement between
            countries and copies of the conditions, which apply to your holiday
            journey, will normally be found on the carrier’s tickets.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            If you fail to pay the balance of the holiday price at least 30 days
            before departure, the Company will treat the booking as cancelled
            and levy the cancellation charges set out below
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            The company also has the right to refuse any person as a member of
            the tour, if in their opinion that person could endanger the health,
            safety and enjoyment of others on the tour. In any of the events
            mentioned above, the company’s sole liability and the client’s sole
            remedy will be limited to a refund of any monies paid, less the
            amount for services already utilised plus administrative fees.
          </li>
        </ol>
      </div>
      <PageIntro
        heading="Cancellation and Changes by You"
        p="You may cancel your holiday at any time providing you notify the Company in writing. The following charges will be levied on any cancellation:"
      />
      <div className="flex justify-center px-10">
        <ol className="list-decimal w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            30 days or more 50% of Deposit
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            15 – 30 days 100% of Deposit
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            8 – 15 days 80% of Tour Cost
          </li>

          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            Less than 5 days and no show 100% of Tour Cost
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            WE OFFER 100% FULL REFUND TO ANY CANCELATIONS PRIOR 90DAYS
          </li>
        </ol>
      </div>
      <PageIntro heading=" Our Responsibilities" />
      <div className="flex justify-center px-10">
        <ol className="list-decimal w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            The Company does not own or manage the aircraft, accommodation,
            restaurants and other facilities used in conjunction with the tours
            arranged. While the Company has exercised care in selecting
            providers of travel, accommodation, restaurants and other
            facilities, the Company have not had the opportunity to inspect and
            do not represent that such aircraft, accommodation, restaurants, and
            other facilities and services have been inspected.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            The Company is not responsible if you or any member of your party
            suffer death, illness or injury as a result of any failure to
            perform or improper performance of any part of our contract with you
            where such failure is attributable to (i) the acts and/or omissions
            of any member of the party, or (ii) those of a third party not
            connected with the provision of your holiday, or (iii) an event
            which neither the Company or the service provider could have
            foreseen or prevented even with due care.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            Should any member of your party suffer illness, injury or death
            through misadventure arising out of an activity, which does not form
            part of the holiday the Company has arranged for you the Company
            cannot accept liability. The Company will offer general assistance
            where appropriate.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            The Company regret that no refund will be made on unused tickets
            where travel, sporting event or other types of ticket, unless a
            refund can be obtained from the carrier or provider.
          </li>
        </ol>
      </div>
      <PageIntro heading="Your Responsibilities" />
      <div className="flex justify-center px-10">
        <ol className="list-decimal w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            You are responsible for arranging your own travel insurance, though
            an appropriate policy can be incorporated into your tour package.
            Details of all insurance policies held by each member of your party
            must be provided on the booking form. No booking forms can be
            accepted without sufficient proof that personal policies have been
            arranged. Please ensure that your insurance cover applies to the
            specific activities you are booking and which are confirmed on the
            Confirmation Invoice.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            Each member of the party must have a valid passport, visas and all
            necessary documentation for the countries they are touring. The
            Company accept no responsibility for any delay or expense should
            your documents not be in order.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            You are responsible for checking-in for international flights at the
            correct time and for presenting yourself to take up all pre-booked
            components of your holiday. The Company cannot accept responsibility
            for clients missing international flights as a result of late
            check-ins and no credit or refunds will be given if you fail to take
            up any component of your holiday. No credit of refunds will be given
            for lost, mislaid or destroyed travel documents.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            By booking a safari with the Company you undertake to behave in an
            orderly manner and not to disrupt the enjoyment of others on holiday
            with you nor to do anything to bring the reputation of the Company
            into disrepute. If you breach this clause your holiday will be
            terminated with immediate effect and the Company will have no
            further contractual obligation to you. The Company will be entitled
            to recover from the offending party and/or the person who signed the
            booking form compensation for any damage caused.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            It is the responsibility of the person who signs the Booking Form to
            disclose any pre-existing medical conditions that members of their
            party may have.
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            All equipment and personal effects shall be all times and in all
            circumstances at the owner’s risk. The Company cannot accept
            responsibility for any loss or damage or delay to your luggage or
            effects unless directly caused by the negligence of one of our
            representatives.
          </li>
        </ol>
      </div>
      <PageIntro
        heading="Refund"
        p="Refunds will be processed and paid as promptly as possible. If we need to recover funds already paid to our partners for your services, your refund will be issued after those funds have been recovered. Most refunds are processed within six weeks of being requested."
      />
      <PageIntro
        heading="Waiver"
        p="The Company will undertake to ensure the safety of the client throughout the tour, however aspects of each particular tour may not be without an element of danger. Clients should be prepared to sign a ‘Waiver of Responsibility’ form before they begin their tour. This form is an understanding that all clients need to exercise judgement and care at all times to ensure their own safety and that of their fellow tourists."
      />
      <PageIntro
        heading="Disclaimer"
        p="The Company has made all reasonable efforts to check the accuracy of the information contained in our website. The Company cannot however accept any responsibility for any errors or omissions that may appear in this site."
      />
      <PageIntro
        heading="Local Purchase"
        p="We cannot accept responsibility for any items that the Client may purchase locally i.e.: jewellery, food, souvenir items  etc. and the quality and value of such cannot be guaranteed. We recommend that the Client check whether or not any extra charges will be payable for import duty or freight and we are unable to assist with any costs that the Client may incur in this respect."
      />
      <PageIntro
        heading="Dissatisfaction/Complaints during Safari"
        p="Any complaints that may arise during safari travel should be communicated immediately or as it happens, to your tour manager (in writing via email/WhatsApp) for on-site attention, verification and immediate correction, a summary of the complaints (in case not addressed on site) shall be communicated to the company in writing within 24hours of completion of Safari tour for necessary action The complaints/issues raised shall be investigated and addressed within 24hours of formal complaint/issue receipt. Incase you need any clarification Feel free to contact us, call or WhatsApp on +254 724 163 662."
      />
      <section className="section-intro flex items-center justify-center px-3 md:px-0">
        <div className="w-full md:w-3/4 mt-5 flex flex-col items-center pt-14">
          <Heading
            text="More Info"
            className="mt-3 mb-5 sm:mt-5 sm:mb-10 text-center"
          />
          <div className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas">
            <p>
              A deposit of 30% of total safari cost is required at the time of
              booking confirmation and balance being payable at or before safari
              start. <br />
              <span className="font-bold">
                If not One can also Pay On Arrival at the Airport.
              </span>
              <br />
              -You may also choose to pay full safari amount at booking
              confirmation if you prefer. <br /> -Divine Travel Nest Safaris
              also has a flexible instalment safari payment option where you can
              pay in advance little instalments for your tour package booking.
              Please discuss this with your tour manager at booking.
            </p>
          </div>
          <div className="flex justify-center px-10">
            <ul className="list-disc  ">
              <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
                You may also pay on-line or locally by credit / debit card;
                Visa/Mastercard/Amex accepted. This mode of payment has a 3.5%
                card surcharge that has to be added on top of the original
                amount. The 3.5% charge does not come to Divine Travel Nest
                Safaris, it is a bank surcharge for credit card transactions.
                (Mastercard & Visa – 3.5%, Amex – 3.5%)
              </li>
              <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
                If you decide to make payments by bank / wire transfer you are
                required to include bank charges incurred on transaction
              </li>
            </ul>
          </div>
          <p className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas mt-5">
            <span className="font-bold text-red-500">
              *CAUTION FOR THOSE PAYING BY BANK / WIRE TRANSFER!
            </span>
            <br />
            Please note that our company bank accounts are located in KENYA
            only. Divine Travel Nest Safaris does not own bank accounts in any
            other City/Country outside Kenya. The Bank account name is as
            specified on payment instructions only
          </p>
        </div>
      </section>
      <div className="h-10"></div>
    </>
  );
}
