import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";
import { headerData } from "../../data/LayoutData";
// import Button from "../ui/Button";
// import { MenuToggle } from "../MenuToggle";
// import Modal from "../ui/Modal";

const App = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  console.log(mobileNavOpen);

  const hideNavItemsVariant = {
    opened: {
      opacity: 0,
      y: "-100%",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 1,
      y: "0%",
      transition: {
        delay: 1.1,
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const mobileMenuVariant = {
    opened: {
      y: "0%",
      transition: {
        delay: 0.15,
        duration: 1.1,
        ease: [0.74, 0, 0.19, 1.02],
      },
    },
    closed: {
      y: "-100%",
      transition: {
        delay: 0.35,
        duration: 0.63,
        ease: [0.74, 0, 0.19, 1.02],
      },
    },
  };

  const fadeInVariant = {
    opened: {
      opacity: 1,
      transition: {
        delay: 1.2,
      },
    },
    closed: { opacity: 0 },
  };

  const ulVariant = {
    opened: {
      transition: {
        delayChildren: 1,
        staggerChildren: 0.18,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.06,
        staggerDirection: -1,
      },
    },
  };

  const liVariant = {
    opened: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.65,
        ease: "easeOut",
      },
    },
    closed: {
      opacity: 0,
      y: "100%",
      transition: {
        duration: 0.25,
        ease: "easeInOut",
      },
    },
  };

  return (
    <main className="container_nav md:hidden shadow-sm">
      <motion.nav
        className="px-5 py-2 flex justify-between"
        initial="closed"
        animate={mobileNavOpen ? "opened" : "closed"}
      >
        <div className="overflow-y-hidden">
          <motion.a
            variants={hideNavItemsVariant}
            className="flex-none rounded-xl text-xl font-semibold focus:outline-none focus:opacity-80"
            href="/"
            aria-label="divine travel nest"
          >
            <img
              className="object-cover object-center w-20"
              src="/logo.png.webp"
              alt="logo"
            />
          </motion.a>
        </div>
        <div
          className={`${
            mobileNavOpen ? "z-0" : "z-50"
          } flex items-center text-base hover:cursor-pointer`}
        >
          <motion.div
            variants={hideNavItemsVariant}
            onClick={() => setMobileNavOpen(true)}
          >
            <svg
              fill="currentColor"
              width="20"
              height="20"
              viewBox="0 0 32 32"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.844 6.050c-0.256-0.256-0.381-0.581-0.381-0.975s0.125-0.719 0.381-0.975 0.581-0.381 0.975-0.381h28.512c0.394 0 0.719 0.125 0.975 0.381s0.381 0.581 0.381 0.975-0.125 0.719-0.381 0.975-0.581 0.381-0.975 0.381h-28.512c-0.394 0-0.719-0.125-0.975-0.381zM31.306 14.963c0.256 0.256 0.381 0.581 0.381 0.975s-0.125 0.719-0.381 0.975-0.581 0.381-0.975 0.381h-28.512c-0.394 0-0.719-0.125-0.975-0.381s-0.381-0.581-0.381-0.975 0.125-0.719 0.381-0.975 0.581-0.381 0.975-0.381h28.512c0.394 0 0.719 0.125 0.975 0.381zM31.306 25.819c0.256 0.256 0.381 0.581 0.381 0.975s-0.125 0.719-0.381 0.975-0.581 0.381-0.975 0.381h-28.512c-0.394 0-0.719-0.125-0.975-0.381s-0.381-0.581-0.381-0.975 0.125-0.719 0.381-0.975 0.581-0.381 0.975-0.381h28.512c0.394 0 0.719 0.131 0.975 0.381z"></path>
            </svg>
          </motion.div>
        </div>
        <motion.div variants={mobileMenuVariant} className="mobile-menu">
          <div className="h-full w-full flex flex-col justify-center items-center">
            <div className="w-full flex justify-between pt-2 px-5">
              <motion.a
                variants={fadeInVariant}
                className="flex-none rounded-xl text-xl font-semibold focus:outline-none focus:opacity-80"
                href="/"
                aria-label="divine travel nest"
              >
                <img
                  className="object-cover object-center w-24"
                  src="/logo.png.webp"
                  alt="logo"
                />
              </motion.a>

              <motion.button
                className={`${
                  mobileNavOpen ? "z-50" : "z-0"
                }  hover:cursor-pointer px-5 py-3`}
                variants={fadeInVariant}
                onClick={() => setMobileNavOpen(false)}
              >
                <svg
                  fill="currentColor"
                  width="30"
                  height="30"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18.8,16l5.5-5.5c0.8-0.8,0.8-2,0-2.8l0,0C24,7.3,23.5,7,23,7c-0.5,0-1,0.2-1.4,0.6L16,13.2l-5.5-5.5  c-0.8-0.8-2.1-0.8-2.8,0C7.3,8,7,8.5,7,9.1s0.2,1,0.6,1.4l5.5,5.5l-5.5,5.5C7.3,21.9,7,22.4,7,23c0,0.5,0.2,1,0.6,1.4  C8,24.8,8.5,25,9,25c0.5,0,1-0.2,1.4-0.6l5.5-5.5l5.5,5.5c0.8,0.8,2.1,0.8,2.8,0c0.8-0.8,0.8-2.1,0-2.8L18.8,16z" />
                </svg>
              </motion.button>
            </div>
            <motion.ul className="list-none w-full px-5 " variants={ulVariant}>
              {headerData.pages.map((page, i) => (
                <motion.li
                  className="select-none overflow-y-hidden "
                  whileTap={{ scale: 0.95 }}
                  key={i}
                >
                  <motion.div variants={liVariant}>
                    <Link
                      to={page.href}
                      className=" text-xl font-bold capitalize hover:cursor-pointer hover:text-colorCard py-2"
                      onClick={() => setMobileNavOpen(false)}
                    >
                      {page.name}
                    </Link>
                    <div className="w-full h-[1px] bg-colorTextBase my-1"></div>
                    <div className="flex gap-2">
                      <ul className="list-none py-2 w-1/2">
                        {page.menuItems.map((subPage, i) => (
                          <motion.li
                            className="py-2"
                            whileTap={{ scale: 0.95 }}
                            key={i}
                          >
                            <Link
                              className="hover:text-colorCard text-sm"
                              to={subPage.href}
                              onClick={() => setMobileNavOpen(false)}
                            >
                              {subPage.text}
                            </Link>
                          </motion.li>
                        ))}
                      </ul>
                      <ul className="list-none py-2 w-1/2 overflow-hidden">
                        {page.sideBar.map((subPage, i) => (
                          <motion.li
                            className="py-2"
                            whileTap={{ scale: 0.95 }}
                            key={i}
                          >
                            <Link
                              className="text-xs cursor-pointer font-medium uppercase rounded-full py-1.5 px-2.5 border border-colorTextBase hover:bg-colorTextBase hover:text-white  tracking-wide whitespace-nowrap"
                              to={subPage.href}
                              onClick={() => setMobileNavOpen(false)}
                            >
                              {subPage.text}
                            </Link>
                          </motion.li>
                        ))}
                      </ul>
                    </div>
                  </motion.div>
                </motion.li>
              ))}
            </motion.ul>
            <motion.div
              variants={fadeInVariant}
              className="flex flex-col justify-between pt-3 cursor-default"
            >
              <h5 className="text-xs">+254 722 595 916 / +254 712 954 545</h5>
              <h5 className="text-xs">divinetravelnestsafarisltd@gmail.com</h5>
            </motion.div>
          </div>
        </motion.div>
      </motion.nav>
    </main>
  );
};

export default App;
