import React, { useState } from "react";
import Button from "../ui/Button";
import Modal from "../ui/Modal";

export default function Advrt() {
  const [open, setOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <div
      className=" h-[34rem] relative bg-[url('/public/assets/images/body/regal-african-safaris-gwZAmtRwxBI-unsplash.jpg')] bg-bottom bg-fixed bg-cover bg-no-repeat before:content-['']
          before:absolute before:inset-0 before:bg-black before:opacity-70"
    >
      <div className="relative w-full h-full flex justify-center items-center z-10 flex-col">
        <h1 className="font-fontMainBold font-bold capitalize lg:text-6xl md:text-5xl text-3xl text-white text-center px-3">
          Africa's Top Wildlife Experiences
        </h1>
        <p className="max-w-[40rem] p-5 capitalize md:text-base text-sm font-bold text-center text-white tracking-wider">
          Whether you are dreaming of a first-hand meeting with the Big 5,
          getting up close and personal to a gorilla, witnessing one of the
          planet's most spectacular natural events, or simply relaxing on a
          pristine beach, our experts will tell you how and where to do it.
        </p>
        <Modal
          open={open}
          setOpen={setOpen}
          title="Get in touch for inquiries and booking your safari adventure"
        />
        <Button
          onClick={handleClick}
          text="Book My Safari"
          type="button"
          className="my-10"
        />
      </div>
    </div>
  );
}
