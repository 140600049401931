import React from "react";
import { PageIntro } from "../components/ui/PageIntro";
import Head from "../components/seo/Head";
import { useLocation } from "react-router-dom";

export default function Terms() {
  const location = useLocation();
  return (
    <>
      <Head
        title="Terms and Conditions"
        link={location.pathname}
        addPostfixTitle={true}
      />

      <PageIntro
        heading="Introduction"
        p="These Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And Conditions”) contained herein on this webpage, shall govern your use of this website, including all pages within this website. These Terms apply in full force and effect to your use of this Website and by using this Website; you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Website Standard Terms And Conditions."
      />
      <PageIntro
        heading="Intellectual Property Rights"
        p="Other than content you own, which you may have opted to include on this Website, under these Terms, Divine Travel Nest Safaris and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website."
      />
      <PageIntro
        heading="Restrictions"
        p="You are expressly and emphatically restricted from all of the following:"
      />
      <div className="flex justify-center px-10 mt-3">
        <ol className="list-decimal w-full md:w-3/4 ">
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            publishing any Website material in any media;
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            selling, sublicensing and/or otherwise commercializing any Website
            material;
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            publicly performing and/or showing any Website material;
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            using this Website in any way that is, or may be, damaging to this
            Website;
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            using this Website in any way that impacts user access to this
            Website;
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            using this Website contrary to applicable laws and regulations, or
            in a way that causes, or may cause, harm to the Website, or to any
            person or business entity;
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            engaging in any data mining, data harvesting, data extracting or any
            other similar activity in relation to this Website, or while using
            this Website;
          </li>
          <li className="text-sm sm:text-base leading-5 sm:leading-7 font-Athelas ">
            using this Website to engage in any advertising or marketing
          </li>
        </ol>
      </div>
      <PageIntro p="Certain areas of this Website are restricted from access by you and Divine Travel Nest Safaris may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information." />
      <PageIntro
        heading="No warranties"
        p="This Website is provided “as is,” with all faults, and Divine Travel Nest  Safaris makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you."
      />
      <PageIntro
        heading="Limitation of liability"
        p="In no event shall Divine Travel Nest Safaris, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and Devine Travel Nest Safaris, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website."
      />
      <PageIntro
        heading="Indemnification"
        p="You hereby indemnify to the fullest extent Divine Travel Nest Safaris from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your use of this website or your breach of any of the provisions of these Terms."
      />
      <PageIntro
        heading="Severability"
        p="If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein."
      />
      <PageIntro
        heading="Variation of Terms"
        p="Divine Travel Nest Safaris is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website."
      />
      <PageIntro
        heading="Assignment"
        p="Divine Travel Nest Safaris shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms."
      />
      <PageIntro
        heading="Entire Agreement"
        p="These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Divine Travel Nest Safaris and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same."
      />
      <PageIntro
        heading="Governing Law & Jurisdiction"
        p="These Terms will be governed by and construed in accordance with the laws of Kenya, and you submit to the non-exclusive jurisdiction of Kenya and courts located in Nairobi for the resolution of any disputes."
      />
      <div className="h-10"></div>
    </>
  );
}
