import MenuMobile from "./MenuMobile";
import MenuDesk from "./MenuDesk";
//import { useCycle } from "framer-motion";

export default function Navbar() {
  // const [open, setOpen] = useState(false);
  // const [isOpen, toggleOpen] = useCycle(false, true);

  //const buttonRef = useRef<HTMLButtonElement>(null);
  // const timeoutDuration = 200;
  // let timeout: string | number | NodeJS.Timeout | undefined;

  // const closePopover = () => {
  //   return buttonRef.current?.dispatchEvent(
  //     new KeyboardEvent("keydown", {
  //       key: "Escape",
  //       bubbles: true,
  //       cancelable: true,
  //     })
  //   );
  // };

  // const onMouseEnter = (open: boolean) => {
  //   clearTimeout(timeout);
  //   if (open) return;
  //   return buttonRef.current?.click();
  // };

  // const onMouseLeave = (open: boolean) => {
  //   if (!open) return;
  //   timeout = setTimeout(() => closePopover(), timeoutDuration);
  // };

  return (
    <>
      <div className="z-40 relative">
        <MenuDesk />
        <MenuMobile />
      </div>
    </>
  );
}
