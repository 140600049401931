import React from "react";
import { PageHero } from "../components/ui/PageHero";
import { PageIntro } from "../components/ui/PageIntro";
import Heading from "../components/ui/Heading";
import { WhyUs } from "../components/home/WhyUs";
import LazyImage from "../utils/LazyImage";
import { WhyUsPoints } from "../components/ui/WhyUsPoints";
import Head from "../components/seo/Head";
import { useLocation } from "react-router-dom";

export const About = () => {
  const location = useLocation();

  return (
    <>
      <Head
        title="About Us"
        addPostfixTitle={true}
        link={location.pathname}
        description="Join us for a journey beyond the ordinary, where luxury meets adventure, and every encounter with wildlife is a profound connection. Discover the beauty of the natural world with us, as we take you on safaris that enrich your soul and leave you with memories to treasure for a lifetime."
      />

      <PageHero
        image="https://res.cloudinary.com/ddwkntgdf/image/upload/v1707486798/okt7mzr2udf4k4ty6tkj.jpg?auto=format"
        mainText="We are Divine Travel Nest"
        subText="Dare to Explore Africa with us"
        className="object-top"
      />
      <PageIntro
        sign=" African. Safari. Experts."
        heading="YOUR VACATION IS DESTINED TO BE AS UNIQUE AS YOU ARE"
        p="We understand that every traveler is unique, which is why we offer customizable safari experiences tailored to your preferences. Whether you dream of witnessing the Great Migration in East Africa, embarking on a photographic safari to capture mesmerizing wildlife moments, or indulging in a luxurious retreat amidst the wilderness, our team will work closely with you to design a personalized itinerary that fulfills your desires and exceeds your expectations"
        className="px-8"
      />

      <section className=" flex md:flex-row flex-col-reverse items-center py-8 sm:py-16 ">
        <div className="w-full sm:w-1/2 flex flex-col justify-start items-center">
          <Heading
            text="Divine travel nest team"
            className="pb-3 sm:pt-0 pt-3"
          />
          <PageIntro p="Our team of highly skilled and knowledgeable guides are the backbone of our safari experiences. With their expert guidance, you’ll gain invaluable insights into the behaviors of Africa’s iconic animals, discover hidden gems off the beaten path, and witness unforgettable moments in the wild. Their passion for conservation and commitment to sustainable tourism ensure that every adventure with Divine Travel Nest Safaris is not only thrilling but also environmentally responsible." />
        </div>
        <div className="w-screen md:w-1/2 flex items-center justify-center">
          <LazyImage src="https://res.cloudinary.com/ddwkntgdf/image/upload/v1707631012/mflurmaldtx9khlfz99m.jpg" />
        </div>
      </section>
      <section className=" flex md:flex-row flex-col items-center py-8 sm:py-16">
        <div className="w-screen md:w-1/2 flex items-center justify-center">
          <LazyImage src="https://images.unsplash.com/photo-1678186039062-3f7d05a87a5b" />
        </div>
        <div className="w-full sm:w-1/2 flex flex-col justify-start items-center">
          <Heading text="Tailored Experiences" className="pb-3 sm:pt-0 pt-3 " />
          <PageIntro p="We understand that every traveler is unique, which is why we offer customizable safari experiences tailored to your preferences. Whether you dream of witnessing the Great Migration in East Africa, embarking on a photographic safari to capture mesmerizing wildlife moments, or indulging in a luxurious retreat amidst the wilderness, our team will work closely with you to design a personalized itinerary that fulfills your desires and exceeds your expectations." />
        </div>
      </section>
      <section className=" flex md:flex-row flex-col-reverse items-center py-8 sm:py-16">
        <div className="w-full sm:w-1/2 flex flex-col justify-start items-center">
          <Heading text="Comfort and Safety" className="pb-3 sm:pt-0 pt-3 " />
          <PageIntro p="At Divine Travel Nest Safaris, we prioritize your comfort and safety throughout your safari journey. We partner with carefully selected lodges, camps, and accommodations that provide a blend of luxury, authenticity, and a genuine connection with nature. Our fleet of well-maintained safari vehicles ensures a smooth and comfortable ride, while our commitment to safety measures and trained staff guarantees your well-being during the entire expedition." />
        </div>
        <div className="w-screen md:w-1/2 flex items-center justify-center">
          <LazyImage src="https://images.unsplash.com/photo-1667987566780-3b31fa5485c8" />
        </div>
      </section>
      <section className=" flex md:flex-row flex-col items-center py-8 sm:py-16">
        <div className="w-screen md:w-1/2 flex items-center justify-center">
          <LazyImage src="https://images.unsplash.com/photo-1535940360221-641a69c43bac" />
        </div>
        <div className="w-full sm:w-1/2 flex flex-col justify-start items-center">
          <Heading
            text="Conservation & Community"
            className="pb-3 sm:pt-0 pt-3 "
          />
          <PageIntro p="We believe in making a positive impact on the communities and environments we visit. By partnering with local conservation initiatives and supporting community development projects, Divine Travel Nest Safaris ensures that your adventure contributes to the preservation of Africa’s wildlife and empowers local communities. Engage in meaningful interactions with local cultures, visit conservation centers, and leave a lasting legacy for generations to come. Join Divine Travel Nest Safaris and let us ignite your sense of wonder as you embark on an unforgettable safari adventure, where the rhythm of the wilderness beckons and the spirit of Africa comes alive." />
        </div>
      </section>

      <div className=" bg-[#c2a77e] w-full flex justify-center items-center flex-col">
        <div className="m-10 flex flex-col md:flex-row shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]">
          <div className="w-full md:w-[35%] ">
            <WhyUs />
          </div>
          <WhyUsPoints />
        </div>
      </div>
    </>
  );
};
