import React from "react";
import { PageIntro } from "../components/ui/PageIntro";
import Heading from "../components/ui/Heading";
import { WhyUs } from "../components/home/WhyUs";
import LazyImage from "../utils/LazyImage";
import Head from "../components/seo/Head";
import { useLocation } from "react-router-dom";

export default function Team() {
  const location = useLocation();
  return (
    <>
      <Head title="Our team" link={location.pathname} addPostfixTitle={true} />
      <PageIntro
        heading="Meet the Team"
        sign="Safari Makers"
        p="We understand that every traveler is unique, which is why we offer customizable safari experiences tailored to your preferences. Whether you dream of witnessing the Great Migration in East Africa, embarking on a photographic safari to capture mesmerizing wildlife moments, or indulging in a luxurious retreat amidst the wilderness, our team will work closely with you to design a personalized itinerary that fulfills your desires and exceeds your expectations"
      />
      <div className="grid gap-12 items-center md:grid-cols-3 mx-auto px-6 md:px-12 xl:px-32">
        <div className="space-y-4 text-center">
          <LazyImage src="https://res.cloudinary.com/ddwkntgdf/image/upload/v1708534631/zly9t2gkj0vuwtdfqnxb.jpg" />

          <div>
            <h4 className="text-2xl font-AthelasBold">James Kahoro</h4>
            <span className="block text-xs font-Averia text-gray-500">
              Safari curator
            </span>
          </div>
        </div>
        <div className="space-y-4 text-center">
          <LazyImage src="https://res.cloudinary.com/ddwkntgdf/image/upload/v1708534646/xb1geqy5skjrpwi97nax.jpg" />

          <div>
            <h4 className="text-2xl font-AthelasBold">Janet Wanjiru</h4>
            <span className="block text-xs font-Averia text-gray-500">CEO</span>
          </div>
        </div>
        <div className="space-y-4 text-center">
          <LazyImage src="https://res.cloudinary.com/ddwkntgdf/image/upload/v1708535645/t3uy7vurdd7hxiqq8wpj.jpg" />

          <div>
            <h4 className="text-2xl font-AthelasBold">John Mwangi</h4>
            <span className="block text-xs font-Averia text-gray-500">
              CEO-Founder
            </span>
          </div>
        </div>
      </div>
      <div className="h-10"></div>
      <div className=" bg-[#c2a77e] w-full flex justify-center items-center flex-col">
        <div className="m-10 flex flex-col md:flex-row shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]">
          <div className="w-full md:w-[35%] ">
            <WhyUs />
          </div>
          <div className="w-full md:w-[65%] bg-white flex justify-center items-center">
            <div className="">
              <div className="my-2">
                <Heading
                  text="Why choose us"
                  className="mt-3 mb-5 sm:mt-5 sm:mb-10"
                />
              </div>
              <div className="p-10 w-full">
                <dl className="mb-8">
                  <dt className="font-Averia text-xs font-bold uppercase text-primary ">
                    24/7 on-safari support
                  </dt>
                  <dd className=" font-Athelas text-base mb-3">
                    +254 724-163-662
                  </dd>
                </dl>
                <dl className="my-8">
                  <dt className="font-Averia text-xs font-bold uppercase text-primary tracking-widest">
                    Variety of safari packages
                  </dt>
                  <dd className=" font-Athelas text-base mb-3">
                    we have Over 100+ safari packages in East Africa & Here to
                    help your Chose the best.
                  </dd>
                </dl>
                <dl className="my-8">
                  <dt className="font-Averia text-xs font-bold uppercase text-primary tracking-widest">
                    Tailor made safari
                  </dt>
                  <dd className=" font-Athelas text-base mb-3">
                    Curate your itinerary that fits your style, preferences &
                    budget.
                  </dd>
                </dl>
                <dl className="mt-8">
                  <dt className="font-Averia text-xs font-bold uppercase text-primary tracking-widest">
                    BEST SAFARI GUIDES AND DRIVERS
                  </dt>
                  <dd className=" font-Athelas text-base mb-3">
                    All our Safaris guides are all licensed by the Kenya
                    Professional Safari Guides Association.
                  </dd>
                </dl>
                <dl className="my-8">
                  <dt className="font-Averia text-xs font-bold uppercase text-primary tracking-widest">
                    FLEXIBILITY
                  </dt>
                  <dd className=" font-Athelas text-base mb-3">
                    Our Itineraries are flexible to suit you.
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
