import React, {
  Fragment,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import Button from "./Button";
import { EMAIL_REGEX } from "../../utils/interface";
import axios from "axios";
import * as iso3166 from "iso-3166-1-alpha-2";

interface ModalProps {
  title: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
}

const Modal = ({ title, open, setOpen }: ModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    mode: "onSubmit",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
  });

  const [countryName, setCountryName] = useState<string>("us");

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryName(data.country_name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCountryCode = (countryName: string): string => {
    try {
      const code = iso3166.getCode(countryName);
      return code ?? "us";
    } catch (error) {
      console.error(`Error converting ${countryName} to ISO code:`, error);
      return "us";
    }
  };

  const countryCode = getCountryCode(countryName).toLowerCase();

  const submit = async (data: FormData) => {
    try {
      // Initialize EmailJS
      emailjs.init("znTnwdmQ3r_88Dh66");

      // Create form data
      const form = document.createElement("form");
      Object.keys(data).forEach((key) => {
        const input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", key);
        input.setAttribute("value", data[key as keyof FormData]);
        form.appendChild(input);
      });

      await emailjs.sendForm("service_zkjp3r5", "template_5r3vulj", form);
      alert("Message sent successfully!");
      reset();
    } catch (error) {
      console.error("Failed to send message:", error);
      alert("Failed to send message, please try again later.");
    }
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 !z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative overflow-hidden bg-white text-center shadow-xl transition-all my-20 w-full md:w-3/4">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="flex flex-col items-center justify-center">
                    <div
                      className={`mx-auto flex shrink-0 items-center justify-center sm:mx-0 `}
                    >
                      <img
                        src="/assets/images/logo/Logo.png.webp"
                        alt=""
                        className="h-20 md:h-36 "
                      />
                    </div>
                    <div className="mt-6 sm:text-left">
                      <DialogTitle
                        as="h3"
                        className="text-center text-xl font-AthelasBold font-semibold leading-6"
                      >
                        {title}
                      </DialogTitle>
                      <p className="text-xs font-Athelas pt-5 text-gray-500">
                        By submitting this form you agree to allow this website
                        to store the information so that they can respond to
                        your inquiry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="my-3 px-4 py-3 flex justify-center sm:px-6 ">
                  <form
                    className="flex w-3/4 flex-col border border-gray-200 p-6"
                    onSubmit={handleSubmit((data) => submit(data))}
                  >
                    <div className="grid gap-4 lg:gap-6">
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                        <div>
                          <label
                            htmlFor="hs-firstname-contacts-1"
                            className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                          >
                            First Name
                          </label>

                          <input
                            {...register("firstName", {
                              required: "Please provide your first name",
                            })}
                            type="text"
                            name="firstName"
                            id="firstName"
                            className="py-3 px-4 block w-full bg-transparent placeholder-colorTextBase placeholder-opacity-75 border border-colorTextBase rounded-md text-sm"
                            placeholder="First Name"
                            autoComplete="on"
                          />
                          {errors.firstName && (
                            <p
                              className={`${
                                errors ? "animate-shake" : ""
                              } text-red-500 text-xs font-light py-2`}
                            >
                              {errors.firstName.message}
                            </p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="hs-lastname-contacts-1"
                            className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                          >
                            Last Name
                          </label>
                          <input
                            {...register("lastName", {
                              required: "Please provide your last name",
                            })}
                            type="text"
                            name="lastName"
                            id="lastName"
                            className="py-3 px-4 block w-full bg-transparent placeholder-colorTextBase placeholder-opacity-75 border border-colorTextBase rounded-md text-sm disabled:opacity-50 disabled:pointer-events-none"
                            placeholder="Last Name"
                            autoComplete="on"
                          />
                          {errors.lastName && (
                            <p
                              className={`${
                                errors ? "animate-shake" : ""
                              } text-red-500 text-xs font-light py-2`}
                            >
                              {errors.lastName.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                        <div>
                          <label
                            htmlFor="hs-email-contacts-1"
                            className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                          >
                            Email
                          </label>
                          <input
                            {...register("email", {
                              required: "Please provide an email",
                              pattern: {
                                value: EMAIL_REGEX,
                                message:
                                  "Please provide a properly formatted email address",
                              },
                            })}
                            type="email"
                            name="email"
                            id="email"
                            className="py-3 px-4 block w-full bg-transparent placeholder-colorTextBase placeholder-opacity-75 border border-colorTextBase rounded-md text-sm disabled:opacity-50 disabled:pointer-events-none"
                            placeholder="your@email.com"
                            autoComplete="on"
                          />
                          {errors.email && (
                            <p
                              className={`${
                                errors ? "animate-shake" : ""
                              } text-red-500 text-xs font-light py-2`}
                            >
                              {errors.email.message}
                            </p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="hs-phone-number-1"
                            className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                          >
                            Phone Number
                          </label>
                          <div className="flex flex-col items-center py-1">
                            <PhoneInput
                              {...register("phoneNumber", {
                                required: "Please provide a phone number",
                              })}
                              defaultCountry={countryCode}
                              onChange={(phone: string) => phone}
                            />
                            {errors.phoneNumber && (
                              <p
                                className={`${
                                  errors ? "animate-shake" : ""
                                } text-red-500 text-xs font-light py-2`}
                              >
                                {errors.phoneNumber.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="hs-about-contacts-1"
                          className="block mb-2 text-sm text-gray-700 font-medium dark:text-white"
                        >
                          Message
                        </label>
                        <textarea
                          {...register("message", {})}
                          id="message"
                          name="message"
                          rows={4}
                          className="py-3 px-4 block w-full bg-transparent placeholder-colorTextBase placeholder-opacity-75 border border-colorTextBase rounded-lg text-xs disabled:opacity-50 disabled:pointer-events-none"
                          placeholder="Please mention any other information that can help us plan a perfect Africa Holiday"
                        ></textarea>
                      </div>
                    </div>
                    <Button
                      text="Submit form"
                      className="bg-secondary text-white mt-6 justify-center"
                    />
                    <div className="mt-3 text-center">
                      <p className="text-xs text-gray-600">
                        Free of charge, no spam, no disturbing calls
                      </p>
                    </div>
                  </form>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
