import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PageHero } from "../../components/ui/PageHero";
import { PageIntro } from "../../components/ui/PageIntro";
import SafariCard from "../../components/ui/SafariCard";
import useSafaris from "../../hooks/useSafaris";
import { SafariPackage } from "../../utils/interface";
import Button from "../../components/ui/Button";
import { motion } from "framer-motion";
import PriceTable from "../../components/Pricetable";
import Loader from "../../components/ui/Loader";
import Head from "../../components/seo/Head";

const variants = {
  open: {
    y: 0,
    opacity: 1,
    height: "auto",
    transition: {
      y: { stiffness: 1000, velocity: -100 },
      height: { type: "tween" },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    height: 0,
    transition: {
      y: { stiffness: 1000 },
      height: { type: "tween" },
    },
  },
};

const SafariPackageDetails = () => {
  const { slug } = useParams<{ slug: string }>();

  const {
    fetchSafariPackage: { isLoading, error, data },
  } = useSafaris({ slug });

  const [safariData, setSafariData] = useState<SafariPackage | undefined>(
    undefined
  );

  const location = useLocation();

  const { allSafarisQuery } = useSafaris();
  const [similarSafaris, setSimilarSafaris] = useState<SafariPackage[]>([]);
  const [isOpen, setIsOpen] = useState(true);

  const showFull = () => {
    setIsOpen(true);
  };
  const showShort = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (data && allSafarisQuery.data) {
      setSafariData(data);
      const category = data.safari_package_category;
      const similar = allSafarisQuery.data.filter(
        (safari) =>
          safari.id !== data.id &&
          safari.safari_package_category.some((cat) => category.includes(cat))
      );
      // Shuffle the similar safaris array
      const shuffledSimilarSafaris = similar.sort(() => 0.5 - Math.random());
      // Get three random items from the shuffled array
      const randomThreeSimilarSafaris = shuffledSimilarSafaris.slice(0, 3);
      setSimilarSafaris(randomThreeSimilarSafaris);
    }
  }, [data, allSafarisQuery.data]);

  return (
    <>
      {isLoading && (
        <>
          <div className="h-screen w-screen flex justify-center items-center">
            <Loader />
          </div>
        </>
      )}
      {error && <h1 className="text-9xl">error...</h1>}

      <div>
        {safariData && (
          <>
            <Head
              title={safariData.title}
              link={location.pathname}
              description={safariData.safari_package_description}
              imageCard={safariData.image_url}
              addPostfixTitle={true}
            />

            <PageHero
              image={safariData.image_url}
              mainText={safariData.title}
            />
            <PageIntro
              heading={safariData.title}
              p={safariData.safari_package_description}
            />
            <div className="w-full mt-5 flex flex-col items-center px-3 sm:px-0">
              <div className="w-full md:w-3/4">
                <div className="py-4 w-full flex flex-col justify-center items-center">
                  <h1 className="md:text-2xl text-sm font-bold">Itinerary</h1>
                  <div className="flex gap-4">
                    <Button
                      type="button"
                      text="Show full itinerary"
                      onClick={() => showFull()}
                    ></Button>
                    <Button
                      type="button"
                      text="Show short itinerary"
                      onClick={() => showShort()}
                    ></Button>
                  </div>
                </div>
                <ul className="">
                  {safariData.itinerary.itinerary_details.map((day) => (
                    <div key={day.day_title}>
                      <div className="mb-4 w-full py-2 px-3 inline-flex items-center gap-x-2 font-medium  bg-mainColor/95 border border-colorTextSecond text-white p-3 tracking-wider capitalize rounded-md hover:bg-mainColor/100">
                        <h1 className="md:text-sm text-xs">{day.day_title}</h1>
                      </div>
                      <motion.li
                        animate={isOpen ? "open" : "closed"}
                        variants={variants}
                      >
                        <div>
                          <>
                            <div className="pb-4">
                              <p className="text-base">{day.day_description}</p>
                            </div>
                            <div className="pb-4 flex flex-col gap-2">
                              {day.destination && (
                                <motion.div
                                  animate={isOpen ? "open" : "closed"}
                                  variants={variants}
                                  className="flex"
                                >
                                  <div className="font-bold pr-1 capitalize">
                                    Destination :
                                  </div>
                                  <div className=""></div> {day.destination}
                                </motion.div>
                              )}
                              {day.travel_time && (
                                <motion.div
                                  animate={isOpen ? "open" : "closed"}
                                  variants={variants}
                                  className="flex"
                                >
                                  <div className="font-bold pr-1 capitalize">
                                    travel time :
                                  </div>
                                  <div className=""></div>
                                  {day.travel_time}
                                </motion.div>
                              )}
                              {day.activities && (
                                <motion.div
                                  animate={isOpen ? "open" : "closed"}
                                  variants={variants}
                                  className="flex"
                                >
                                  <div className="font-bold pr-1 capitalize">
                                    Activities :
                                  </div>
                                  <div className=""></div>
                                  {day.activities}
                                </motion.div>
                              )}
                              {day.optional_activities && (
                                <motion.div
                                  animate={isOpen ? "open" : "closed"}
                                  variants={variants}
                                  className="flex"
                                >
                                  <div className="font-bold pr-1 capitalize">
                                    Optional Activities :
                                  </div>
                                  <div className=""></div>
                                  {day.optional_activities}
                                </motion.div>
                              )}
                              {day.accommodation && (
                                <motion.div
                                  animate={isOpen ? "open" : "closed"}
                                  variants={variants}
                                  className="flex"
                                >
                                  <div className="font-bold pr-1 capitalize">
                                    accommodation :
                                  </div>
                                  <div className=""></div>
                                  {day.accommodation}
                                </motion.div>
                              )}
                              {day.meals && (
                                <motion.div
                                  animate={isOpen ? "open" : "closed"}
                                  variants={variants}
                                  className="flex"
                                >
                                  <div className="font-bold pr-1 capitalize">
                                    meals :
                                  </div>
                                  <div className=""></div>
                                  {day.meals}
                                </motion.div>
                              )}
                              {day.complementary && (
                                <motion.div
                                  animate={isOpen ? "open" : "closed"}
                                  variants={variants}
                                  className="flex"
                                >
                                  <div className="font-bold pr-1 capitalize">
                                    complementary :
                                  </div>
                                  <div className=""></div>
                                  {day.complementary}
                                </motion.div>
                              )}
                            </div>
                          </>
                        </div>
                      </motion.li>
                    </div>
                  ))}
                </ul>
              </div>
              <div className=" w-full md:w-3/4">
                {safariData && (
                  <>
                    <PriceTable pricelist={safariData.prices_midrange} />
                  </>
                )}
              </div>

              <div className="py-4 w-full md:w-3/4 px-3 sm:px-0">
                <h1 className="capitalize md:text-2xl text-sm font-bold">
                  Whats included
                </h1>
                <ul className="p-1 list-disc leading-relaxed">
                  <li className="">Transfer to and from the airport</li>
                  <li className="">Full board hotel accommodation</li>
                  <li className="">Park fees</li>
                  <li className="">Professional tour guide</li>
                  <li className="">
                    Enough mineral bottled water while on safari
                  </li>
                  <li className="">All activities unless labeled optional</li>
                  <li className="">All taxes/ VAT</li>
                </ul>
              </div>
              <div className="py-4 w-full md:w-3/4 px-3 sm:px-0">
                <h1 className="capitalize md:text-2xl text-sm font-bold ">
                  Whats excluded
                </h1>
                <ul className="p-1 list-disc leading-relaxed">
                  <li className="">International flights</li>
                  <li className="">
                    Additional accommodation before and at the end of the tour
                  </li>
                  <li className="">Medical insurance</li>
                  <li className="">
                    Government imposed increase of taxes and/or park fees
                  </li>
                </ul>
              </div>
            </div>
            <div className="px-0 sm:px-4 ">
              <h1 className="tracking-widest capitalize md:text-2xl text-base font-bold bg-secondary p-2 text-center">
                You may also like
              </h1>
              <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 w-full px-2 my-5 ">
                {similarSafaris.map((safari) => {
                  return (
                    <li
                      key={safari.id}
                      className="flex flex-col justify-between"
                    >
                      <SafariCard
                        featured={safari.featured}
                        id={safari.id}
                        image={safari.image_url}
                        description={safari.safari_package_description}
                        title={safari.title}
                        price={safari.prices_midrange}
                        days={safari.details?.days}
                        priceFrom={safari.details.price_from}
                        category={safari.safari_package_category}
                        highlights={safari.safari_package_highlights}
                        slug={safari.slug}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SafariPackageDetails;
