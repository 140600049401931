import { currencyFormat } from "simple-currency-format";
import Button from "./ui/Button";
import React from "react";

interface Price {
  prices_midrange?: {
    [month: string]: {
      two_three_people: number;
      four_five_people: number;
      six_or_more_people: number;
      one_person: number;
    };
  };
}

interface PriceTableProps {
  pricelist: Price["prices_midrange"];
}

const getPriceTableDate = (month: string) => {
  switch (month) {
    case "jun":
      return "Jun 16 – 30";
    case "jul":
      return "Jul 1 – Sep 30";
    case "oct_dec":
      return "Oct 1 – Dec 21";
    case "apr_jun":
      return "Apr 1 – Jun 15";
    case "dec_jan":
      return "Dec 22 – Jan 3";
    case "jan_mar":
      return "Jan 3 – Mar 31";
    default:
      return month;
  }
};

const getMonthRangeStartDate = (monthRange: string) => {
  switch (monthRange) {
    case "jan_mar":
      return new Date(new Date().getFullYear(), 0, 3); // January 3rd
    case "apr_jun":
      return new Date(new Date().getFullYear(), 3, 1); // April 1st
    case "jun":
      return new Date(new Date().getFullYear(), 5, 16); // June 16th
    case "jul":
      return new Date(new Date().getFullYear(), 6, 1); // July 1st
    case "oct_dec":
      return new Date(new Date().getFullYear(), 9, 1); // October 1st
    case "dec_jan":
      return new Date(new Date().getFullYear(), 11, 22); // December 22nd
    default:
      return new Date();
  }
};

const PriceTable: React.FC<PriceTableProps> = ({ pricelist }) => {
  const currentYear = new Date().getFullYear();
  const sortedMonthRanges = Object.keys(pricelist || {}).sort(
    (a, b) =>
      getMonthRangeStartDate(a).getTime() - getMonthRangeStartDate(b).getTime()
  );

  return (
    <>
      <h1 className="capitalize md:text-2xl text-sm font-bold">
        Prices & Seasons {currentYear}
      </h1>
      <section className="py-1 bg-blueGray-50">
        <div className="relative flex flex-col min-w-0 break-words bg-colorCard w-full mb-6 shadow-lg rounded ">
          <div className="flex flex-wrap items-center bg-colorTextBase p-3 rounded-t-md">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="text-sm font-semibold tracking-wider text-white">
                Best Price Available{" "}
                <span className="text-xs font-normal text-nowrap">
                  (Contact us for a more tailored quotation)
                </span>
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <Button text="Book now" />
            </div>
          </div>

          <div className="block w-full overflow-x-auto">
            <table className="items-center bg-transparent w-full border-collapse ">
              <thead>
                <tr>
                  <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    Date
                  </th>
                  <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    2 People
                  </th>
                  <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    3 People
                  </th>
                  <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    4 People
                  </th>
                  <th className="px-2 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                    5 People
                  </th>
                </tr>
              </thead>

              <tbody>
                {sortedMonthRanges.map((month) => (
                  <tr key={month} className="border-b border-blueGray-200">
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left text-blueGray-700">
                      {getPriceTableDate(month)}
                    </td>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {pricelist &&
                        currencyFormat(
                          pricelist[month].one_person,
                          "en-US",
                          "USD"
                        )}{" "}
                      pp
                    </td>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {pricelist &&
                        currencyFormat(
                          pricelist[month].two_three_people,
                          "en-US",
                          "USD"
                        )}{" "}
                      pp
                    </td>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {pricelist &&
                        currencyFormat(
                          pricelist[month].four_five_people,
                          "en-US",
                          "USD"
                        )}{" "}
                      pp
                    </td>
                    <td className="border-t-0 px-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {pricelist &&
                        currencyFormat(
                          pricelist[month].six_or_more_people,
                          "en-US",
                          "USD"
                        )}{" "}
                      pp
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
};

export default PriceTable;
