import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { getAllBlogs, getBlog } from "../api/firebase";
import { BlogPost } from "../utils/interface";

interface FilterProps {
  key?: string;
  value?: string;
  slug: string;
}

export default function useBlogBost(filter: Partial<FilterProps> = {}) {
  const { slug } = filter;

  //Get All Safari Packages
  const allBlogPost: UseQueryResult<BlogPost[], Error> = useQuery({
    queryKey: ["blogs"],
    queryFn: async () => await getAllBlogs(),
    // @ts-ignore
    config: {
      staleTime: 1000 * 60,
      keepPreviousData: true,
    },
  });

  // Get Safari Package by slug
  const fetchBlogPost: UseQueryResult<BlogPost | null, Error> = useQuery({
    queryKey: ["blogs", filter],
    queryFn: async () => await getBlog({ slug: slug as string }),
  });

  return {
    allBlogPost,
    fetchBlogPost,
  };
}
