import React from "react";
import { PageHero } from "../ui/PageHero";
import { PageIntro } from "../ui/PageIntro";
import LazyImage from "../../utils/LazyImage";
import HeroCarousel from "../embla/HeroCarousel";
import { EmblaOptionsType } from "embla-carousel";

interface PageContentData {
  heading: string;
  subTitle?: string;
  headingTwo: string;
  mainText: string;
  p?: string;
  sign?: string;
  className?: string;
  mainImg: string;
  secImg: string;
  secImgTwo: string;
  firstParagraph: string;
  secondParagraph: string;
  slides?: string[];
  slidesWithText?: {
    titleHeading: string;
    carouselHeading: string;
    image: string;
  }[];
  titleHeading?: string;
  carouselHeading?: string;
  textslide: boolean;
}

export default function PageTemplate({
  heading,
  subTitle,
  headingTwo,
  firstParagraph,
  secondParagraph,
  sign,
  mainImg,
  secImg,
  secImgTwo,
  carouselHeading,
  slides,
  slidesWithText,
  titleHeading,
  textslide,
  mainText,
}: PageContentData) {
  const OPTIONS: EmblaOptionsType = {
    containScroll: "trimSnaps",
    loop: true,
    dragFree: true,
  };
  return (
    <>
      <PageHero
        image={mainImg}
        mainText={mainText}
        subText={subTitle ? subTitle : "Dare to Explore Africa with us"}
        className="object-center"
      />
      <section className=" flex md:flex-row flex-col items-center pt-8 sm:pt-20 ">
        <div className="w-full sm:w-1/2 flex flex-col justify-start items-center">
          <PageIntro p={firstParagraph} sign={sign} heading={heading} />
        </div>
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <LazyImage src={secImg} />
        </div>
      </section>
      <section className=" flex md:flex-row flex-col-reverse items-center pt-8 sm:pt-20">
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <LazyImage src={secImgTwo} />
        </div>
        <div className="w-full sm:w-1/2 flex flex-col justify-start items-center">
          <PageIntro p={secondParagraph} heading={headingTwo} />
        </div>
      </section>
      <section className="py-10">
        <HeroCarousel
          options={OPTIONS}
          titleHeading={titleHeading}
          carouselHeading={carouselHeading}
          textslide={textslide}
          slides={slides}
          slidesWithText={slidesWithText}
        />
      </section>
    </>
  );
}
