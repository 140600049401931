import React from "react";
import ReactDOM from "react-dom/client";

import "./styles/index.scss";
import "normalize.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import { About } from "./pages/About";
import Tours from "./pages/Tours";
import SafariPackageDetails from "./pages/safaris/SafariPackageDetails";

import SafariDestination from "./pages/safaris/SafariDestination";
import ScrollToTop from "./components/ScrollToTop";
import SafariTerms from "./pages/SafariTerms";
import Terms from "./pages/Terms";
import Team from "./pages/Team";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Contact from "./pages/Contact";
import Destinations from "./pages/Destinations";
import SafariParks from "./pages/SafariParks";
import SafariTypes from "./pages/SafariTypes";
import SafariAccomodation from "./pages/SafariAccomodation";
import DetailSafariAccomodation from "./pages/DetailSafariAccomodation";
import CurateSafarisContactForm from "./pages/CurateSafarisContactForm";
import Responsibility from "./pages/Responsibility";
import Blogs from "./pages/Blogs";
import { Error } from "./pages/Error";
import BlogPostDetails from "./pages/BlogPostDetails";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <ScrollToTop />
        <App />
      </>
    ),
    children: [
      {
        index: true,
        path: "/",
        element: <Home />,
      },
      {
        index: true,
        path: "/about",
        element: <About />,
      },

      {
        index: true,
        path: "/safari-booking-terms",
        element: <SafariTerms />,
      },
      {
        index: true,
        path: "/terms-of-service",
        element: <Terms />,
      },
      {
        index: true,
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        index: true,
        path: "/our-team",
        element: <Team />,
      },

      {
        index: true,
        path: "/safaris",
        element: <Tours />,
      },
      {
        index: true,
        path: "/contacts",
        element: <Contact />,
      },
      {
        path: "/safari-package/:slug",
        element: <SafariPackageDetails />,
      },
      {
        path: "/destinations",
        element: <Destinations />,
      },
      {
        path: "/safari-parks/:matchString",
        element: <SafariParks />,
      },
      {
        path: "/safari-accomodations/:matchString",
        element: <DetailSafariAccomodation />,
      },

      {
        path: "/destinations/:destination",
        element: <SafariDestination />,
      },
      {
        index: true,
        path: "/safari-packages",
        element: <Tours />,
      },
      {
        index: true,
        path: "/safari-types",
        element: <SafariTypes />,
      },
      {
        index: true,
        path: "/safari-accomodations",
        element: <SafariAccomodation />,
      },
      {
        index: true,
        path: "/curate-safaris",
        element: <CurateSafarisContactForm />,
      },
      {
        index: true,
        path: "/responsibility",
        element: <Responsibility />,
      },
      {
        index: true,
        path: "/blogs",
        element: <Blogs />,
      },
      {
        index: true,
        path: "/blog/:slug",
        element: <BlogPostDetails />,
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
