import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Background } from "react-imgix";

const transition = {
  type: "spring",
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
};

export const MenuItem = ({
  setActive,
  active,
  item,
  link,
  children,
}: {
  setActive: (item: string) => void;
  active: string | null;
  item: string;
  link: string;
  children?: React.ReactNode;
}) => {
  return (
    <div onMouseEnter={() => setActive(item)} className="">
      <motion.a
        href={link}
        transition={{ duration: 0.3 }}
        className="cursor-default hover:opacity-[0.9] hover:text-colorTextSecond capitalize font-bold text-lg"
      >
        {item}
      </motion.a>
      {active !== null && (
        <div>
          {active === item && (
            <div className="absolute top-[calc(100%_+_1.2rem)] left-1/2 transform -translate-x-1/2 pt-4">
              <motion.div
                transition={transition}
                layoutId="active" // layoutId ensures smooth animation
                className="bg-colorCard  backdrop-blur-sm overflow-hidden border border-b border-colorBorder shadow-xl w-[90vw]"
              >
                <motion.div
                  initial={{
                    x: -16,
                    opacity: 0,
                  }}
                  animate={{
                    x: 0,
                    opacity: 1,
                    transition: { transition },
                  }}
                  layout // layout ensures smooth animation
                  className="w-full h-full"
                >
                  {children}
                </motion.div>
              </motion.div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const Menu = ({
  setActive,
  children,
}: {
  setActive: (item: string | null) => void;
  children: React.ReactNode;
}) => {
  return (
    <nav
      onMouseLeave={() => setActive(null)} // resets the state
      onClick={() => setActive(null)}
      className="relative w-full flex items-center justify-between p-2 "
    >
      {children}
    </nav>
  );
};

export const ProductItem = ({
  text,
  href,
  img,
}: {
  text: string;
  href: string;
  img: string;
}) => {
  return (
    <Link to={href} className="flex flex-col">
      <Background src={img} className="flex-shrink-0 rounded-sm shadow-sm">
        <div className="h-40"></div>
      </Background>

      <div className="py-2">
        <h4 className="text-base font-medium">{text}</h4>
        {/* <p className="text-neutral-700 text-sm max-w-[10rem] dark:text-neutral-300">
          {description}
        </p> */}
      </div>
    </Link>
  );
};
