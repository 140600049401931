import React from "react";
import PageTemplate from "../components/templates/PageTemplate";
import { useLocation, useParams } from "react-router-dom";
import Head from "../components/seo/Head";

type AccomodationData = {
  mainText: string;
  heading: string;
  headingTwo: string;
  firstParagraph: string;
  secondParagraph: string;
  sign: string;
  mainImg: string;
  secImgTwo: string;
  secImg: string;
  textslide: boolean;
  slidesWithText: {
    titleHeading: string;
    carouselHeading: string;
    image: string;
  }[];
};

const accomodations: { [key: string]: AccomodationData } = {
  hodari: {
    mainText: "Hodari Africa Collection",
    heading: "Enjoy the taste of Africa",
    headingTwo: "A place of happiness and luxury ",
    firstParagraph:
      "Hodari Africa offers a collection of luxury safari lodges located in the heart of Kenya’s most spectacular destinations, from the rugged beauty of Laikipia and Samburu to the iconic Masai Mara.",
    secondParagraph:
      "The properties include Lewaso Eco Cottages in Laikipia, Ahadi Mara Camp and Mara Enkaji in Masai Mara, Samburu Elephant Lodge in Samburu, Malindi Retreat in Malindi and Hotel Rudi in Karen",
    sign: "Hodari Africa Collection",
    mainImg:
      "https://hodariafricacollection.com/wp-content/uploads/2024/05/IMG_3295-1024x768.jpg",
    secImgTwo:
      "https://hodariafricacollection.com/wp-content/uploads/2023/10/EXTERIOR-ROOM-1024x683.webp",
    secImg:
      "https://hodariafricacollection.com/wp-content/uploads/2023/11/DJI_0057-1024x576.webp",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "Hotel Rudi",
        carouselHeading:
          "Escape to Hotel Rudi where the rustle of leaves & a perfume of blossoms create a symphony.",
        image:
          "https://hodariafricacollection.com/wp-content/uploads/2024/05/01-Hotel-Rudi.jpg",
      },
      {
        titleHeading: "Malindi Retreat",
        carouselHeading:
          "Indulge in a symphony of blooms, sun, and laughter at Malindi Retreat,	",
        image:
          "https://hodariafricacollection.com/wp-content/uploads/2024/05/20240414_065916216_iOS-scaled-1-1024x768.jpg",
      },
      {
        titleHeading: "Mwanzo lodge",
        carouselHeading:
          "Welcome to our enchanting Lodge nestled amidst lush greenery and vibrant blooms in Naivasha",
        image:
          "https://hodariafricacollection.com/wp-content/uploads/2024/06/SC_6022-1024x681.jpg",
      },
    ],
  },
  kibo: {
    mainText: "Welcome to Kibo Villa, Amboseli",
    heading: "Enjoy the taste of Africa",
    headingTwo: "A place of happiness and luxury ",
    firstParagraph:
      "Kibo Villa is secluded in five acres of gardens, lawns and a little forest of indigenous tortilis acacia trees. Its tranquil atmosphere combines luxuriant comfort with the ethereal bush appeal of its rugged surroundings. Built in natural stone and elegant gum pole structures, Kibo Villa has three large master ensuite bedrooms, a lounge, a fully facilitated kitchen, a barbeque area, and a dining area that stretches onto a large veranda.",
    secondParagraph:
      "The master bedroom occupies the top floor, which opens onto a terrace where guest(s) can relax and admire the views of Mt. Kilimanjaro as well as the wildlife grazing on the plains directly ahead. The villa is accessed through its own private entrance and has a carport.",
    sign: "Kibo Villa Amboseli",
    mainImg:
      "https://www.kibovilla.com/wp-content/uploads/2024/04/01-kibo-villa-b.jpg",
    secImgTwo:
      "https://www.kibovilla.com/wp-content/uploads/2024/05/05-kibo-villa-b.jpg",
    secImg:
      "https://www.kibovilla.com/wp-content/uploads/2024/05/06-kibo-villa-b.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://www.kibovilla.com/wp-content/uploads/2024/05/02-kibo-villa-b.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://www.kibovilla.com/wp-content/uploads/2024/05/03-kibo-villa-b.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://www.kibovilla.com/wp-content/uploads/2024/05/06-kibo-villa-b.jpg",
      },
    ],
  },
  sawela: {
    mainText: "Sawela Lodges",
    heading: "Tranquility and Luxury",
    headingTwo: "Always in Tune",
    firstParagraph:
      "Sawela Lodge offers spectacular views of nearby Mt. Longonot and world-famous Lake Naivasha. The peaceful and natural setting will have you rested and relaxed in no time, just as it has for many our local and international guests who consider the property a luxurious home away from home.",
    secondParagraph:
      "Sawela Lodge is 90 minutes’ drive from Nairobi, Kenya (85 Kilometres). The hotel offers guests a variety of activities, from an organic Swimming pool for both adults, large lawns for football and other contact sports, Volleyball and Netball pitches, A kids play area, Bicycles, Boating, Lawn Racket Ball, nature walk and lastly the SAWELA TRAIL, which gives you the opportunity to blow off steam during your stay",
    sign: "Sawela Lodges",
    mainImg: "https://sawelalodges.com/images/slider/slider1.jpg",
    secImgTwo: "https://sawelalodges.com/images/slider/slider3.jpg",
    secImg: "https://sawelalodges.com/images/slider/slider2.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider4.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider5.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider6.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider7.jpg",
      },
    ],
  },
  simba: {
    mainText: "Simba Lodges",
    heading: "Masai mara Simba lodge",
    headingTwo: "Lake Naivasha Simba lodge",
    firstParagraph:
      "Whether you want a boost of rejuvenation or simply escape for the weekend to unwind, check into Mara Simba lodge to achieve your wellness goals. Indulge in exceptional culinary experiences and entertain yourself at the bar, revive yourself as you surrender to the pleasures of life at this truly alluring destination. The luxurious accommodation afford supreme privacy and luxury with 3 types of accommodations to choose from that make the most of the bush environs.Incredible and personalised game drives bring you extremely close to the fascinating Big Five. Enjoy the watch of the great migration across the Mara River between July and September every year.",
    secondParagraph:
      "Lake Naivasha Simba lodge is an enchanting setting where contemporary comfort blends stylishly with nature to offer an environment where guests can refresh their senses, rekindle their love of travel and reconnect with the world around them. Each room is blissfully private; you can sit on your balcony and watch our resident wildlife, take a nap and wake up to stunning bush views. Cocoon yourself in this exclusive hideaway and let your heart start to beat to the rhythm of africa.",
    sign: "Sawela Lodges",
    mainImg:
      "https://res.cloudinary.com/ddwkntgdf/image/upload/v1720121879/wurapyeswxdt6ssbcubt.jpg",
    secImgTwo:
      "https://res.cloudinary.com/ddwkntgdf/image/upload/v1720121887/xp99ksq44fhdwljyezku.jpg",
    secImg:
      "https://res.cloudinary.com/ddwkntgdf/image/upload/v1720121884/dkfv8yjhx0ijkuiv7leg.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "http://www.simbalodges.com/assets/images/hotels/NaivashaSimba_DSC_0314.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://res.cloudinary.com/ddwkntgdf/image/upload/v1720121884/dkfv8yjhx0ijkuiv7leg.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "http://www.simbalodges.com/assets/images/wedding/naivasha/DSC_0315_Wedding.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "http://www.simbalodges.com/assets/images/accommodation/NaivashaSimba_3W1A5839.jpg",
      },
    ],
  },
  kibocamp: {
    mainText: "Kibo Safari Camp",
    heading: "Best spot for viewing Mt.Kilimanjaro",
    headingTwo: "Accomodation",
    firstParagraph:
      "Kibo Private Wing is located less than 2km from the KWS Kimana Gate. It is exclusive and eco-friendly and offering breathtaking views of Mt Kilimanjaro. Amboseli, meaning “salty dust” in maa, the language of the Maasai, is a place of stark contrast. The park provides the classic Hollywood image of Africa. Set in rolling savannah scenery the park is dwarfed by the presence of the pink-tinged, snow-capped peak of Mount Kilimanjaro.",
    secondParagraph:
      "Kibo Safari Camp has 73 self-contained tents for single, double, triple and family accommodation. The beds have been custom designed and locally made using bush logs and will ensure a good night’s sleep! All tents have flush toilets, showers, and wash basins. You can enjoy your shower as you like: bubbling hot or refreshing cold. For the duration of your stay in the African bush, Kibo Safari Camp will be your home away from home!",
    sign: "Kibo Safari Camp",
    mainImg:
      "https://kibosafaricamp.com/wp-content/uploads/2020/12/10-KPW-DINING.jpg",
    secImgTwo:
      "https://kibosafaricamp.com/wp-content/uploads/2019/08/KiboSafariCamp_950_Poolside.jpg",
    secImg:
      "https://kibosafaricamp.com/wp-content/uploads/2020/12/22-KPW-RECEPTION.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://kibosafaricamp.com/wp-content/uploads/2020/12/9-KPW-BONFIRE-2.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://kibosafaricamp.com/wp-content/uploads/2020/12/3-KPW-DOUBLE-2.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://kibosafaricamp.com/wp-content/uploads/2020/12/3-KPW-DOUBLE-5.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://kibosafaricamp.com/wp-content/uploads/2020/12/KSC_POOL-DJI_0031-.jpg",
      },
    ],
  },
  osotua: {
    mainText: "Osotua Luxury Resort",
    heading: "Osotua Luxury Resort by ICON",
    headingTwo: "Restaurant & Bar",
    firstParagraph:
      "Managed and operated by Icon Hotels Group Africa (IHGA), Osotua Luxury Resort offers tastefully furnished executive studio apartments and villas to ensure comfort and relaxation for your short and long term stays in Naivasha. The hotel features a restaurant, free private parking, an outdoor pool, a garden, a patio, bar and restaurant. Our location in Naivasha puts us within driving distance to Crater Lake Game Sanctuary, Lake Naivasha National Park and Crescent Island Game Park. They offer room service and a 24-hour front desk to guests. Each hotel room includes a flat-screen smart TV, a private bathroom, a balcony with a view of the lake and the out door pool.",
    secondParagraph:
      "The restaurant at Osotua Luxury Resort offers a choice of international cuisines in addition to the greatest local dishes. The delicious meals served at the restaurant will satisfy your palate as they are creations of our outstanding chefs. Our outdoor bar is just next to the pool, where guests may enjoy the perfect cocktail as well as other drinks.",
    sign: "Osotua Luxury Resort",
    mainImg:
      "https://osotua-iconresort.com/wp-content/uploads/2022/09/osotua-resort-by-icon-naivasha-scaled.jpg",
    secImgTwo:
      "https://osotua-iconresort.com/wp-content/uploads/2022/09/banner3_Osotua-Resort-By-Icon-Hotels-Group-Africa.jpg",
    secImg:
      "https://osotua-iconresort.com/wp-content/uploads/2022/09/banner2_Osotua-Resort-By-Icon-Hotels-Group-Africa.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://osotua-iconresort.com/wp-content/uploads/2022/09/Osotua-luxury-resort-by-ICON-spare-room-twin-bedroom.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://osotua-iconresort.com/wp-content/uploads/2022/09/ostotua-resort-restaurant-naivasha-5-1.jpg",
      },

      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://osotua-iconresort.com/wp-content/uploads/2022/09/osotua-luxury-resort-about-us-banner.jpg",
      },
    ],
  },
  sopa: {
    mainText: "Sopa lodges Resort",
    heading: "The Sopa Lodges",
    headingTwo: "Tarangire Sopa Lodge",
    firstParagraph:
      "The location of every facility for the lodges has been carefully chosen to promise the best views over the wilderness while also offering an unrivalled ambience amongst cool and elegant surroundings. The magnificent standards and unrivalled space of guest accommodation are equally matched by the attention bestowed on each and every one of the patrons by the members of their staff who are carefully selected for their dedication to personal service and a genuine, heartfelt friendliness.  This ensures that all guests enjoy an extraordinary and unparalleled level of true African hospitality.",
    secondParagraph:
      "Built to blend in with the vastness of its surroundings, Tarangire Sopa Lodge lies hidden among the kopjes, ancient baobab and grasses of the Tarangire National Park - home to the greatest concentration of elephants in Africa. Many can be seen around the lodge, allowing visitors a close encounter.",
    sign: "Sopa lodges Resort",
    mainImg: "https://www.sopalodges.com/images/slider/lake-nakuru.jpg",
    secImgTwo: "https://www.sopalodges.com/images/the-lodges/lake-naivasha.jpg",
    secImg: "https://www.sopalodges.com/images/tarangire/overview.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://www.sopalodges.com/images/com_osgallery/gal-15/original/room-interior7F075CB1-778A-914C-41E4-5D2D075A305A.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://www.sopalodges.com/images/com_osgallery/gal-14/original/bar138D4314-10C1-9ADF-92F4-AE63EC25A260.jpg",
      },

      {
        titleHeading: "",
        carouselHeading: "",
        image:
          "https://www.sopalodges.com/images/com_osgallery/gal-14/original/gardens-and-swimming-pool2D73ADBC-3182-BB13-7DAF-615E92DE95D3.jpg",
      },
    ],
  },
  punda: {
    mainText: "Punda Milias",
    heading: "The lodges",
    headingTwo: "The Restaurant",
    firstParagraph:
      "The lodge grounds are a tranquil, leafy-green oasis where you’ll find the perfect city break from Nairobi, or have the perfect base to explore the natural wonders of Nakuru National Park and Soysambu Conservancy. They offer a range of accommodation options depending on your budget. From a luxury villa, to beautifully designed cottages, as well as comfortable and cosy bandas and safari tents. For the true travel nomad, there are even extensive camping grounds for you to pitch your tent in.",
    secondParagraph:
      "The lodges have a great restaurant and beautifully decorated common area available to the guests and walk-ins. The Chefs prepare a wide range of delicious continental dishes using produce grown straight from our own shamba. They will accommodate room service or bring your food to one of the hanging beds in our garden. ",
    sign: "Punda Milias",
    mainImg: "https://sawelalodges.com/images/slider/slider1.jpg",
    secImgTwo: "https://sawelalodges.com/images/slider/slider3.jpg",
    secImg: "https://sawelalodges.com/images/slider/slider2.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider4.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider5.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider6.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider7.jpg",
      },
    ],
  },
  ziwa: {
    mainText: "Ziwa bush lodge",
    heading: "Ziwa philosophy",
    headingTwo: "Escape To Nature",
    firstParagraph:
      "Ziwa Bush Lodge offers a personalized boutique experience to its guests with just six double units, three twin units and one family unit all containing spacious en-suites. Each individual room is made from local stone, canvas and makuti roofing (folded palm leaves) with its beautifully and uniquely handcrafted wooden furniture made on site by Kenyans living in and around Nakuru town.",
    secondParagraph:
      "For the love of nature, Ziwa bush Lodge relies on its natural habitat and surroundings and when guests needs relaxation, meditation, healing and rejuvenation, Ziwa offers the perfect elements of nature combined with comfort and exclusive services to our guests giving them the right environment to connect deeper with oneself. While maintaining luxury and sophistication, Ziwa holds true to its core value of preserving and upholding  nature",
    sign: "Ziwa bush lodge",
    mainImg: "https://sawelalodges.com/images/slider/slider1.jpg",
    secImgTwo: "https://sawelalodges.com/images/slider/slider3.jpg",
    secImg: "https://sawelalodges.com/images/slider/slider2.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider4.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider5.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider6.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider7.jpg",
      },
    ],
  },
  ashnil: {
    mainText: "Ashnil hotels limited",
    heading: "Ziwa philosophy",
    headingTwo: "Ashnil Mara Camp",
    firstParagraph:
      "Ashnil Limited Camps are four beautiful Camps and Lodges located in some of the best game viewing locations in Kenya’s world-famous National Parks and Reserves. Each of the properties has its unique feel, unparalleled levels of comfort, and service and most importantly offer amazing opportunities to view the local wildlife, culture and landscapes. We at Ashnil Limited Camps want to offer our guests the perfect blend of African hospitality, utterly breathtaking locations and first class service. Our four locations in Kenya are Masai Mara National Reserve, Tsavo East National Park and Buffalo Springs National Reserve.",
    secondParagraph:
      "Ashnil Mara Camp, with 50 luxury tents, is located in the Masai Mara National Reserve and close to the Mara River, famous for the wildebeest migration. Our luxury Masai Mara Camp is arguably in one of the very best positions in the Masai Mara for game viewing and also provides the perfect setting to sit and relax at the end of an exciting day of game drives.",
    sign: "Ashnil Hotels Limited",
    mainImg: "https://sawelalodges.com/images/slider/slider1.jpg",
    secImgTwo: "https://sawelalodges.com/images/slider/slider3.jpg",
    secImg: "https://sawelalodges.com/images/slider/slider2.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider4.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider5.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider6.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider7.jpg",
      },
    ],
  },
  zebra: {
    mainText: "Zebra Plains Collection",
    heading: "Extraordinary Safari Moments",
    headingTwo: "Classic, Luxury and Luxury Boutique Camps",
    firstParagraph:
      "Zebra Plains Collection is a collection of 3 Luxury tented safari camps in the world-famous Maasai Mara Game Reserve, home to the Big Five and the Famous Annual Wildebeests Migration.",
    secondParagraph:
      "For everyone to have their own niche accommodation and safaris, the hotel categorize the camps into three to ensure all guests book what they want this are Zebra Plains Classic Camp, Zebra River Luxury Camp and Little Zebra River Boutique Luxury Camp. Allof the camps are located in the Maasai Mara where the well-known Maasai tribe live and it is here where we employ over 80% of the local community as our way of giving our guests Authentic experience and also a way of giving back to the community Our great team of very passionate hosts will create a very memorable stay for you and your safari companions",
    sign: "Zebra Plains Collection",
    mainImg: "https://sawelalodges.com/images/slider/slider1.jpg",
    secImgTwo: "https://sawelalodges.com/images/slider/slider3.jpg",
    secImg: "https://sawelalodges.com/images/slider/slider2.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider4.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider5.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider6.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider7.jpg",
      },
    ],
  },
  aalodges: {
    mainText: "AA Lodges",
    heading: "AA Lodges",
    headingTwo: "Classic, Luxury and Luxury Boutique Camps",
    firstParagraph:
      "AA Lodges is a collection of 3 Luxury tented safari camps in the world-famous Maasai Mara Game Reserve, home to the Big Five and the Famous Annual Wildebeests Migration.",
    secondParagraph:
      "For everyone to have their own niche accommodation and safaris, the hotel categorize the camps into three to ensure all guests book what they want this are Zebra Plains Classic Camp, Zebra River Luxury Camp and Little Zebra River Boutique Luxury Camp. Allof the camps are located in the Maasai Mara where the well-known Maasai tribe live and it is here where we employ over 80% of the local community as our way of giving our guests Authentic experience and also a way of giving back to the community Our great team of very passionate hosts will create a very memorable stay for you and your safari companions",
    sign: "AA Lodges",
    mainImg: "https://sawelalodges.com/images/slider/slider1.jpg",
    secImgTwo: "https://sawelalodges.com/images/slider/slider3.jpg",
    secImg: "https://sawelalodges.com/images/slider/slider2.jpg",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider4.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider5.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider6.jpg",
      },
      {
        titleHeading: "",
        carouselHeading: "",
        image: "https://sawelalodges.com/images/slider/slider7.jpg",
      },
    ],
  },
};
export default function DetailSafariAccomodation() {
  const { matchString } = useParams<{ matchString: string | undefined }>();
  const location = useLocation();

  if (!matchString || !accomodations[matchString]) {
    return <div>Camp/lodge not found</div>;
  }

  const accomodationData = accomodations[matchString as string];

  return (
    <>
      <Head
        title={accomodationData.mainText}
        link={location.pathname}
        description={accomodationData.firstParagraph}
        imageCard={accomodationData.mainImg}
      />
      <PageTemplate
        mainText={accomodationData.mainText}
        heading={accomodationData.heading}
        headingTwo={accomodationData.headingTwo}
        firstParagraph={accomodationData.firstParagraph}
        secondParagraph={accomodationData.secondParagraph}
        sign={accomodationData.sign}
        mainImg={accomodationData.mainImg}
        secImgTwo={accomodationData.secImgTwo}
        secImg={accomodationData.secImg}
        textslide={accomodationData.textslide}
        slidesWithText={accomodationData.slidesWithText}
      />
    </>
  );
}
