import React from "react";
import { PageIntro } from "../components/ui/PageIntro";
import { PageHero } from "../components/ui/PageHero";
import LazyImage from "../utils/LazyImage";
import { Link, useLocation } from "react-router-dom";
import Button from "../components/ui/Button";
import Head from "../components/seo/Head";

export default function SafariAccomodation() {
  const location = useLocation();
  return (
    <>
      <Head
        title="Partnered Accomodation"
        link={location.pathname}
        addPostfixTitle={true}
      />
      <PageHero
        image="https://sawelalodges.com/images/detail-slider/honeymoon8.jpg"
        mainText="Featured Accommodations Camps & Lodges"
        subText="We devise itineraries that are entirely bespoke to you."
        className="object-center"
      />
      <PageIntro
        sign=" African. Safari. Experts."
        heading=" Camps & Lodges"
        p="Experience the magic with our premier camps and lodges, strategically located in the heart of stunning national parks and reserves. Each of our accommodations is designed to blend seamlessly with the natural environment, providing you with an intimate and sustainable safari experience."
        className="px-8"
      />
      <section className="section-amenities relative py-10">
        <div className="before:bg-[url('/public/assets/images/body/palm-trees.png')] bg-size-full before:bg-no-repeat before:absolute before:lg:left-[-50px] before:md:left-[-6px] before:left-[10px] before:bottom-0 before:z-[-1] before:2xl:w-[260px] before:xl:w-[185px] before:md:w-[138px] before:w-[60px] before:2xl:h-[500px] before:xl:h-[350px] before:md:h-[275px] before:h-[120px]"></div>
        <div className="flex flex-wrap justify-between items-center mx-auto 2xl:max-w-[1320px] xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] sm:max-w-[540px] relative">
          <div className="flex flex-wrap mb-[-24px]">
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className="flex flex-wrap xl:flex-row flex-col-reverse ">
                  <div className="xl:w-[50%] xl:p-0 w-[100%] p-[24px] min-h-80 flex items-center justify-center">
                    <LazyImage src="https://hodariafricacollection.com/wp-content/uploads/2024/05/IMG_3295-1024x768.jpg" />
                  </div>
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pb-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0]">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        01
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Hodari Africa collection
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          Offers a collection of luxury safari lodges located in
                          the heart of Kenya’s most spectacular destinations,
                          from the rugged beauty of Laikipia and Samburu to the
                          iconic Masai Mara.
                        </p>
                        <Link to="hodari">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col-reverse ">
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://www.kibovilla.com/wp-content/uploads/2024/05/04-kibo-villa-b.jpg" />
                  </div>
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pb-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0]">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        02
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Kibo Villa
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          Built in natural stone and elegant gum pole
                          structures, Kibo Villa has three large master ensuite
                          bedrooms, a lounge, a fully facilitated kitchen, a
                          barbeque area, and a dining area that stretches onto a
                          large veranda.
                        </p>
                        <Link to="kibo">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col">
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0] lh-amenities-right">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        03
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Sawela Lodges
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          Located in the heart of safari-staging country near
                          Lake Naivasha, the Sawela Lodge has much to offer
                          those who are not heading out to the savannah. The
                          hotel offers guests a variety of recreational
                          activities to choose from.
                        </p>
                        <Link to="sawela">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://sawelalodges.com/images/breadcrumb.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col">
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0] lh-amenities-right">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        04
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Simba lodges
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          There are some moments that pass in a second, but last
                          for a lifetime. It’s these experiences that you’ll
                          find at Simba Lodges; surprising and authentic touches
                          that make your stay a truly timeless adventure.
                        </p>
                        <Link to="simba">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://res.cloudinary.com/ddwkntgdf/image/upload/v1720121876/etefnpmeqzvduof5ucwd.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col-reverse ">
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://kibosafaricamp.com/wp-content/uploads/2020/12/20-KPW-LUXURY-TENT.jpg" />
                  </div>
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pb-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0]">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        05
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Kimbo Safari Camp
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          Kibo Safari Camp is a luxury tented camp in Amboseli
                          National Park in Kenya at the foot of Mount
                          Kilimanjaro, the highest mountain in Africa.
                        </p>
                        <Link to="kibocamp">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col-reverse ">
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://osotua-iconresort.com/wp-content/uploads/2022/09/banner1_Osotua-Resort-By-Icon-Hotels-Group-Africa.jpg" />
                  </div>
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pb-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0]">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        06
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Osotua luxury resort
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          Osotua Luxury Resort offers tastefully furnished
                          executive studio apartments and villas to ensure
                          comfort and relaxation for your short and long term
                          stays in Naivasha.
                        </p>
                        <Link to="osotua">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col">
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0] lh-amenities-right">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        07
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Sopa lodge resort
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          Tucked away within Kenya & Tanzania’s vast landscape,
                          Sopa Lodges will be found in hand-picked locations
                          with panoramic views.
                        </p>
                        <Link to="sopa">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://www.sopalodges.com/images/slider/samburu.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col">
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0] lh-amenities-right">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        08
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Punda Milias
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          The lodge grounds are a tranquil, leafy-green oasis
                          where you’ll find the perfect city break from Nairobi,
                          or have the perfect base to explore the natural
                          wonders of Nakuru National Park and Soysambu
                          Conservancy.
                        </p>
                        <Link to="punda">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://images.squarespace-cdn.com/content/v1/63396091abb2d849fa0e848a/26e97786-6fda-4e18-ae13-ec0f98ba6f51/IMG_1193.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col-reverse ">
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://static.wixstatic.com/media/dfe473_4cc5851bdc084605b5894ad9a1d8d272~mv2.jpg/v1/fill/w_948,h_558,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/dfe473_4cc5851bdc084605b5894ad9a1d8d272~mv2.jpg" />
                  </div>
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pb-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0]">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        09
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Ziwa bush lodge
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          Engulfed in the natural Bushes and indigenous trees
                          and plantations, Ziwa bush Lodge boasts 7 stand alone
                          executive cabins and 3 standard rooms.This brings the
                          magic of coexistence with nature by creating the most
                          beautiful African lodge amidst the green vegetation.
                        </p>
                        <Link to="ziwa">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col-reverse ">
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://ashnilhotels.com/wp-content/uploads/2023/01/MAIN-TENT.png" />
                  </div>
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pb-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0]">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        10
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Ashnil hotels
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          Ashnil Aruba Lodge lies in the heart of Tsavo East
                          National Park in Kenya. The Lodge features 46 rooms
                          and 6 tents. Its location is superb, just a couple of
                          hours from the coast of Mombasa, 5 hours leisurely
                          drive from Nairobi and is located on the banks of
                          Aruba Dam which was built in 1952 as a sport fishing
                          recreational point in the park.
                        </p>
                        <Link to="ashnil">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col">
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0] lh-amenities-right">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        11
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Zebra plains Collection
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          Zebra Plains Collection is a collection of 3 Luxury
                          tented safari camps in the world-famous Maasai Mara
                          Game Reserve, home to the Big Five and the Famous
                          Annual Wildebeests Migration. Owned and run by a
                          Kenyan with over 16 years of experience in safari
                          lodge management in Kenya and Tanzania.
                        </p>

                        <Link to="zebra">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://zebraplainscollection.com/wp-content/uploads/2023/11/gal-49-l.jpg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full px-[12px] mb-[24px] flex">
              <div
                className="lh-amenities shadow-[0_0_50px_0_rgba(0,0,0,0.1)] flex rounded-sm bg-colorCard overflow-hidden aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className=" flex flex-wrap xl:flex-row flex-col">
                  <div className="xl:w-[50%] xl:p-0 w-[100%] px-[24px] pt-[24px] flex items-center justify-center">
                    <div className="lh-amenities-in relative xl:p-[24px] px-[0] lh-amenities-right">
                      <h4 className="side-number absolute xl:top-[24px] xl:right-[24px] top-[0px] right-[0px] text-[55px] font-extrabold leading-[55px] text-[#0000000d]">
                        12
                      </h4>

                      <div className="amenities-contain">
                        <h4 className="amenities-heading xl:text-[22px] text-[18px] font-bold leading-[1.2]">
                          Aa lodges
                        </h4>
                        <p className="text-[14px] lg:py-[15px] md:py-[10px] py-[5px]">
                          AA LODGES is a chain of hotels duly registered under
                          the Laws of the Republic of Kenya. Founded in 2010, AA
                          Lodges has been on the premise of offering support and
                          comfort to all cadre of travelers irrespective of the
                          ever increasing competition.
                        </p>
                        <Link to="aalodges">
                          <Button text="view more" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-[50%] min-h-80 xl:p-0 w-[100%] p-[24px] flex items-center justify-center">
                    <LazyImage src="https://aalodges.com/wp-content/uploads/2018/11/AA-Lodge-Mara-20.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
