import React, { useEffect } from "react";
import "../../styles/carousel.scss";
import { EmblaOptionsType } from "embla-carousel";
import { DotButton, useDotButton } from "./EmblaCarouselDotButton";
import {
  PrevButton,
  NextButton,
  usePrevNextButtons,
} from "./EmblaCarouselArrowButtons";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import LazyImage from "../../utils/LazyImage";

interface PropType {
  options?: EmblaOptionsType;
}

interface CarouselData {
  slides?: string[];
  titleHeading?: string;
  carouselHeading?: string;
  textslide: boolean;
  slidesWithText?: {
    titleHeading: string;
    carouselHeading: string;
    image: string;
  }[];
}

const HeroCarousel: React.FC<PropType & CarouselData> = ({
  options,
  slides,
  titleHeading,
  carouselHeading,
  textslide,
  slidesWithText,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({ playOnInit: true, delay: 3000 }),
  ]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  useEffect(() => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;
  }, [emblaApi]);

  return (
    <section className="emblahome">
      {textslide && (
        <div className="absolute w-full top-[25%] p-5">
          <div className="text-center relative flex z-[5] flex-col h-full w-full items-center justify-center">
            <h1 className="font-fontMainBold font-bold capitalize lg:text-6xl md:text-5xl text-3xl text-white">
              {titleHeading}
            </h1>
            <h2 className="max-w-96 capitalize md:text-base text-base text-white font-bold tracking-wider line-clamp-2 ">
              {carouselHeading}
            </h2>
          </div>
        </div>
      )}

      <div className="emblahome__viewport relative " ref={emblaRef}>
        <div className="emblahome__container ">
          {textslide && slides
            ? slides.map((img, index) => (
                <div
                  className="emblahome__slide w-full h-[60vh] md:h-[100vh] relative before:content-[''] before:absolute before:inset-0 before:bg-black before:opacity-30"
                  key={index}
                >
                  <LazyImage src={img} />
                </div>
              ))
            : slidesWithText &&
              slidesWithText.map((slide, index) => (
                <div
                  className="emblahome__slide w-full h-[60vh] md:h-[100vh] relative before:content-[''] before:absolute before:inset-0 before:bg-black before:opacity-50"
                  key={index}
                >
                  <div className="absolute w-full top-[25%] p-5">
                    <div className="text-center relative flex z-[5] flex-col h-full w-full items-center justify-center">
                      {/* <div
                        className={`mx-auto flex shrink-0 items-center justify-center sm:mx-0 `}
                      >
                        <img
                          src="/assets/images/logo/Logo.png.webp"
                          alt=""
                          className="h-10 md:h-20 "
                        />
                      </div> */}
                      <h1 className="font-extrabold capitalize lg:text-6xl md:text-5xl text-3xl text-white">
                        {slide.titleHeading}
                      </h1>
                      <h2 className="tracking-wider font-bold text-sm md:text-lg line-clamp-2 capitalize text-white">
                        {slide.carouselHeading}
                      </h2>
                    </div>
                  </div>

                  <LazyImage src={slide.image} />
                </div>
              ))}
        </div>
        <div className="emblahome__controls overflow-hidden absolute w-full bottom-0 md:h-full mb-5 md:mb-0">
          <div className="nav md:h-full flex w-full justify-between emblahome__buttons items-center md:w-full">
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
            <div className="emblahome__dots flex md:hidden">
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={"emblahome__dot z-20 ".concat(
                    index === selectedIndex ? " emblahome__dot--selected" : ""
                  )}
                />
              ))}
            </div>
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </div>
        </div>
      </div>

      <div className="emblahome__dots hidden md:flex absolute w-full bottom-0 p-10">
        <div className="flex w-full justify-center">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"emblahome__dot z-20 ".concat(
                index === selectedIndex ? " emblahome__dot--selected" : ""
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default HeroCarousel;
