import React from "react";
import Head from "../components/seo/Head";
import { useLocation } from "react-router-dom";
import { PageHero } from "../components/ui/PageHero";
import { PageIntro } from "../components/ui/PageIntro";
import SearchDestination from "../components/ui/SearchDestination";

export default function Destinations() {
  const location = useLocation();
  return (
    <>
      <Head
        title="Destinations"
        addPostfixTitle={true}
        link={location.pathname}
        description="Join us for a journey beyond the ordinary, where luxury meets adventure, and every encounter with wildlife is a profound connection. Discover the beauty of the natural world with us, as we take you on safaris that enrich your soul and leave you with memories to treasure for a lifetime."
      />

      <PageHero
        image="https://images.unsplash.com/photo-1680346087980-1dbc57f7ba56"
        mainText="Tailor-Made Holidays"
        subText="We have the freedom to be creative and responsive to your every requirement."
        className="object-bottom"
      />
      <PageIntro
        sign="Divine Travel Nest Destinations"
        heading="BESPOKE JOURNEYS"
        p="At Divine Travel Nest Safaris, we take pride in offering a wide array of destinations across East Africa, each promising a unique and enriching experience. Our team of dedicated consultants are not just experts; they are passionate about crafting tailor-made holidays that exceed your expectations."
        className="px-8"
      />
      <SearchDestination />
    </>
  );
}
