// Header Data
export const headerData = {
  pages: [
    {
      name: "safaris",
      href: "/safari-packages",
      sideBar: [
        { text: "Kenya Safari Parks", href: "/safari-parks/Kenya" },
        { text: "Tanzania Safari Parks", href: "/safari-parks/Tanzania" },
        { text: "Uganda Safaris Parks", href: "/safari-parks/Uganda" },
      ],
      menuItems: [
        {
          text: "Safari Packages",
          href: "/safari-packages",
          img: "https://images.unsplash.com/photo-1550547050-744447052329",
        },

        {
          text: "Safari Experiences",
          href: "/safari-types",
          img: "https://images.unsplash.com/photo-1577971132997-c10be9372519",
        },
        {
          text: "Safari Destinations",
          href: "/destinations",
          img: "https://images.unsplash.com/photo-1519659528534-7fd733a832a0",
        },
      ],
    },
    {
      name: "about",
      href: "/about",
      sideBar: [
        { text: "Contact us", href: "/contacts" },
        { text: "Our Responsibility", href: "/responsibility" },
        { text: "Our Blog", href: "/blogs" },
      ],
      menuItems: [
        {
          text: "About us",
          href: "/about",
          img: "https://res.cloudinary.com/ddwkntgdf/image/upload/v1707631012/mflurmaldtx9khlfz99m.jpg",
        },
        {
          text: "Safari Accomodations",
          href: "/safari-accomodations",
          img: "https://images.unsplash.com/photo-1506059612708-99d6c258160e",
        },
        {
          text: "Our team",
          href: "/our-team",
          img: "https://res.cloudinary.com/ddwkntgdf/image/upload/v1707486798/okt7mzr2udf4k4ty6tkj.jpg",
        },
      ],
    },
    {
      name: "destinations",
      href: "/destinations",
      sideBar: [
        { text: "Kenya", href: "destinations/Kenya" },
        { text: "Tanzania", href: "destinations/Tanzania" },
        { text: "Uganda", href: "destinations/Uganda" },
      ],
      menuItems: [
        {
          text: "Best of Kenya Safaris",
          href: "destinations/Kenya",
          img: "https://images.unsplash.com/photo-1477949775154-d739b82400b3",
        },
        {
          text: "Best of Tanzania Safaris",
          href: "destinations/Tanzania",
          img: "https://images.unsplash.com/photo-1575999502951-4ab25b5ca889",
        },
        {
          text: "Best of Uganda Safaris",
          href: "destinations/Uganda",
          img: "https://images.unsplash.com/photo-1711198612606-734e3410efea",
        },
      ],
    },
    {
      name: "contacts",
      href: "/contacts",
      sideBar: [
        { text: "Chat on whats up", href: "https://wa.me/+254712954545" },
        { text: "Make an enquiry", href: "/contacts" },
        { text: "Email us", href: "/contacts" },
      ],
      menuItems: [
        {
          text: "Contacts",
          href: "/contacts",
          img: "https://images.unsplash.com/photo-1470848051974-964b789cb6fa",
        },
        // {
        //   text: "Why choose us",
        //   href: "/why-divine-travel",
        //   img: "https://images.unsplash.com/photo-1521651201144-634f700b36ef",
        // },
        {
          text: "Curate safaris",
          href: "/curate-safaris",
          img: "https://images.unsplash.com/photo-1578326626553-39f72c545b07",
        },
        {
          text: "Safari itinerary",
          href: "/safari-packages",
          img: "https://images.unsplash.com/photo-1657658153344-3fa560150950",
        },
      ],
    },
  ],
};
