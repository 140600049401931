import React, { useCallback, useEffect, useRef, useState } from "react";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import Imgix from "react-imgix";
import { NextButton, PrevButton } from "../EmblaArrows";
import { Link } from "react-router-dom";

type PropType = {
  options?: EmblaOptionsType;
  dataImage: {
    name: string;
    details: string;
    image: string;
    disabled: boolean;
  }[];
};

const DstSlider: React.FC<PropType> = (props) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const { options, dataImage } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  return (
    <div className="embla flex flex-col justify-center lg:hidden">
      <div className="embla__viewport w-full" ref={emblaRef}>
        <div className="embla__destination-mobile-container" ref={carouselRef}>
          {dataImage.map((photourl, i) => (
            <Link
              to={photourl.disabled ? "#" : `destinations/${photourl.name}`}
              key={i}
              className="embla__slide-destination last:mr-5 relative after:content-['']
          after:absolute after:inset-0 after:bg-black after:opacity-30 after:hover:opacity-65 after:transition-all after:duration-500"
            >
              <Imgix
                className="lazyload h-full object-cover"
                src={`${photourl.image}?auto=format`}
                width={600}
                height={800}
                htmlAttributes={{
                  sizes:
                    "(max-width: 600px) 100vw, (max-width: 1200px) 100vw, 100vw",
                }}
              />

              <div className="absolute top-0 left-0 right-0 bottom-0 h-full w-full">
                <div className="relative flex z-[5] flex-col h-full w-full items-center justify-center">
                  <h1 className="font-AthelasBold cursor-pointer font-extrabold capitalize text-4xl text-white">
                    {photourl.name}
                  </h1>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div className="p-5 flex justify-end items-center">
        <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} />
        <NextButton onClick={scrollNext} disabled={nextBtnDisabled} />
      </div>
    </div>
  );
};
export default DstSlider;
