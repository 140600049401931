import React from "react";

import Slider from "react-slick";

export default function PartnersSlider() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    arrows: false,
    autoplaySpeed: 1000,
    pauseOnHover: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <section>
      <Slider {...settings} className="pb-10">
        <div
          className="partners
         px-2 sm:px-5"
        >
          <img
            className="img-partners"
            src="assets/images/logo/tripadvisor.png"
            alt="Your alt text"
          />
        </div>
        <div className="partners px-2 md:px-5">
          <img
            className="img-partners"
            src="assets/images/logo/tra.png"
            alt="Your alt text"
          />
        </div>
        <div
          className="partners 
         px-2 sm:px-5"
        >
          <img
            className="img-partners"
            src="assets/images/logo/trustpilot.png"
            alt="Your alt text"
          />
        </div>
        <div
          className="partners 
         px-2 sm:px-5"
        >
          <img
            className="img-partners"
            src="assets/images/logo/asilia.png"
            alt="Your alt text"
          />
        </div>

        <div
          className="partners 
         px-2 sm:px-5"
        >
          <img
            className="img-partners"
            src="assets/images/logo/beyond.png"
            alt="Your alt text"
          />
        </div>
        <div
          className="partners 
         px-2 sm:px-5"
        >
          <img
            className="img-partners"
            src="assets/images/logo/Eco-tourism_Kenya.png"
            alt="Your alt text"
          />
        </div>
        <div
          className="partners 
         px-2 sm:px-5"
        >
          <img
            className="img-partners"
            src="assets/images/logo/KWS.png"
            alt="Your alt text"
          />
        </div>
        <div
          className="partners
         px-2 sm:px-5"
        >
          <a target="blank" href="https://www.safaribookings.com/">
            <img
              className="img-partners"
              src="assets/images/logo/safari-bookings.png"
              alt="Your alt text"
            />
          </a>
        </div>
      </Slider>
    </section>
  );
}
