import React, { useCallback, useEffect, useState } from "react";
import Imgix from "react-imgix";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { NextButton, PrevButton } from "./EmblaArrows";

type PropType = {
  options?: EmblaOptionsType;
  images: string[];
};

const CountryImageSlide: React.FC<PropType> = (props) => {
  const { options, images } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);
  return (
    <>
      <div className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__destination-container gap-3 md:gap-5">
            {images.map((photourl, i) => (
              <div key={i} className="last:mr-3 md:last:mr-5">
                <Imgix
                  className="h-full max-h-[95vh] object-cover"
                  src={`${photourl}?auto=format`}
                  sizes="(max-width: 600px) 100vw, (max-width: 1024px) 100vw, 100vw"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="p-5 flex justify-end items-center">
          <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} />
          <NextButton onClick={scrollNext} disabled={nextBtnDisabled} />
        </div>
      </div>
    </>
  );
};
export default CountryImageSlide;
