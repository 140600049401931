import React from "react";

export const WhyUsPoints = () => {
  return (
    <div className="background bg-colorBackground">
      <div className="why-us-container">
        <div className="panel pricing-table">
          <div className="pricing-plan flex flex-col items-center">
            <img
              src="assets/images/icons/technical-support-customer-svgrepo-com.png"
              alt=""
              className="pricing-img h-16"
            />
            <h2 className="text-sm font-semibold">24/7 Customer Support</h2>
            <ul className="pricing-features">
              <li className="pricing-features-item text-colorTextSecond">
                +254 722-595-916
              </li>
              <li className="pricing-features-item text-colorTextSecond">
                +254 712-954-545
              </li>
            </ul>
          </div>

          <div className="pricing-plan flex flex-col items-center">
            <img
              src="assets/images/icons/settings-svgrepo-com.png"
              alt=""
              className="pricing-img h-16"
            />
            <h2 className="text-sm font-semibold">Customizable Tours</h2>
            <ul className="pricing-features">
              <li className="pricing-features-item text-colorTextSecond">
                Nothing we do is off the shelf, one size never fits all. Your
                dedicated consultant will be with you throughout the process,
                from that first call to the day you return home, drawing from
                their own personal expertise and helping you make the right
                choices.
              </li>
            </ul>
          </div>

          <div className="pricing-plan flex flex-col items-center">
            <img
              src="assets/images/icons/location-man-svgrepo-com.png"
              alt=""
              className="pricing-img h-16"
            />
            <h2 className="text-sm font-semibold">
              Best Safari guides and drivers
            </h2>
            <ul className="pricing-features">
              <li className="pricing-features-item text-colorTextSecond">
                Our team of qualified safari experts knows how to tailor your
                adventure to your individual travel style
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
