import { Link } from "react-router-dom";
import LazyImage from "../../utils/LazyImage";
import { currencyFormat } from "simple-currency-format";

interface SafariProps {
  id: string;
  image: string;
  description: string;
  title: string;
  price: any;
  days?: number;
  priceFrom: number;
  slug: string;
  featured: boolean;
  category: string[];
  highlights: string[];
}

export default function SafariCard({
  title,
  highlights,
  image,
  slug,
  price,
  featured,
  days,
  priceFrom,
}: SafariProps) {
  return (
    <>
      <Link to={`/safari-package/${slug}`} className="flex-grow relative">
        <div className="relative h-full w-full shadow inline-block duration-300 ease-in-out transition-transform transform hover:-translate-y-2 bg-colorCard">
          <div className="shadow p-4 h-full bg-light">
            <div className="flex justify-center relative  overflow-hidden h-60">
              <div className="transition-transform duration-500 transform ease-in-out hover:scale-110 w-full ">
                <LazyImage src={image} />
                <div className="absolute inset-0 bg-black opacity-25"></div>
              </div>
              {featured && (
                <span className="absolute top-0 left-0 inline-flex mt-3 ml-3 px-3 py-2  z-10 bg-colorTextSecond text-xs uppercase font-medium text-white select-none">
                  Featured
                </span>
              )}
            </div>

            <div className="mt-4">
              <h2
                className="tracking-wider font-bold text-sm md:text-lg line-clamp-2 capitalize"
                title={title}
              >
                {title}
              </h2>
            </div>
            <div className="mt-4">
              <ul className="text-xs grid grid-cols-2">
                {highlights.map((highlight: string) => (
                  <li
                    key={highlight}
                    className="pr-1 capitalize flex items-center"
                  >
                    <img
                      src="/assets/images/icons/location-svgrepo-com.png"
                      alt="clock"
                      className="my-3 h-4 mr-2"
                    />

                    {highlight}
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex items-center justify-between">
              <div className="relative flex items-center">
                <img
                  src="/assets/images/icons/clock-0300-svgrepo-com.png"
                  alt="clock"
                  className="my-3 h-5 w-5"
                />
                <p className=" text-xs">Days: {days}</p>
              </div>
              <div>
                <p className="text-xs">from</p>
                <p className="text-base font-bold">
                  {currencyFormat(priceFrom, "en-US", "USD")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
