import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SafariPackage } from "../../utils/interface";
import useSafaris from "../../hooks/useSafaris";
import { PageHero } from "../../components/ui/PageHero";
import { PageIntro } from "../../components/ui/PageIntro";
import SafariCard from "../../components/ui/SafariCard";
import { destinationData } from "../../data/DestinationsData";
import CountryImageSlide from "../../components/CountryImageSlide";
import { EmblaOptionsType } from "embla-carousel";
import DstSlider from "../../components/home/DstSlider";
import DestinationBanner from "../../components/home/DestinationBanner";
import Heading from "../../components/ui/Heading";
import Loader from "../../components/ui/Loader";
import Head from "../../components/seo/Head";

const OPTIONS: EmblaOptionsType = {
  containScroll: "trimSnaps",
  loop: true,
};

const SafariDestination = () => {
  const { destination } = useParams<{ destination: string }>() as {
    destination: keyof typeof destinationData;
  };

  const [safariPackageData, setSafariPackageData] = useState<SafariPackage[]>(
    []
  );

  const location = useLocation();

  const {
    allSafarisQuery: { isLoading: itemLoading, error, data },
  } = useSafaris();

  useEffect(() => {
    if (data && data.length > 0) {
      const filteredSafaris = data.filter((safari) =>
        safari.destination.includes(destination)
      );

      setSafariPackageData(filteredSafaris);
    }
  }, [data, destination]);

  return (
    <>
      {destinationData[destination].map((element) => (
        <div key={element.name}>
          <Head
            title={`Africa Safari in ${element.name}`}
            link={location.pathname}
            description={element.description}
            imageCard={element.image}
          />
          <PageHero
            image={element.image}
            mainText={element.name}
            subText="Dare to Explore Africa with us"
          />
          <PageIntro
            sign={element.name}
            heading={element.name}
            p={element.description}
            className="px-8"
          />
          <CountryImageSlide images={element.photoURL} options={OPTIONS} />
          <div className=" bg-[#c2a77e] w-full flex justify-center items-center flex-col">
            <div className="m-5 md:mb-0 md:m-10 min-h-96 flex flex-col justify-center bg-white md:flex-row shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]">
              <div className="p-10 w-full md:w-[55%] flex flex-col justify-center ">
                <h1 className="font-Averia font-bold uppercase text-xs text-primary text-nowrap">
                  {element.name} key facts
                </h1>
                <p className="font-Athelas text-base">{element.facts}</p>
              </div>
              <div className="hidden md:block relative my-auto h-96 w-px bg-gray-200"></div>
              <div className="md:hidden block relative my-auto h-px w-full bg-gray-200"></div>
              <div className="p-10 w-full md:w-[45%]">
                <dl className="">
                  <dt className="font-Averia text-xs font-bold uppercase text-primary ">
                    languages
                  </dt>
                  <dd className=" font-Athelas text-base mb-3">
                    {element.language}
                  </dd>
                </dl>
                <dl className="">
                  <dt className="font-Averia text-xs font-bold uppercase text-primary tracking-widest">
                    currency
                  </dt>
                  <dd className=" font-Athelas text-base mb-3">
                    {element.currency}
                  </dd>
                </dl>
                <dl className="">
                  <dt className="font-Averia text-xs font-bold uppercase text-primary tracking-widest">
                    Geographical area
                  </dt>
                  <dd className=" font-Athelas text-base mb-3">
                    {element.area}
                  </dd>
                </dl>
                <dl className="">
                  <dt className="font-Averia text-xs font-bold uppercase text-primary tracking-widest">
                    Best Time to visit : {element.reasonsToVisit.summary}
                  </dt>
                  <dd className=" font-Athelas text-base mb-3">
                    {element.reasonsToVisit.detail}
                  </dd>
                </dl>
              </div>
            </div>
            <div className="m-5 md:m-10 md:px-10 px-5 md:pt-6 pt-8 md:pb-10 min-h-96  bg-white md:flex-row shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]">
              <h1 className="font-Averia font-bold uppercase text-xs text-primary text-nowrap">
                Habitats in {element.name}
              </h1>
              <div className="flex flex-col justify-center">
                <DstSlider dataImage={element.habitats} />
                <DestinationBanner dataImage={element.habitats} />
              </div>
            </div>
          </div>
          <div>
            <Heading text={`top safari packages in ${element.name}`} />
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 w-full px-2 my-5 ">
              <>
                {error && <h1>Error</h1>}
                {itemLoading && (
                  <div className="h-screen w-screen flex justify-center items-center">
                    <Loader />
                  </div>
                )}
                {safariPackageData?.map((safariProduct) => {
                  return (
                    <li
                      key={safariProduct.id}
                      className="flex flex-col justify-between"
                    >
                      <SafariCard
                        id={safariProduct.id}
                        featured={safariProduct.featured}
                        image={safariProduct.image_url}
                        description={safariProduct.safari_package_description}
                        title={safariProduct.title}
                        price={safariProduct.prices_midrange}
                        days={safariProduct.details?.days}
                        priceFrom={safariProduct.details.price_from}
                        category={safariProduct.safari_package_category}
                        highlights={safariProduct.safari_package_highlights}
                        slug={safariProduct.slug}
                      />
                    </li>
                  );
                })}
              </>
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default SafariDestination;
