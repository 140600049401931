export const EMAIL_REGEX =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

interface PublishDate {
  seconds: number;
  nanoseconds: number;
}

interface LastUpdated {
  seconds: number;
  nanoseconds: number;
}

interface SeoMetadata {
  meta_title?: string | null;
  meta_description?: string | null;
  focus_keywords?: string[];
}

interface Details {
  price_from: number;
  days: number;
}

export interface ItineraryDetail {
  day_title: string;
  day_description: string;
  optional_activities?: string;
  meals?: string;
  accommodation?: string;
  activities?: string;
  destination?: string;
  complementary?: string;
  travel_time?: string;
}

export interface SafariPackage {
  id: string;
  image_url: string;
  safari_package_description: string;
  title: string;
  destination: string;
  publish_date: PublishDate;
  safari_package_category: string[];
  safari_package_highlights: string[];
  safari_package_image: string;
  slug: string;
  prices_midrange?: {
    [month: string]: {
      two_three_people: number;
      four_five_people: number;
      six_or_more_people: number;
      one_person: number;
    };
  };
  seo_metadata?: SeoMetadata;
  details: Details;
  featured: boolean;
  last_updated: LastUpdated;
  itinerary: {
    itinerary_details: ItineraryDetail[];
  };
}

export interface BlogPost {
  id: string;
  name: string;
  slug: string;
  publish_date: PublishDate;
  header_image: string;
  content: (
    | {
        type: string;
        value: string;
      }
    | { type: string; value: string[] }
  )[];
  status: string;
  read_time: number;
  tag: string[];
  first_paragraph_preview: string;
  category: string;
  blog_description: string;
}
