import React from "react";
import { PageIntro } from "../components/ui/PageIntro";
import { PageHero } from "../components/ui/PageHero";
import { Background } from "react-imgix";
import Head from "../components/seo/Head";
import { useLocation } from "react-router-dom";

export default function SafariTypes() {
  const location = useLocation();
  return (
    <>
      <Head
        title="Safari Experiences"
        link={location.pathname}
        addPostfixTitle={true}
      />
      <PageHero
        image="https://images.unsplash.com/photo-1444210971048-6130cf0c46cf"
        mainText="Holiday Types"
        subText="We devise itineraries that are entirely bespoke to you."
        className="object-center"
      />
      <PageIntro
        sign=" African. Safari. Experts."
        heading="TAILORED TO YOU"
        p="We understand that every traveler is unique, which is why we offer customizable safari experiences tailored to your preferences. Whether you dream of witnessing the Great Migration in East Africa, embarking on a photographic safari to capture mesmerizing wildlife moments, or indulging in a luxurious retreat amidst the wilderness, our team will work closely with you to design a personalized itinerary that fulfills your desires and exceeds your expectations"
        className="px-8"
      />
      <section className="container mx-auto py-10 md:py-20 px-5">
        <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
          <Background
            src="https://images.unsplash.com/photo-1635460585286-19beb893037e"
            className="relative bg-cover rounded-md transform duration-300 hover:-translate-y-1 cursor-pointer hover:shadow-2xl group after:content-['']
          after:absolute after:inset-0 after:bg-black after:rounded-md after:opacity-0 after:hover:opacity-65 after:transition-all after:duration-300"
          >
            <article className="flex items-end pl-3 pb-6 min-h-96 h-96">
              <div className="flex flex-col z-10">
                <div className="w-fit px-3 py-1 rounded-full text-sm bg-white transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300">
                  <p className="font-bold ">Specialized Safaris</p>
                </div>
                <div className="w-16 h-1 bg-colorCard rounded-full my-2 ml-3 transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300"></div>
                <p className="opacity-0 p-2 text-white text-sm group-hover:opacity-100 transform duration-500 h-48">
                  We have categorized this safaris tours to focus on specific
                  species or habitats, such as birdwatching safaris, gorilla
                  trekking safaris in Uganda and Rwanda, wildbeest migrations
                  and much more. These specialized safaris cater to enthusiasts
                  interested in particular animals or ecosystems.
                </p>
              </div>
            </article>
          </Background>
          <Background
            src="https://images.unsplash.com/photo-1544979590-37e9b47eb705"
            className="relative bg-cover rounded-md transform duration-300 hover:-translate-y-1 cursor-pointer hover:shadow-2xl group after:content-['']
          after:absolute after:inset-0 after:bg-black after:rounded-md after:opacity-0 after:hover:opacity-65 after:transition-all after:duration-300"
          >
            <article className="flex items-end pl-3 pb-6 min-h-96 h-96">
              <div className="flex flex-col z-10">
                <div className="w-fit px-3 py-1 rounded-full text-sm bg-white transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300">
                  <p className="font-bold ">Photographic Safaris</p>
                </div>
                <div className="w-16 h-1 bg-colorCard rounded-full my-2 ml-3 transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300"></div>
                <p className="opacity-0 p-2 text-white text-sm group-hover:opacity-100 transform duration-500 h-48">
                  These safaris are tailored for photography enthusiasts,
                  offering specialized vehicles and guides who understand the
                  best angles and lighting for wildlife photography.
                  Photographic safaris often focus on spending extended periods
                  at specific locations to capture unique wildlife behaviors and
                  scenes.
                </p>
              </div>
            </article>
          </Background>
          <Background
            src="https://images.unsplash.com/photo-1523402419494-ff575e437f21"
            className="relative bg-cover rounded-md transform duration-300 hover:-translate-y-1 cursor-pointer hover:shadow-2xl group after:content-['']
          after:absolute after:inset-0 after:bg-black after:rounded-md after:opacity-0 after:hover:opacity-65 after:transition-all after:duration-300"
          >
            <article className="flex items-end pl-3 pb-6 min-h-96 h-96">
              <div className="flex flex-col z-10">
                <div className="w-fit px-3 py-1 rounded-full text-sm bg-white transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300">
                  <p className="font-bold ">Honeymoon Safaris</p>
                </div>
                <div className="w-16 h-1 bg-colorCard rounded-full my-2 ml-3 transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300"></div>
                <p className="opacity-0 p-2 text-white text-sm group-hover:opacity-100 transform duration-500 h-48">
                  Our Honeymoon safaris are typically designed to offer couples
                  a romantic and memorable experience in a wildlife or natural
                  setting
                </p>
              </div>
            </article>
          </Background>
          <Background
            src="https://images.unsplash.com/photo-1585523658894-cc78fc2c8f67"
            className="relative bg-cover rounded-md transform duration-300 hover:-translate-y-1 cursor-pointer hover:shadow-2xl group after:content-['']
          after:absolute after:inset-0 after:bg-black after:rounded-md after:opacity-0 after:hover:opacity-65 after:transition-all after:duration-300"
          >
            <article className="flex items-end pl-3 pb-6 min-h-96 h-96">
              <div className="flex flex-col z-10">
                <div className="w-fit px-3 py-1 rounded-full text-sm bg-white transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300">
                  <p className="font-bold ">Luxury Safaris</p>
                </div>
                <div className="w-16 h-1 bg-colorCard rounded-full my-2 ml-3 transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300"></div>
                <p className="opacity-0 p-2 text-white text-sm group-hover:opacity-100 transform duration-500 h-48">
                  Luxury safaris are designed to cater for exclusive and
                  indulgent experiences in some of the Africa's most pristine
                  and remote natural environments from all personalized service,
                  private safari vehicles and sustainable tourism practices,
                  including eco-friendly lodges and tented camps.
                </p>
              </div>
            </article>
          </Background>
          <Background
            src="https://images.unsplash.com/photo-1535683939029-0030b4de2382"
            className="relative bg-cover rounded-md transform duration-300 hover:-translate-y-1 cursor-pointer hover:shadow-2xl group after:content-['']
          after:absolute after:inset-0 after:bg-black after:rounded-md after:opacity-0 after:hover:opacity-65 after:transition-all after:duration-300"
          >
            <article className="flex items-end pl-3 pb-6 min-h-96 h-96">
              <div className="flex flex-col z-10">
                <div className="w-fit px-3 py-1 rounded-full text-sm bg-white transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300">
                  <p className="font-bold ">Flying Safaris</p>
                </div>
                <div className="w-16 h-1 bg-colorCard rounded-full my-2 ml-3 transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300"></div>
                <p className="opacity-0 p-2 text-white text-sm group-hover:opacity-100 transform duration-500 h-48">
                  Our flying safaris offer a unique and efficient way to explore
                  remote and expansive wilderness areas. These safaris involve
                  traveling between different safari destinations or remote
                  lodges via small aircraft rather than overland transfers.
                </p>
              </div>
            </article>
          </Background>
          <Background
            src="https://images.unsplash.com/photo-1506929562872-bb421503ef21"
            className="relative bg-cover rounded-md transform duration-300 hover:-translate-y-1 cursor-pointer hover:shadow-2xl group after:content-['']
          after:absolute after:inset-0 after:bg-black after:rounded-md after:opacity-0 after:hover:opacity-65 after:transition-all after:duration-300"
          >
            <article className="flex items-end pl-3 pb-6 min-h-96 h-96">
              <div className="flex flex-col z-10">
                <div className="w-fit px-3 py-1 rounded-full text-sm bg-white transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300">
                  <p className="font-bold ">Beach Safaris</p>
                </div>
                <div className="w-16 h-1 bg-colorCard rounded-full my-2 ml-3 transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300"></div>
                <p className="opacity-0 p-2 text-white text-sm group-hover:opacity-100 transform duration-500 h-48">
                  Divine travel nest Beach safaris offer a unique blend of
                  wildlife adventure and relaxation on some of the Africa's most
                  stunning coastlines such as Mombasa, Zanzibar and Kilifi.
                  These safaris typically combine wildlife viewing with leisure
                  time spent at luxurious beach resorts or lodges.
                </p>
              </div>
            </article>
          </Background>
          <Background
            src="https://images.unsplash.com/photo-1571636240366-1606c4a13f55"
            className="relative bg-cover rounded-md transform duration-300 hover:-translate-y-1 cursor-pointer hover:shadow-2xl group after:content-['']
          after:absolute after:inset-0 after:bg-black after:rounded-md after:opacity-0 after:hover:opacity-65 after:transition-all after:duration-300"
          >
            <article className="flex items-end pl-3 pb-6 min-h-96 h-96">
              <div className="flex flex-col z-10">
                <div className="w-fit px-3 py-1 rounded-full text-sm bg-white transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300">
                  <p className="font-bold ">Mountain Safaris</p>
                </div>
                <div className="w-16 h-1 bg-colorCard rounded-full my-2 ml-3 transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300"></div>
                <p className="opacity-0 p-2 text-white text-sm group-hover:opacity-100 transform duration-500 h-48">
                  We offer Mountain safaris that are unique adventure experience
                  focused on exploring high-altitude landscapes, diverse flora
                  and fauna, and often involve trekking or hiking through rugged
                  terrain on the slopes of Africa famous mountains such as
                  Mt.Kenya and Mt.Kilimanjaro.
                </p>
              </div>
            </article>
          </Background>
          <Background
            src="https://images.unsplash.com/photo-1430462708036-7ef5363d56d8"
            className="relative bg-cover rounded-md transform duration-300 hover:-translate-y-1 cursor-pointer hover:shadow-2xl group after:content-['']
          after:absolute after:inset-0 after:bg-black after:rounded-md after:opacity-0 after:hover:opacity-65 after:transition-all after:duration-300"
          >
            <article className="flex items-end pl-3 pb-6 min-h-96 h-96">
              <div className="flex flex-col z-10">
                <div className="w-fit px-3 py-1 rounded-full text-sm bg-white transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300">
                  <p className="font-bold ">Nature Walk Safaris</p>
                </div>
                <div className="w-16 h-1 bg-colorCard rounded-full my-2 ml-3 transform translate-y-20 sm:translate-y-40 group-hover:translate-y-0 duration-300"></div>
                <p className="opacity-0 p-2 text-white text-sm group-hover:opacity-100 transform duration-500 h-48">
                  With our Nature walking safaris packages you will have a more
                  intimate experience with nature and wildlife. Accompanied by
                  experienced guides, you will explore on foot, learning about
                  tracks, plants, and smaller animals that might be missed
                  during a game drive.
                </p>
              </div>
            </article>
          </Background>
        </section>
      </section>
    </>
  );
}
