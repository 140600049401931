import React, { useEffect, useState } from "react";
import Heading from "../components/ui/Heading";
import Advrt from "../components/home/Advrt";
import Blog from "../components/home/Blog";
import PartnersSlider from "../components/home/PartnersSlider";
import { WhyUs } from "../components/home/WhyUs";
import SafariCard from "../components/ui/SafariCard";
import DstSlider from "../components/home/DstSlider";
import { EmblaOptionsType } from "embla-carousel";
import useSafaris from "../hooks/useSafaris";
import { SafariPackage } from "../utils/interface";
import { Link } from "react-router-dom";
import Imgix from "react-imgix";
import { WhyUsPoints } from "../components/ui/WhyUsPoints";
import HeroCarousel from "../components/embla/HeroCarousel";
import Loader from "../components/ui/Loader";
import Head from "../components/seo/Head";

export default function Home() {
  const [safariPackageData, setSafariPackageData] = useState<SafariPackage[]>(
    []
  );

  const {
    allSafarisQuery: { isLoading: itemLoading, error, data },
  } = useSafaris();

  useEffect(() => {
    if (data && data.length > 0) {
      setSafariPackageData(data);
    }
  }, [data]);

  const OPTIONS: EmblaOptionsType = {
    containScroll: "trimSnaps",
    loop: true,
    dragFree: true,
  };

  const destinations = [
    {
      name: "Kenya",
      image: "https://images.unsplash.com/photo-1638093812170-9422ebf1822f",
      details: "",
      disabled: false,
    },
    {
      name: "Uganda",
      image: "https://images.unsplash.com/photo-1594068304148-3e33049a2651",
      details: "",
      disabled: false,
    },
    {
      name: "Tanzania",
      image: "https://images.unsplash.com/photo-1549035092-33b2937b075a",
      details: "",
      disabled: false,
    },
  ];

  return (
    <>
      <Head />
      <section className="hero-section ">
        {/* <MainSlider /> */}
        <HeroCarousel
          options={OPTIONS}
          titleHeading="Safari Serenity"
          carouselHeading="Tailor-made journeys and group tours designed just for you."
          textslide={true}
          slides={[
            "https://images.unsplash.com/photo-1517001170041-70a5966d5402",
            "https://images.unsplash.com/photo-1586041247337-cbf8401140d4",
            "https://images.unsplash.com/photo-1707299782155-a97fe1788b43",
            "https://images.unsplash.com/photo-1527436553949-ea84cd6cf13c",
            "https://images.unsplash.com/photo-1548523490-af827a7a8121",
          ]}
        />
      </section>
      <section className="introduction">
        <Heading
          text="Welcome to Divine travel nest"
          className="mt-3 mb-5 sm:mt-5 sm:mb-10"
        />
        <div className="w-full flex flex-col items-center ">
          <div className="w-3/4 md:text-base text-sm font-Athelas">
            <p className="">
              Welcome to Divine Travel Nest Safaris: Your Gateway to the African
              Wilderness! Divine Travel Nest Safaris is a leading safari
              operator specializing in crafting exceptional wildlife experiences
              across the captivating landscapes of Africa. With a passion for
              conservation and a deep understanding of the region’s rich
              biodiversity, we offer unparalleled safari adventures that immerse
              you in the untamed beauty of the African continent.{" "}
            </p>
            <p className="mt-3">
              Join Divine Travel Nest Safaris and let us ignite your sense of
              wonder as you embark on an unforgettable safari adventure, where
              the rhythm of the wilderness beckons and the spirit of Africa
              comes alive.
            </p>
          </div>
        </div>

        <div className="flex justify-center items-center py-10">
          <h2 className="font-Sebastian text-6xl">African. Safari. Experts.</h2>
        </div>
      </section>
      <section className="destinations">
        <Heading
          text="Our Top Safari Destinations"
          className="mt-3 mb-5 sm:mt-5 sm:mb-10"
        />
        <div className="md:px-5 ">
          <div className="grid-cols-3 md:gap-5 gap-2 hidden lg:grid">
            {destinations.map((photourl, i) => (
              <Link
                to={photourl.disabled ? "#" : `destinations/${photourl.name}`}
                key={i}
                className="relative min-h-full md:min-h-96 after:content-['']
          after:absolute after:inset-0 after:bg-black after:opacity-30 after:hover:opacity-65 after:transition-all after:duration-500"
              >
                <Imgix
                  className="lazyload h-full object-cover"
                  src={`${photourl.image}?auto=format`}
                  width={800}
                  height={600}
                  htmlAttributes={{
                    sizes:
                      "(max-width: 600px) 100vw, (max-width: 1200px) 100vw, 100vw",
                  }}
                />

                <div className="absolute top-0 left-0 right-0 bottom-0 h-full w-full">
                  <div className="relative flex z-[5] flex-col h-full w-full items-center justify-center">
                    <h1 className=" font-AthelasBold cursor-pointer font-extrabold capitalize text-4xl text-center text-white">
                      {photourl.name}
                    </h1>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <DstSlider options={OPTIONS} dataImage={destinations} />
      </section>
      <div className="flex flex-col justify-center items-center lg:mt-5 "></div>
      <div className="">
        <Advrt />
      </div>
      <div className="">
        <div className="my-1">
          <Heading
            text="Popular Tours"
            className="mt-3 mb-5 sm:mt-5 sm:mb-10"
          />
        </div>
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 w-full px-5 my-5 ">
          <>
            {error && <h1>Error</h1>}
            {itemLoading && (
              <div className="h-screen w-screen flex justify-center items-center">
                <Loader />
              </div>
            )}
            {safariPackageData?.map((safariProduct) => {
              return (
                <li
                  key={safariProduct.id}
                  className="flex flex-col justify-between"
                >
                  <SafariCard
                    id={safariProduct.id}
                    image={safariProduct.image_url}
                    description={safariProduct.safari_package_description}
                    title={safariProduct.title}
                    price={safariProduct.prices_midrange}
                    days={safariProduct.details?.days}
                    priceFrom={safariProduct.details.price_from}
                    category={safariProduct.safari_package_category}
                    featured={safariProduct.featured}
                    highlights={safariProduct.safari_package_highlights}
                    slug={safariProduct.slug || ""}
                  />
                </li>
              );
            })}
          </>
        </ul>
      </div>
      <Blog />
      <div className="pt-10">
        <Heading text="Our partners" className="mt-3 mb-5 sm:mt-5 sm:mb-10" />
        <PartnersSlider />
      </div>
      <div className=" bg-[#c2a77e] w-full flex justify-center items-center flex-col">
        <div className="m-6 flex flex-col lg:flex-row shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px]">
          <div className="w-full lg:w-[35%] ">
            <WhyUs />
          </div>
          <WhyUsPoints />
        </div>
      </div>
    </>
  );
}
