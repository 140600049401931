import { DateTime } from "luxon";

type Timestamp = {
  seconds: number;
  nanoseconds: number;
};

type FormatOption = "readableDate" | "formattedDate";

export const timeStampConverter = (
  timestamp: Timestamp,
  formatOption: FormatOption
): string => {
  // Extract seconds and nanoseconds from the timestamp
  const { seconds, nanoseconds } = timestamp;

  // Combine seconds and nanoseconds into milliseconds
  const milliseconds = seconds * 1000 + nanoseconds / 1e6;

  // Convert milliseconds to DateTime object (assuming UTC)
  const dateTime = DateTime.fromMillis(milliseconds, { zone: "utc" });

  // Format DateTime object to desired format
  if (formatOption === "readableDate") {
    return dateTime.toFormat("yyyy-MM-dd HH:mm:ss.SSS");
  } else if (formatOption === "formattedDate") {
    return dateTime.toFormat("LLL d, yyyy");
  } else {
    throw new Error("Unsupported format option");
  }
};
