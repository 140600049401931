import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer
        className={`p-2 overflow-hidden sm:p-10 relative bg-[url('/public/assets/images/footer/magdalena-kula-manchee-mMQPh3gInCQ-unsplash.jpg')] bg-cover bg-top  bg-no-repeat before:content-['']
          before:absolute before:inset-0 before:bg-[#c2a77e] before:opacity-50`}
      >
        <div className="flex justify-between items-center z-10 relative flex-col sm:flex-row">
          <img
            src="/assets/images/logo/Logo.png.webp"
            alt=""
            className="h-20 md:h-36 "
          />
          <div className="py-5 sm:py-0 grid gap-3 grid-cols-1 sm:grid-cols-5">
            <Link
              to="/"
              aria-current="page"
              className="p-2 whitespace-nowrap font-Averia text-xs uppercase font-bold w-full text-center hover:text-white transition duration-500"
            >
              Home
            </Link>
            <Link
              to="/our-team"
              className="p-2 whitespace-nowrap font-Averia text-xs uppercase font-bold w-full text-center hover:text-white transition duration-500"
            >
              Meet our team
            </Link>

            <Link
              to="/terms-of-service"
              className="p-2 whitespace-nowrap font-Averia text-xs uppercase font-bold w-full text-center hover:text-white transition duration-500"
            >
              Terms Of Service
            </Link>
            <Link
              to="/safari-booking-terms"
              className="p-2 whitespace-nowrap font-Averia text-xs uppercase font-bold w-full text-center hover:text-white transition duration-500"
            >
              Safari booking terms
            </Link>
            <Link
              to="/contacts"
              className="p-2 whitespace-nowrap font-Averia text-xs uppercase font-bold w-full text-center hover:text-white transition duration-500"
            >
              Get in touch
            </Link>
          </div>
        </div>

        <div className="relative w-full text-6xl sm:text-8xl font-Sebastian text-[#ece4cd] text-center z-10">
          Get your dream safari Started
        </div>
      </footer>

      <div className="bg-[#232323] flex flex-col sm:flex-row sm: justify-between items-center py-2 px-5">
        <h3 className="text-xs text-[#ece4cd]">
          COPYRIGHT @ DIVINE NEST TRAVEL 2023
        </h3>

        <Link to="/privacy-policy">
          <h3 className="text-xs text-[#ece4cd] pt-3 sm:pt-0">
            PRIVACY POLICY
          </h3>
        </Link>
      </div>
    </>
  );
};

export default Footer;
