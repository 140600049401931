import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

interface PageProps {
  title?: string;
  description?: string;
  link?: string;
  keywords?: string[];
  imageCard?: string;
  addPostfixTitle?: boolean;
  children?: ReactNode;
}

const DOMAIN = "https://divinetravelnestsafarisltd.com";
const MAIN_KEYWORDS = [
  "Divine Travel Nest Safaris",
  "Safaris",
  "East Africa Tours",
];
const FAVICON_SOURCE = "https://divinetravelnestsafarisltd.com/favicon.ico";
const POST_FIX_TITLE = " - Divine Travel Nest Safaris";

const defaultPageProps: PageProps = {
  title: "Divine Travel Nest Safaris",
  description:
    "Discover the wonders of East Africa with Divine Travel Safaris a local modern travelling company based in Kenya. Book your dream safari adventure now and create lasting memories",
  imageCard:
    "https://res.cloudinary.com/ddwkntgdf/image/upload/v1707631012/mflurmaldtx9khlfz99m.jpg",
  keywords: [
    "Divine Travel Nest Safaris",
    "safaris",
    "East Africa Tours, experiences",
  ],
  addPostfixTitle: false,
  children: null,
};

export default function Head({
  title = defaultPageProps.title,
  description = defaultPageProps.description,
  link,
  keywords = defaultPageProps.keywords,
  addPostfixTitle = defaultPageProps.addPostfixTitle,
  imageCard = defaultPageProps.imageCard,
  children,
}: PageProps) {
  let metaTitle;
  if (addPostfixTitle) {
    metaTitle = title + POST_FIX_TITLE;
  } else {
    metaTitle = title;
  }

  const metaDesc = description;
  const metaLink = DOMAIN + (link ?? "");
  const metaKeywords =
    keywords && keywords.length > 0
      ? MAIN_KEYWORDS.concat(keywords).join(" ")
      : MAIN_KEYWORDS.join(" ");
  const metaImageCard = imageCard;
  const metaFavicon = FAVICON_SOURCE;

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDesc} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#ece4cd" />
      <meta
        name="robots"
        content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
      />

      {/* links Tags */}
      <link rel="canonical" href={metaLink} />
      <link rel="icon" href={metaFavicon} />
      <link rel="manifest" href={`${DOMAIN}/manifest.json`} />
      <link rel="icon" href={`${DOMAIN}/favicon.ico`} />
      <link rel="apple-touch-icon" href={`${DOMAIN}/logo192.png`} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${DOMAIN}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${DOMAIN}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${DOMAIN}/favicon-16x16.png`}
      />

      {/* OG tags */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDesc} />
      <meta property="og:image" content={metaImageCard} />
      <meta property="og:url" content={metaLink} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={title} />

      <meta name="referrer" content="origin-when-crossorigin" />
      {children}
    </Helmet>
  );
}
