export const destinationData = {
  Kenya: [
    {
      name: "Kenya",
      image: "https://images.unsplash.com/photo-1638093812170-9422ebf1822f",
      description:
        "Kenya boasts a diverse range of national parks and reserves, each providing distinct attractions and experiences for visitors. Key destinations include Maasai Mara National Reserve, Amboseli National Park, Tsavo East and West National Parks, and Lake Nakuru National Park. These parks feature an impressive array of wildlife and breathtaking landscapes, offering abundant opportunities for adventure and discovery.",
      facts:
        "Kenya stands as a premier safari destination in Africa, boasting more than fifty national parks and reserves, among which the renowned Masai Mara stands out. Hosting the awe-inspiring annual wildebeest migration and Africa's second-highest peak, Mount Kenya, the country offers a landscape of unparalleled natural splendor and unforgettable experiences.",
      currency: "Kenyan shilling (KES)",
      area: "582,646 km²",
      language: "English and Swahili",
      habitats: [
        {
          name: "Open plains",
          details: "Classic savannah landscape, with grassy plains.",
          image: "https://images.unsplash.com/photo-1653293144633-b63d8707ce2d",
          disabled: true,
        },

        {
          name: "Riverine forests",
          details: "Lush green forest on the banks of the Mara River.",
          image:
            "https://images.pexels.com/photos/259547/pexels-photo-259547.jpeg",
          disabled: true,
        },
        {
          name: "Saline lakes",
          details: "Lake Nakuru, surrounded by wooded grassland.",
          image: "https://images.unsplash.com/photo-1609849012254-828f19a1b9b5",
          disabled: true,
        },
        {
          name: "Mountains",
          details: "Central Kenya is mountainous.",
          image: "https://images.unsplash.com/photo-1571636240366-1606c4a13f55",
          disabled: true,
        },
      ],
      reasonsToVisit: {
        summary: "all year round ",
        detail:
          "Determining the optimal time to visit Kenya hinges largely on individual interests and inclinations. Typically, the dry seasons, spanning from June to October and January to February, are deemed ideal for wildlife observation, as animals gather around watering holes. Conversely, the wet seasons, occurring from March to May and November to December, present lush, green scenery and fewer crowds, fostering a more intimate connection with the natural surroundings.",
      },

      photoURL: [
        "https://images.unsplash.com/photo-1618856445394-259e67220916",
        "https://images.unsplash.com/photo-1536797485467-3809860ee00a",
        "https://images.unsplash.com/photo-1536539319361-951bea3074bf",
        "https://images.pexels.com/photos/54514/pexels-photo-54514.jpeg",
      ],
    },
  ],
  Tanzania: [
    {
      name: "Tanzania",
      image: "https://images.unsplash.com/photo-1549035092-33b2937b075a",
      description:
        "Tanzania stands at the crossroads of ecology, geology, and human heritage, offering diverse ecosystems ranging from the expansive Serengeti grasslands to remote southwestern parks brimming with pristine wilderness. The Serengeti, covering over 12,000 square miles, hosts a dynamic and flourishing wildlife habitat, including the iconic Great Migration of terrestrial mammals. The Great Rift Valley, a vast geological fault traversing Eastern Africa, significantly shapes Tanzania's topography and contributes to its geological richness. For adventurous travelers seeking solitude, lesser-known parks like Ruaha, Katavi, and Mahale provide contrasting yet exceptional experiences. Despite being less visited than the bustling northern circuit, these areas promise remarkable wildlife encounters and the allure of off-the-beaten-path exploration.",
      facts:
        "Tanzania stands as the most expansive nation in East Africa, encompassing the notable islands of Zanzibar, Pemba, and Mafia. Its vast territory is approximately double the size of California and is bounded by the Indian Ocean. Mount Kilimanjaro, Africa's highest peak, graces its landscape, accompanied by three immense lakes: Lake Victoria to the north, Lake Tanganyika to the west, and Lake Nyasa to the southwest.",
      currency: "Tanzanian shillings (TZS)",
      area: "947,300 km²",
      language: "Swahili",
      habitats: [
        {
          name: "Open plains",
          details: "Savannah landscape covers the volcanic grasslands.",
          image: "https://images.unsplash.com/photo-1516494982030-fda424f96b59",
          disabled: true,
        },

        {
          name: "Riverine forests",
          details: "Forests frame the Great Rift Valley lakes..",
          image: "https://images.unsplash.com/photo-1467221973068-7abf710bbf54",
          disabled: true,
        },
        {
          name: "Freshwater lakes",
          details: "Freshwater lakes are surrounded by wooded grassland.",
          image: "https://images.unsplash.com/photo-1661885152270-25a392dd843a",
          disabled: true,
        },
        {
          name: "Mountains",
          details: "Home to the highest mountain in Africa.",
          image: "https://images.unsplash.com/photo-1631646109206-4b5616964f84",
          disabled: true,
        },
      ],
      reasonsToVisit: {
        summary: "all year round ",
        detail:
          "Tanzania's equatorial climate allows for travel throughout the year, each season offering its own distinct advantages. The dry season, spanning from late June to October, provides optimal conditions for wildlife viewing. Conversely, the wet season heralds the calving season and lush greenery, with the southern circuit remaining comparatively unexplored, promising a more secluded experience.",
      },

      photoURL: [
        "https://images.unsplash.com/photo-1598975314523-9afadd6316c8",
        "https://images.unsplash.com/photo-1612358405627-3721c6fc5fac",
        "https://images.unsplash.com/photo-1602410141957-ee70b4739066",
        "https://images.unsplash.com/photo-1513436158476-868ee8782fdd",
      ],
    },
  ],
  Uganda: [
    {
      name: "Uganda",
      image: "https://images.unsplash.com/photo-1594068304148-3e33049a2651",
      description:
        "In addition to Uganda's renowned mountain gorillas, the country boasts a diverse array of natural and cultural treasures. Lake Victoria and its surrounding wetlands are significant hubs for birdwatching enthusiasts. Beyond its ecological wonders, Uganda offers thrilling activities like river rafting on the Nile and hiking through the spectacular Rwenzori Mountains, known as the 'Mountains of the Moon' for their surreal beauty. Culturally, Uganda's intricate social customs, varied languages, and historical sites like the Kasubi Tombs provide a comprehensive glimpse into the country's rich heritage, extending beyond its wildlife attractions. Remote parks like Kidepo Valley in the north offer a rugged allure, appealing to those seeking off-the-beaten-path experiences. Uganda's indigenous communities, including the Baganda, Basoga, and Karamoja people, contribute to its vibrant cultural tapestry, with their traditions, rituals, and arts enriching the safari experience.",
      facts:
        "Uganda's wildlife presents a fascinating tapestry of biodiversity, with its renowned mountain gorillas standing as a symbol of its conservation efforts. The country is home to over 1,000 bird species, making it a haven for birdwatchers. Its national parks, including Queen Elizabeth, Murchison Falls, and Kidepo Valley, host an impressive array of wildlife, from majestic elephants and lions to elusive leopards and giraffes. Uganda's waterways, such as the Nile and Lake Victoria, support diverse aquatic life, including hippos and crocodiles. With its varied landscapes and rich ecosystems, Uganda offers an unparalleled wildlife experience for nature enthusiasts and conservationists alike.",
      currency: "Uganda shillings (UGS)",
      area: "241,551  km²",
      language: "English and Banganda",
      habitats: [
        {
          name: "Swampy wetlands",
          details: "Savannah landscape covers the volcanic grasslands.",
          image: "https://images.unsplash.com/photo-1557849582-5875ac6dee83",
          disabled: true,
        },

        {
          name: "Tropical rain forests",
          details: "Forests frame the Great Rift Valley lakes..",
          image: "https://images.unsplash.com/photo-1516051713211-b616b2fcf955",
          disabled: true,
        },
        {
          name: "Freshwater lakes",
          details: "Freshwater lakes are surrounded by wooded grassland.",
          image: "https://images.unsplash.com/photo-1551357140-c61c4f40224e",
          disabled: true,
        },
        {
          name: "Dense woodland",
          details: "Home to the highest mountain in Africa.",
          image: "https://images.unsplash.com/photo-1591782695697-5c8dc6e0eebd",
          disabled: true,
        },
      ],
      reasonsToVisit: {
        summary: "all year round ",
        detail:
          "The optimal periods to explore Uganda are during the dry seasons from June to August and December to February, which offer prime wildlife viewing conditions. These times feature favorable weather and less dense vegetation, enhancing visibility for wildlife sightings. Nonetheless, Uganda's diverse landscapes offer unique allure throughout the year, with lush scenery and distinct seasonal contrasts beyond the recommended periods.",
      },

      photoURL: [
        "https://images.unsplash.com/photo-1536328105599-b20961f76903",
        "https://images.unsplash.com/photo-1705066251269-9a5cdf0389e9",
        "https://images.unsplash.com/photo-1614528767034-70de9fe166e0",
        "https://images.unsplash.com/photo-1578843062829-dbd2f637458f",
      ],
    },
  ],
  Zanzibar: [
    {
      name: "Zanzibar",
      image: "https://images.unsplash.com/photo-1594068304148-3e33049a2651",
      description:
        "Kenya boasts a diverse range of national parks and reserves, each providing distinct attractions and experiences for visitors. Key destinations include Maasai Mara National Reserve, Amboseli National Park, Tsavo East and West National Parks, and Lake Nakuru National Park. These parks feature an impressive array of wildlife and breathtaking landscapes, offering abundant opportunities for adventure and discovery.",
      facts:
        "Kenya stands as a premier safari destination in Africa, boasting more than fifty national parks and reserves, among which the renowned Masai Mara stands out. Hosting the awe-inspiring annual wildebeest migration and Africa's second-highest peak, Mount Kenya, the country offers a landscape of unparalleled natural splendor and unforgettable experiences.",
      currency: "Tanzanian shillings (TZS)",
      area: "947,300 km²",
      language: "Swahili",
      habitats: [
        {
          name: "Open plains",
          details: "Savannah landscape covers the volcanic grasslands.",
          image: "https://images.unsplash.com/photo-1653293144633-b63d8707ce2d",
          disabled: true,
        },

        {
          name: "Riverine forests",
          details: "Forests frame the Great Rift Valley lakes..",
          image:
            "https://images.pexels.com/photos/259547/pexels-photo-259547.jpeg",
          disabled: true,
        },
        {
          name: "Freshwater lakes",
          details: "Freshwater lakes are surrounded by wooded grassland.",
          image: "https://images.unsplash.com/photo-1609849012254-828f19a1b9b5",
          disabled: true,
        },
        {
          name: "Mountains",
          details: "Home to the highest mountain in Africa.",
          image: "https://images.unsplash.com/photo-1571636240366-1606c4a13f55",
          disabled: true,
        },
      ],
      reasonsToVisit: {
        summary: "all year round ",
        detail:
          "Tanzania's equatorial climate allows for travel throughout the year, each season offering its own distinct advantages. The dry season, spanning from late June to October, provides optimal conditions for wildlife viewing. Conversely, the wet season heralds the calving season and lush greenery, with the southern circuit remaining comparatively unexplored, promising a more secluded experience.",
      },

      photoURL: [
        "https://images.unsplash.com/photo-1598975314523-9afadd6316c8",
        "https://images.unsplash.com/photo-1612358405627-3721c6fc5fac",
        "https://images.unsplash.com/photo-1602410141957-ee70b4739066",
        "https://images.unsplash.com/photo-1513436158476-868ee8782fdd",
      ],
    },
  ],
};
