import React from "react";
import PageTemplate from "../components/templates/PageTemplate";
import { useLocation, useParams } from "react-router-dom";
import Head from "../components/seo/Head";

type CountryData = {
  mainText: string;
  heading: string;
  headingTwo: string;
  firstParagraph: string;
  secondParagraph: string;
  sign: string;
  mainImg: string;
  secImgTwo: string;
  secImg: string;
  textslide: boolean;
  slidesWithText: {
    titleHeading: string;
    carouselHeading: string;
    image: string;
  }[];
};

const countries: { [key: string]: CountryData } = {
  Kenya: {
    mainText: "Where Adventure Meets Wildlife Wonderland",
    heading: "Roaming Kenya's Majesty",
    headingTwo: "A Safari Adventure Through Its Pristine Parks",
    firstParagraph:
      "Embarking on a safari adventure in Kenya is like stepping into a world where nature reigns supreme. With its diverse landscapes, rich biodiversity, and iconic wildlife, Kenya's safari parks offer an unforgettable journey into the heart of Africa. From the legendary Maasai Mara to the hidden gems of Amboseli and Tsavo, each park promises unique experiences and awe-inspiring encounters with nature's finest creations.",
    secondParagraph:
      "Kenya's safari parks are more than just wildlife destinations; they are windows into the soul of Africa. Whether you're marveling at the Great Migration in the Maasai Mara, admiring elephants beneath the shadow of Kilimanjaro in Amboseli, or exploring the rugged beauty of Tsavo, each park offers a unique and unforgettable safari experience. So, pack your bags, grab your camera, and embark on the adventure of a lifetime in Kenya's wild and wondrous safari parks.",
    sign: "Kenya Safari Parks",
    mainImg: "https://images.unsplash.com/photo-1623745491998-1a52714dec2d",
    secImgTwo: "https://images.unsplash.com/photo-1629263279495-9cf554873e12",
    secImg: "https://images.unsplash.com/photo-1453550486481-aa4175b013ea",
    textslide: false,
    slidesWithText: [
      //   {
      //     titleHeading: "Kenya Coast",
      //     carouselHeading:
      //       "White sands, crystal clear waters and exciting watersports",
      //     image: "https://images.unsplash.com/photo-1586442993273-0a0d4164fc70",
      //   },
      {
        titleHeading: "Samburu National Reserve",
        carouselHeading:
          "A Sanctuary of Wildlife Diversity and Cultural Heritage",
        image: "https://images.unsplash.com/photo-1670092873465-d00ecd79c3d6",
      },
      {
        titleHeading: "Masai Mara National Reserve",
        carouselHeading: "A true bucket list destination",
        image: "https://images.unsplash.com/photo-1623952625109-6c47a93f675c",
      },
      {
        titleHeading: "Chyulu Hills National Park",
        carouselHeading:
          "Stunning scenery and an enchanting off-the-tourist-trail safari",
        image: "https://images.unsplash.com/photo-1689574264188-4a59281c2a44",
      },
      //   {
      //     titleHeading: "Greate Rift Valley",
      //     carouselHeading:
      //       "Stunning network of lakes, extinct volcanoes and an array of wildlife",
      //     image: "https://images.unsplash.com/photo-1659414005818-3888864fda80",
      //   },
      //   {
      //     titleHeading: "Laikipia",
      //     carouselHeading:
      //       "A place of untamed beauty which stretches from Mt Kenya to Lake Baringo",
      //     image: "https://images.unsplash.com/photo-1618856444929-b263d666822d",
      //   },
      {
        titleHeading: "Nairobi National Park",
        carouselHeading: "Where Wildlife Roams Against an Urban Backdrop",
        image: "https://images.unsplash.com/photo-1635595358293-03620e36be48",
      },
      {
        titleHeading: "Amboseli National Park",
        carouselHeading:
          "Vast open savannah and a skyline dominated by the mighty Mt Kilimanjaro",
        image: "https://images.unsplash.com/photo-1678092369673-94f97a97d4e5",
      },
      {
        titleHeading: "Hells Gate National Park",
        carouselHeading: "Unlock Nature's Drama",
        image: "https://images.unsplash.com/photo-1672651745723-d0b2b962a7f3",
      },
      {
        titleHeading: "Lake Nakuru National Park",
        carouselHeading: "Discover the Colorful Symphony of Nature",
        image: "https://images.unsplash.com/photo-1623745494461-c5afa6f6e507",
      },
      {
        titleHeading: "Tsavo National Park",
        carouselHeading: "Where the Wild Roars",
        image: "https://images.unsplash.com/photo-1598200934836-e445fd003611",
      },
      {
        titleHeading: "Mount Kenya National Park",
        carouselHeading: "Touching the Sky",
        image: "https://images.unsplash.com/photo-1535680671320-c566e5ecddf7",
      },
      {
        titleHeading: "Mount Longonot National Park",
        carouselHeading: "Rising from the Earth",
        image: "https://images.unsplash.com/photo-1535758007178-141f94869b83",
      },
    ],
  },
  Tanzania: {
    mainText: "Discovering the Wonders of Tanzania's Safari Parks",
    heading: "Exploring Tanzania's Wilderness",
    headingTwo: "Safari Marvels in its Spectacular Parks",
    firstParagraph:
      "Tanzania, a land of untamed beauty and natural wonders, beckons adventurers to explore its diverse safari parks. From the iconic Serengeti to the majestic Ngorongoro Crater, Tanzania's wilderness areas offer a safari experience like no other. With vast savannahs, dense forests, and abundant wildlife, each park presents a unique opportunity to witness the wonders of the African wilderness up close.",
    secondParagraph:
      "Tanzania's safari parks are a testament to the country's rich natural heritage and commitment to conservation. Whether you're witnessing the Great Migration in the Serengeti, exploring the Ngorongoro Crater's ancient wonders, or discovering the hidden treasures of Tarangire, each park offers a unique and unforgettable safari experience. So, embark on a journey of discovery and immerse yourself in the wild and wondrous landscapes of Tanzania's safari parks.",
    sign: "Tanzania Safari Parks",
    mainImg: "https://images.unsplash.com/photo-1536797485467-3809860ee00a",
    secImgTwo:
      "https://plus.unsplash.com/premium_photo-1664304370557-233bccc0ac85",
    secImg: "https://images.unsplash.com/photo-1518709594023-6eab9bab7b23",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "Mahale Mountains",
        carouselHeading:
          "White sands, crystal clear waters and exciting watersports",
        image: "https://images.unsplash.com/photo-1586442993273-0a0d4164fc70",
      },
      {
        titleHeading: "Ruaha National Park",
        carouselHeading:
          "A Sanctuary of Wildlife Diversity and Cultural Heritage",
        image: "https://images.unsplash.com/photo-1670092873465-d00ecd79c3d6",
      },
      {
        titleHeading: "Serengeti National Park",
        carouselHeading: "A true bucket list destination",
        image: "https://images.unsplash.com/photo-1623952625109-6c47a93f675c",
      },
      {
        titleHeading: "Mahale Mountains",
        carouselHeading:
          "Stunning scenery and an enchanting off-the-tourist-trail safari",
        image: "https://images.unsplash.com/photo-1689574264188-4a59281c2a44",
      },
      {
        titleHeading: "Ngorongoro Creater",
        carouselHeading:
          "Stunning network of lakes, extinct volcanoes and an array of wildlife",
        image: "https://images.unsplash.com/photo-1659414005818-3888864fda80",
      },
      {
        titleHeading: "Selous Game Reserve",
        carouselHeading:
          "A place of untamed beauty which stretches from Mt Kenya to Lake Baringo",
        image: "https://images.unsplash.com/photo-1618856444929-b263d666822d",
      },
      {
        titleHeading: "Tarangire National Park",
        carouselHeading:
          "Vast open savannah and a skyline dominated by the mighty Mt Kilimanjaro",
        image: "https://images.unsplash.com/photo-1678092369673-94f97a97d4e5",
      },
      {
        titleHeading: "Mount Kilimanjaro",
        carouselHeading:
          "Looking for a challenge? Africa’s highest mountain beckons",
        image: "https://images.unsplash.com/photo-1631646109206-4b5616964f84",
      },
    ],
  },
  Uganda: {
    mainText: " Exploring Uganda's Enchanting Safari Parks",
    heading: "Uganda's Hidden Gems",
    headingTwo: "Safari Serenity Amidst Nature's Bounty",
    firstParagraph:
      "Nestled in the heart of East Africa, Uganda is a land of diverse landscapes, rich biodiversity, and unparalleled natural beauty. From the mist-shrouded mountains of the Rwenzori Range to the lush greenery of the Bwindi Impenetrable Forest, Uganda's safari parks offer a unique and enchanting safari experience. With a focus on conservation and ecotourism, these parks provide travelers with the opportunity to encounter rare and endangered species in their natural habitats, making Uganda a must-visit destination for wildlife enthusiasts and adventure seekers alike.",
    secondParagraph:
      "Uganda's safari parks are a testament to the country's natural diversity and commitment to conservation. Whether you're tracking mountain gorillas in Bwindi, cruising along the Kazinga Channel in Queen Elizabeth, or marveling at the power of Murchison Falls, each park offers a unique and unforgettable safari experience. So, pack your bags, grab your camera, and embark on an adventure to Uganda's enchanting safari parks, where every moment is a journey into the wild.",
    sign: "Uganda Safri Parks",
    mainImg: "https://images.unsplash.com/photo-1534996170135-5d01433eaa9e",
    secImg: "https://images.unsplash.com/photo-1576526164505-9a2540866186",
    secImgTwo: "https://images.unsplash.com/photo-1505562626914-1db0937ea292",
    textslide: false,
    slidesWithText: [
      {
        titleHeading: "Kibale Forest National Park",
        carouselHeading:
          "White sands, crystal clear waters and exciting watersports",
        image: "https://images.unsplash.com/photo-1586442993273-0a0d4164fc70",
      },
      {
        titleHeading: "Kidepo National Park",
        carouselHeading:
          "A Sanctuary of Wildlife Diversity and Cultural Heritage",
        image: "https://images.unsplash.com/photo-1670092873465-d00ecd79c3d6",
      },
      {
        titleHeading: "Queen Elizabeth National Park",
        carouselHeading: "A true bucket list destination",
        image: "https://images.unsplash.com/photo-1623952625109-6c47a93f675c",
      },
      {
        titleHeading: "Bwindi impenetrable Forest",
        carouselHeading:
          "Stunning scenery and an enchanting off-the-tourist-trail safari",
        image: "https://images.unsplash.com/photo-1689574264188-4a59281c2a44",
      },
    ],
  },
};

export default function SafariParks() {
  const { matchString } = useParams<{ matchString: string | undefined }>();
  const location = useLocation();

  if (!matchString || !countries[matchString]) {
    return <div>Country not found</div>;
  }

  const countryData = countries[matchString as string];

  return (
    <>
      <Head
        title={`Safari Parks in ${matchString}`}
        link={location.pathname}
        addPostfixTitle={true}
      />
      <PageTemplate
        mainText={countryData.mainText}
        heading={countryData.heading}
        headingTwo={countryData.headingTwo}
        firstParagraph={countryData.firstParagraph}
        secondParagraph={countryData.secondParagraph}
        sign={countryData.sign}
        mainImg={countryData.mainImg}
        secImgTwo={countryData.secImgTwo}
        secImg={countryData.secImg}
        textslide={countryData.textslide}
        slidesWithText={countryData.slidesWithText}
      />
    </>
  );
}
