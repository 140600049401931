"use client";
import React, { useState } from "react";
import { Menu, MenuItem, ProductItem } from "../ui/NavbarMenu";
import { cn } from "../../utils/cn";
import { headerData } from "../../data/LayoutData";
import { Link } from "react-router-dom";
import MenuSidebarLink from "../ui/MenuSidebarLink";
import Button from "../ui/Button";
import Modal from "../ui/Modal";

export default function MenuDesk({ className }: { className?: string }) {
  const [active, setActive] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(true);
  };
  return (
    <div
      className={cn(
        "hidden md:flex md:justify-start md:flex-nowrap z-50 w-full py-1 px-5 shadow-sm",
        className
      )}
    >
      <Menu setActive={setActive}>
        <div className="flex items-center">
          <Link
            className="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80"
            to="/"
            aria-label="divine travel nest"
          >
            <img
              className="object-cover object-center w-20"
              src="/logo.png.webp"
              alt="logo"
            />
          </Link>
        </div>

        <div className="flex space-x-16 relative">
          {headerData.pages.map((page) => (
            <MenuItem
              setActive={setActive}
              active={active}
              item={page.name}
              link={page.href}
              key={page.name}
            >
              <div className="flex p-6 gap-6">
                <div className="w-1/4 flex flex-col gap-6">
                  {page.sideBar.map((sideBarlink) => (
                    <MenuSidebarLink
                      key={sideBarlink.text}
                      text={sideBarlink.text}
                      href={sideBarlink.href}
                    />
                  ))}
                </div>
                <div className="w-3/4 grid grid-cols-3 gap-6">
                  {page.menuItems.map((menuitemlink) => (
                    <ProductItem
                      key={menuitemlink.text}
                      text={menuitemlink.text}
                      href={menuitemlink.href}
                      img={menuitemlink.img}
                    />
                  ))}
                </div>
              </div>
            </MenuItem>
          ))}
        </div>
        <div className="flex items-center">
          <Modal
            open={open}
            setOpen={setOpen}
            title="Get in touch for inquiries and booking your safari adventure"
          />
          <Button text="Enquiries" type="button" onClick={handleClick} />
        </div>
      </Menu>
    </div>
  );
}
